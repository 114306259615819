import {
  Centered,
  EnhancedLabeledSelect,
  FlexColumn,
  FlexResponsive,
  FlexRow,
  LabeledData,
  Loading,
  StyledBanner,
  useLoading,
} from '@gorila-shared-ui/components';
import { ProgressSteps, Step } from 'baseui/progress-steps';
import { OnChangeParams } from 'baseui/select';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_ASSET_MINIMAL } from '../../constants/asset';
import { VEHICLE_COLOR_OPTIONS } from '../../constants/vehicle';
import { useDebounceFunction } from '../../hooks/useDebounce';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import { useInitialLoadState } from '../../hooks/useInitialLoadState';
import { useInstallations } from '../../hooks/useInstallations';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getAssets } from '../../services/assetService';
import { Asset, AssetMinimal } from '../../types/asset';
import { Brand } from '../../types/brand';
import { ClientCore } from '../../types/client';
import { Installation } from '../../types/installation';
import { SubBrandCore } from '../../types/subBrand';
import { SubClientCore } from '../../types/subClient';
import { getAssetTitle } from '../../utils/assets';
import AssetContainerData from './AssetContainerData';
import { AssetDataResume } from './AssetDataResume';
import { AssetFormFields } from './AssetFormFields';
import { AssetTypeFormFields } from './AssetTypeFormFields';
import AssetVehicleData from './AssetVehicleData';

type Props = {
  asset?: Asset | AssetMinimal;
  isNewAsset?: boolean;
  createNewAsset?: (createAsset: boolean) => void;
  clientId?: string;
  subClientId?: string;
  onAssetChange: (asset: AssetMinimal) => void;
  onSelectedAssetChange?: (asset?: Asset) => void;
  installation?: Installation;
  additionalSteps?: JSX.Element[];
  currentStep: number;
};

export function AssetFormSteps({
  asset,
  clientId,
  subClientId,
  installation,
  onAssetChange,
  currentStep,
  additionalSteps,
  isNewAsset = true,
  onSelectedAssetChange,
  createNewAsset,
}: Readonly<Props>) {
  const [editingAsset, setEditingAsset] = useState<AssetMinimal>(DEFAULT_ASSET_MINIMAL);
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const { css, classes, theme } = useStyles();
  const [assetsList, setAssetsList] = useState<Asset[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch(installation?.vin ?? '');
  const requestIdRef = useRef(0);
  const { initialLoad, completeInitialLoad } = useInitialLoadState();
  const [defaultSearch, setDefaultSearch] = useState<string>();

  const {
    clientId: installationClientId,
    clientName,
    driverName,
    installationVehicle,
    policy,
    socialReason,
  } = useInstallations(installation);

  useEffect(() => {
    onAssetChange(editingAsset);
  }, [editingAsset]);

  const onCreateAsset = () => {
    setSelectedAsset(undefined);
    createNewAsset && createNewAsset(true);
  };

  useEffect(() => {
    onSelectedAssetChange && onSelectedAssetChange(selectedAsset);
  }, [selectedAsset]);

  useEffect(() => {
    if (asset && editingAsset._id !== 'installation') {
      setEditingAsset(asset);
    } else if (clientId && subClientId) {
      setEditingAsset((prev) => ({
        ...prev,
        client: { _id: clientId } as ClientCore,
        subClient: { _id: subClientId } as SubClientCore,
      }));
    }
  }, [asset, clientId, subClientId]);

  useEffect(() => {
    if (installation && asset?._id !== 'installation') {
      const defaultLabel = socialReason?.trim() ? socialReason : driverName;
      setEditingAsset((prev) => {
        const vehicle = {
          ...prev.vehicle!,
          color: VEHICLE_COLOR_OPTIONS[0].value,
          licensePlate: installationVehicle?.placas ?? '',
          vin: installationVehicle?.vin ?? '',
          brand: { name: installationVehicle?.armadora?.descripcion ?? '' } as Brand,
          subBrand: { name: installationVehicle?.carroceria?.descripcion ?? '' } as SubBrandCore,
          year: installationVehicle?.modelo,
        };
        return {
          ...prev,
          _id: 'installation',
          label: clientName?.trim() ? clientName : defaultLabel,
          client: { _id: installationClientId || undefined } as ClientCore,
          insuranceNumber: policy?.numPoliza ?? '',
          insuranceStartDate: policy?.inicioVigencia ?? undefined,
          insuranceEndDate: policy?.finVigencia ?? undefined,
          deleted: false,
          monitoring: false,
          vehicle,
        };
      });
    }
  }, [installation]);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadAssets = async () => {
      const requestId = ++requestIdRef.current;
      const { assets, error, hasNext } = await getAssets({ page, q: search });
      if (requestId === requestIdRef.current) {
        if (!error && assets) {
          if (page > 1 && assetsList) {
            setAssetsList([...assetsList, ...assets]);
            setHasNextPage(hasNext);
          } else {
            setAssetsList(assets);
            setHasNextPage(hasNext);
          }
        } else {
          setAssetsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadAssets();
  }, [page]);

  const assetOptions = useMemo(() => {
    if (!assetsList) return;
    const assetsOptions: { id: string | undefined; label: string }[] = assetsList.map((asset) => ({
      id: asset._id,
      label: getAssetTitle(asset, true),
    }));
    if (selectedAsset) {
      if (!assetsOptions.find((c) => c.id === selectedAsset._id))
        assetsOptions.push({
          id: selectedAsset._id,
          label: getAssetTitle(selectedAsset, true),
        });
    }
    return assetsOptions;
  }, [assetsList]);

  useEffect(() => {
    if (initialLoad && assetsList) {
      if (assetsList.length === 0) {
        onCreateAsset();
      } else if (assetsList.length === 1) {
        setSelectedAsset(assetsList[0]);
        setSearch('');
      } else {
        setDefaultSearch(search);
      }
      completeInitialLoad();
    }
  }, [assetsList]);

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  const generalDataStep = (
    <Step
      title="Datos de Activo"
      key="generalAssetData"
    >
      {!isNewAsset && !initialLoad && (
        <FlexColumn gap={theme.sizing.scale400}>
          {!selectedAsset && (
            <StyledBanner kind="warning">
              Hay {assetsList?.length} resultados con el vin "{search}", favor revisa o selecciona el que quieras usar
            </StyledBanner>
          )}
          <FlexRow>
            <EnhancedLabeledSelect
              label={'Activo:'}
              options={assetOptions}
              value={[{ id: selectedAsset?._id }]}
              onChange={(params: OnChangeParams) => {
                if (params.option?.id) {
                  const asset = assetsList?.find((asset) => asset._id === (params.option?.id as string));
                  setSelectedAsset(asset);
                } else {
                  setSelectedAsset(undefined);
                }
                setSearch(undefined);
              }}
              onInputChange={(e) => {
                handleInputChange(e.target.value);
              }}
              infiniteScroll
              fetchData={fetchData}
              fetchDataLoading={loading}
              hasNext={hasNextPage}
              searchable
              defaultSearch={defaultSearch}
              onBlurResetsInput={false}
              caption={'Usa el número de serie o placa para buscar el vehículo a instalar.'}
              required
            />
            {/* <FlexColumn classNames={css({paddingTop: '21px'})}>
              <StyledButton
                onClick={onCreateAsset}
              >
                Nuevo
              </StyledButton>
            </FlexColumn> */}
          </FlexRow>
          <FlexResponsive>
            <FlexColumn classNames={css({ flex: 1 })}>
              <LabelMedium className={classes.dataHeaderClass}>Datos de Emisión</LabelMedium>
              <FlexColumn gap={theme.sizing.scale300}>
                <LabeledData
                  label="Placa:"
                  content={installation?.poliza.vehiculo.placas ?? '-'}
                  labelWidth="40px"
                />
                <LabeledData
                  label="Vin:"
                  content={installation?.vin ?? '-'}
                  labelWidth="40px"
                />
              </FlexColumn>
            </FlexColumn>
            {selectedAsset && (selectedAsset.device || !!selectedAsset.secondaries?.length) && (
              <FlexColumn classNames={css({ flex: 1 })}>
                <LabelMedium className={classes.dataHeaderClass}>Dispositivos</LabelMedium>
                <FlexColumn
                  gap={theme.sizing.scale300}
                  padding={'0px 0px 0px 2rem'}
                >
                  {selectedAsset.device && (
                    <LabeledData
                      label="Primario:"
                      content={selectedAsset.device?.ident}
                      labelWidth="90px"
                    />
                  )}
                  {!!selectedAsset?.secondaries?.length && (
                    <LabeledData
                      label="Secundarios:"
                      content={
                        <ParagraphSmall
                          margin={0}
                          color={theme.colors.contentSecondary}
                        >
                          {selectedAsset.secondaries.map((d) => d.ident).join(', ')}
                        </ParagraphSmall>
                      }
                      labelWidth="90px"
                    />
                  )}
                </FlexColumn>
              </FlexColumn>
            )}
          </FlexResponsive>
          {selectedAsset && (
            <FlexResponsive>
              <FlexColumn classNames={css({ flex: 1 })}>
                <LabelMedium className={classes.dataHeaderClass}>Datos Generales</LabelMedium>
                <AssetDataResume
                  asset={selectedAsset}
                  fullData={false}
                />
              </FlexColumn>
              <FlexColumn classNames={css({ flex: 1 })}>
                {selectedAsset.vehicle && selectedAsset.assetType === 'vehicle' && (
                  <AssetVehicleData vehicle={selectedAsset.vehicle} />
                )}
                {selectedAsset.container && selectedAsset.assetType === 'container' && (
                  <AssetContainerData container={selectedAsset.container} />
                )}
              </FlexColumn>
            </FlexResponsive>
          )}
        </FlexColumn>
      )}
      {isNewAsset && !initialLoad && (
        <AssetFormFields
          editingAsset={editingAsset}
          hasInstallation={!!installation}
          onChange={setEditingAsset}
          asset={asset}
          clientId={clientId}
          subClientId={subClientId}
        />
      )}
    </Step>
  );

  const assetStep = (
    <Step
      title={`Datos del ${editingAsset.assetType === 'vehicle' ? 'Vehículo' : 'Contenedor'}`}
      key="assetData"
    >
      <AssetTypeFormFields
        assetType={editingAsset.assetType}
        editingAsset={editingAsset}
        onChange={setEditingAsset}
        asset={asset}
      />
    </Step>
  );

  const steps =
    isNewAsset && (editingAsset.assetType === 'container' || editingAsset.assetType === 'vehicle')
      ? [generalDataStep, assetStep]
      : [generalDataStep];

  if (additionalSteps) {
    steps.push(...additionalSteps);
  }

  return (
    <>
      {!initialLoad && <ProgressSteps current={currentStep}>{steps}</ProgressSteps>}
      {initialLoad && (
        <Centered>
          <Loading />
        </Centered>
      )}
    </>
  );
}
