import { ApiError } from '@gorila-shared-ui/components';
import { AlarmTypes, SafeGptConfigRequest, SafeGptConfiguration } from '../types/safeGpt';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getSafeGptConfigList = async (
  q?: string
): Promise<{ configurations?: SafeGptConfiguration[]; error?: ApiError }> => {
  const params = {
    q,
  };
  const response = await getRequest(URLS.safeGpt.all, {
    queryParams: params,
  });
  return {
    configurations: response?.data,
    error: response?.error,
  };
};

export const createSafeGPT = async (safeGPT: SafeGptConfigRequest): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    ...safeGPT,
  };
  const response = await postRequest(URLS.safeGpt.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateSafeGPT = async (
  safeGptId: string,
  safeGPT: SafeGptConfigRequest
): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    ...safeGPT,
  };
  const response = await postRequest(URLS.safeGpt.update(safeGptId), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const getAlarmTypeList = async (): Promise<{ alarmTypes?: AlarmTypes[]; error?: ApiError }> => {
  const response = await getRequest(URLS.safeGpt.alarmTypes);
  return {
    alarmTypes: response?.data,
    error: response?.error,
  };
};
