import { generatePath } from 'react-router-dom';
import { INSTALLATIONS_DETAIL_PAGE } from '../../../../constants/app';
import ConfirmModal from '../../../ui/ConfirmModal';

type Props = {
  emissionId: string;
  isOpen: boolean;
  closeFn: () => void;
};

export function FleetHealthEventInstallationConfirmModal({ emissionId, isOpen, closeFn }: Readonly<Props>) {
  const onConfirmCreateInstallation = async () => {
    const route = generatePath(INSTALLATIONS_DETAIL_PAGE.route, { installationId: emissionId });
    window.open(`${route}?cita=true`, '_blank');
    closeFn();
  };

  return (
    <>
      <ConfirmModal
        isOpen={isOpen}
        onCancel={closeFn}
        onConfirm={onConfirmCreateInstallation}
        title="Evento atendido"
        confirmText="Agendar"
        description="¿Quieres agendar una cita?"
      />
    </>
  );
}
