import { FlexRow, MaterialIcon, StyledButton, StyledTooltip, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useStyles } from '../../hooks/useStyles';
import BulkFileExamplesModal from './modal/BulkFileExamplesModal';
import NewBulkModal from './modal/NewBulkModal';

type Props = {
  onReloadPage: () => void;
};

export function BulkFiltersBar({ onReloadPage }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { openModal: openInfoModal, closeModal: closeInfoModal, showModal: showInfoModal } = useModal();
  const { openModal: openFormModal, closeModal: closeFormModal, showModal: showFormModal } = useModal();

  return (
    <>
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          justifyContent: 'end',
          width: '100%',
        })}
      >
        <StyledTooltip
          content={'Tipos de archivos'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={openInfoModal}
            kind="tertiary"
          >
            <MaterialIcon name={'info'} />
          </Button>
        </StyledTooltip>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openFormModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nueva
        </StyledButton>
      </FlexRow>
      {showFormModal && (
        <NewBulkModal
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeFormModal();
          }}
          isOpen={showFormModal}
        />
      )}
      {showInfoModal && (
        <BulkFileExamplesModal
          onClose={closeInfoModal}
          isOpen={showInfoModal}
        />
      )}
    </>
  );
}
