import { EnhancedLabeledSelect, useLoading } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useState } from 'react';
import { getAvailableCommands } from '../../../services/massiveCommandService';
import { CommandCore } from '../../../types/command';

type Props = {
  deviceModelId: string;
  selectedCommandId?: string;
  onChangeCommand: (command?: string) => void;
  label?: string;
  disabled?: boolean;
  inline?: boolean;
  required?: boolean;
  onBlur?: () => void;
};
export function AvailableCommandsSelect({
  deviceModelId,
  onChangeCommand,
  selectedCommandId,
  label = 'Commandos disponibles:',
  disabled = false,
  inline = false,
  required = false,
  onBlur,
}: Readonly<Props>) {
  const [availableCommandsList, setAvailableCommandsList] = useState<CommandCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedDeviceModelOption, setSelectedDeviceModelOption] = useState<{
    id: string;
    label: string;
  }>();

  useEffect(() => {
    const loadDevicesModels = async () => {
      startLoading();
      const { commands, error } = await getAvailableCommands(deviceModelId);
      if (!error && commands) {
        setAvailableCommandsList(commands);
      } else {
        setAvailableCommandsList(undefined);
      }
      stopLoading();
    };
    if (disabled) return;
    loadDevicesModels();
  }, [deviceModelId]);

  const availableCommandsOptions = useMemo(() => {
    if (!availableCommandsList || availableCommandsList.length === 0) return;
    const availableCommandOptions: { id: string | undefined; label: string }[] = [
      ...availableCommandsList.map((deviceModel) => ({
        id: deviceModel.commandName,
        label: deviceModel.commandDescription,
      })),
    ];
    if (!required) {
      availableCommandOptions?.unshift({
        id: undefined,
        label: 'Ninguno',
      });
    }
    if (selectedDeviceModelOption) {
      if (!availableCommandOptions.find((c) => c.id === selectedDeviceModelOption.id))
        availableCommandOptions.push(selectedDeviceModelOption);
    }
    return availableCommandOptions;
  }, [availableCommandsList]);

  const onDeviceModelChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeCommand(params.option.id as string) : onChangeCommand(undefined);
    setSelectedDeviceModelOption(params.option as { id: string; label: string });
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={availableCommandsOptions}
      value={[{ id: selectedCommandId }]}
      onChange={onDeviceModelChange}
      inline={inline}
      inset={inline}
      fullWidth
      fetchDataLoading={loading}
      searchable
      disabled={disabled}
      required={required}
      onBlur={() => onBlur && onBlur()}
    />
  );
}
