import { ApiError } from '@gorila-shared-ui/components';
import { ApiEndpoint } from '../types/api';
import { CommunicationsReport, CommunicationsReportOffline, EmissionReport } from '../types/report';
import { getRequest } from './api';
import { URLS } from './urls/urls';

export const getReportingDevices = async (
  startDate: string,
  endDate: string,
  page?: number,
  download?: boolean
): Promise<{ communicationsReports?: CommunicationsReport[]; error?: ApiError; total?: number }> => {
  const params = {
    startDate,
    endDate,
    page,
    download,
  };
  const response = await getRequest(URLS.reports.devices, {
    queryParams: params,
  });
  return {
    communicationsReports: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getReportsOffline = async (
  startDate?: string,
  endDate?: string,
  page?: number
): Promise<{ reportsOffline?: CommunicationsReportOffline[]; error?: ApiError; total?: number }> => {
  const params = {
    startDate,
    endDate,
    page,
  };
  const response = await getRequest(URLS.reports.devicesOffline, {
    queryParams: params,
  });
  return {
    reportsOffline: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const downloadReportFile = async (reportId: string): Promise<{ url?: string; error?: ApiError }> => {
  const response = await getRequest(URLS.reports.deviceFileUrl(reportId));
  return {
    url: response?.data?.url,
    error: response?.error,
  };
};

//emissions
export const getEmissionsReportsList = async (
  page = 1,
  startDate?: number | null,
  endDate?: number | null
): Promise<{
  reports?: EmissionReport[];
  error?: ApiError;
  total: number;
}> => {
  const response = await getRequest(
    URLS.reports.emissions,
    {
      queryParams: {
        page,
        startDate: startDate && endDate ? startDate : undefined,
        endDate,
      },
    },
    ApiEndpoint.emissions
  );
  return {
    reports: response?.data?.items,
    total: response?.data?.total ?? 0,
    error: response?.error,
  };
};

export const downloadEmissionReportFile = async (reportId: string): Promise<{ url?: string; error?: ApiError }> => {
  const response = await getRequest(URLS.reports.downloadEmissions(reportId), {}, ApiEndpoint.emissions);
  return {
    url: response?.data?.url,
    error: response?.error,
  };
};
