import { MaterialIcon, StyledTooltip, useLoading, useModal, useMoment } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MASSIVE_TABLE_COLUMNS_NAME } from '../../constants/massiveCommands';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getMassiveCommands } from '../../services/massiveCommandService';
import { availableCommandLabelsState } from '../../storage/commandFiltersState';
import { MassiveCommand } from '../../types/massiveCommands';
import { ListWrapper } from '../shared/ListWrapper';
import MassiveCommsDetailModal from './modal/MassiveCommsDetailModal';

type Props = {
  reloadPage?: boolean;
};
export function MassiveCommsList({ reloadPage }: Readonly<Props>) {
  const [massiveComms, setMassiveComms] = useState<MassiveCommand[]>();
  const commandLabels = useRecoilValue(availableCommandLabelsState);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedMassiveCommId, setSelectedMassiveCommId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadMassiveCommFilesTableData = async () => {
      const response = await getMassiveCommands(page);
      if (response?.error) {
        setMassiveComms(undefined);
        setTotalItems(0);
      } else {
        setMassiveComms(response?.massiveCommands);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadMassiveCommFilesTableData();
  }, [page]);

  const openMassiveCommModal = (massiveCommFileId: string) => {
    setSelectedMassiveCommId(massiveCommFileId);
    openModal();
  };

  const closeMassiveCommModal = () => {
    setSelectedMassiveCommId(undefined);
    closeModal();
  };

  return (
    <>
      <ListWrapper
        columns={MASSIVE_TABLE_COLUMNS_NAME}
        data={
          massiveComms?.map((massiveCommand, i) => {
            return {
              items: [
                massiveCommand.deviceModel._id,
                commandLabels ? commandLabels[massiveCommand.command] : massiveCommand.command,
                massiveCommand.created ? formatDateFromTs(massiveCommand.created) : '',
                <StyledTooltip
                  key={`ws-action-${i}`}
                  content={'Ver detalles'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openMassiveCommModal(massiveCommand._id)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={massiveComms !== undefined}
        isEmpty={!massiveComms?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun envio de comandos masivo"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
        hasSearch={false}
      />
      {showModal && selectedMassiveCommId && (
        <MassiveCommsDetailModal
          onClose={() => closeMassiveCommModal()}
          isOpen={showModal}
          massiveCommandId={selectedMassiveCommId}
        />
      )}
    </>
  );
}
