import { DAY, FlexColumn, FlexRow } from '@gorila-shared-ui/components';
import { DatePicker } from 'baseui/datepicker';
import { ArrowRight } from 'baseui/icon';
import { TimePicker } from 'baseui/timepicker';
import { LabelSmall } from 'baseui/typography';
import { isAfter, isBefore } from 'date-fns';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  dates: (Date | null | undefined)[];
  onDatesChange: (dates: (Date | null | undefined)[]) => void;
  startLabel?: string;
  endLabel?: string;
  startDisabled?: boolean;
  endDisabled?: boolean;
  onError?: (error: string) => void;
  todayIsMaxDate?: boolean;
  minStartDate?: number;
  maxEndDate?: number;
  maxDayInterval?: number;
};

export function StyledDateTimeRangePicker({
  dates,
  onDatesChange,
  startLabel = 'Start Datetime',
  endLabel = 'End Datetime',
  startDisabled = false,
  endDisabled = false,
  onError = () => {},
  todayIsMaxDate = true,
  minStartDate,
  maxEndDate,
  maxDayInterval,
}: Props) {
  const { css, theme, classes } = useStyles();
  const inputWidth = css({
    width: '120px',
  });
  const startDate = dates[0];
  const endDate = dates[1];

  const today = new Date();
  const todayTime = today.getTime();
  const minDate = todayIsMaxDate ? ((startDate?.getTime() || 0) < todayTime ? today : startDate) : startDate;

  const beforeTodayError = 'La fecha de llegada no puede ser menor a la fecha actual';
  const beforeError = 'La fecha de llegada no puede ser menor a la fecha de salida';

  const getMaxEndDate = () => {
    if (maxDayInterval) {
      const time = startDate ? startDate.getTime() + maxDayInterval * DAY : new Date().getTime() + maxDayInterval * DAY;
      if (maxEndDate && isAfter(time, maxEndDate)) return maxEndDate;
      return new Date(time);
    } else if (maxEndDate) return new Date(maxEndDate);
    else return undefined;
  };

  return (
    <FlexRow classNames={classes.centeredStart}>
      <FlexColumn gap={theme.sizing.scale200}>
        <LabelSmall>{startLabel}</LabelSmall>
        <FlexRow gap={theme.sizing.scale200}>
          <div className={inputWidth}>
            <DatePicker
              size="compact"
              value={startDate}
              onClose={() => {
                onDatesChange([startDate, endDate]);
              }}
              onChange={({ date }) => {
                if (endDate && isAfter(date as Date, endDate)) {
                  onDatesChange([date as Date, date as Date]);
                } else if (maxDayInterval) {
                  let maxDate = (date as Date).getTime() + maxDayInterval * DAY;
                  if (maxEndDate && isAfter(maxDate, maxEndDate)) maxDate = maxEndDate;
                  if (endDate && isAfter(endDate, maxDate)) onDatesChange([date as Date, new Date(maxDate)]);
                } else {
                  onDatesChange([date as Date | null | undefined, endDate]);
                }
              }}
              mask="9999/99/99"
              disabled={startDisabled}
              minDate={minStartDate ? new Date(minStartDate) : undefined}
              maxDate={maxEndDate ? new Date(maxEndDate) : undefined}
            />
          </div>
          <div className={inputWidth}>
            <TimePicker
              size="compact"
              creatable
              value={startDate}
              disabled={startDisabled}
              onChange={(time) => {
                if (time && endDate) {
                  if (isAfter(time, endDate)) {
                    onDatesChange([time, time]);
                  } else {
                    onDatesChange([time, endDate]);
                  }
                }
              }}
              maxTime={maxEndDate ? new Date(maxEndDate) : undefined}
            />
          </div>
        </FlexRow>
      </FlexColumn>
      <ArrowRight size={24} />
      <FlexColumn gap={theme.sizing.scale200}>
        <LabelSmall>{endLabel}</LabelSmall>
        <FlexRow gap={theme.sizing.scale200}>
          <div className={inputWidth}>
            <DatePicker
              size="compact"
              value={endDate}
              onClose={() => {
                onDatesChange([startDate, endDate]);
              }}
              onChange={({ date }) => {
                if (date && startDate) {
                  const before = isBefore(date as Date, startDate);
                  const beforeToday = isBefore(date as Date, today);
                  if (todayIsMaxDate && beforeToday) {
                    onError(beforeTodayError);
                  } else if (before && !startDisabled) {
                    onDatesChange([date as Date, date as Date]);
                    onError('');
                  } else if (before && startDisabled) {
                    onError(beforeError);
                  } else {
                    onDatesChange([startDate, date as Date | null | undefined]);
                    onError('');
                  }
                } else {
                  onDatesChange([startDate, date as Date | null | undefined]);
                  onError('');
                }
              }}
              mask="9999/99/99"
              disabled={endDisabled}
              minDate={minDate}
              maxDate={getMaxEndDate()}
            />
          </div>
          <div className={inputWidth}>
            <TimePicker
              size="compact"
              creatable
              value={endDate}
              disabled={endDisabled}
              onChange={(time) => {
                if (time && startDate) {
                  const before = isBefore(time, startDate);
                  const beforeToday = isBefore(time, today);
                  if (todayIsMaxDate && beforeToday) {
                    onError(beforeTodayError);
                  } else if (before && !startDisabled) {
                    onDatesChange([time as Date, time as Date]);
                    onError('');
                  } else if (before && startDisabled) {
                    onError(beforeError);
                  } else {
                    onDatesChange([startDate, time as Date]);
                    onError('');
                  }
                }
              }}
              maxTime={getMaxEndDate()}
            />
          </div>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
}
