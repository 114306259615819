import { EnhancedLabeledSelect, FlexColumn, LabeledField, LabeledInput } from '@gorila-shared-ui/components';
import { ProgressSteps, Step } from 'baseui/progress-steps';
import { SafeGptConfigRequest, SafeGptConfigRequestTouched } from '../../../types/safeGpt';
import { Checkbox } from 'baseui/checkbox';
import { AlarmTypesSelect } from '../../shared/selects/AlarmTypesSelect';
import { Option } from 'baseui/select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForms } from '../../../hooks/useForms';
import {
  editSafeGptConfigState,
  editSafeGptConfigTouchedState,
  editSafeGptConfigValidState,
} from '../../../storage/safeGpt';
import { SubClientListPicker } from '../../shared/subClientListPicker/SubClientListPicker';
import { useStyles } from '../../../hooks/useStyles';

type Props = {
  isNew?: boolean;
  currentStep: number;
};

export function SafeGptConfigFormSteps({ isNew = false, currentStep }: Readonly<Props>) {
  const { css } = useStyles();
  const [form, setForm] = useRecoilState(editSafeGptConfigState);
  const [formTouched, setFormTouched] = useRecoilState(editSafeGptConfigTouchedState);
  const formValid = useRecoilValue(editSafeGptConfigValidState);
  const { onTouched, onChangeInput, onValidateField } = useForms<SafeGptConfigRequest, SafeGptConfigRequestTouched>(
    setForm,
    setFormTouched
  );

  const ignitionStatusOptions: Option[] = [
    {
      id: 'true',
      label: 'Si',
    },
    {
      id: 'false',
      label: 'No',
    },
    {
      id: 'null',
      label: 'Cualquiera',
    },
  ];

  const generalDataStep = (
    <Step
      title="Datos de la configuración"
      key="safeGptConfigData"
    >
      <FlexColumn>
        <LabeledInput
          label={'Nombre'}
          placeholder={'Ingrese el nombre de la configuración'}
          value={form.name}
          onChange={(value) => {
            onChangeInput('name', value);
          }}
          onBlur={() => onTouched('nameTouched')}
          error={onValidateField(formTouched.nameTouched, formValid.name)}
          required
        />
        <AlarmTypesSelect
          selectedAlarmTypeIds={form.alarmTypes}
          onChangeAlarmTypeIds={(value) => {
            onChangeInput('alarmTypes', value);
          }}
          onBlur={() => onTouched('alarmTypesTouched')}
          error={onValidateField(formTouched.alarmTypesTouched, formValid.alarmTypes)}
          required
        />
        <LabeledField
          label=""
          caption="Si la evaluación considerará `No. Eventos *` Y/O `Mismo tipo *`"
        >
          <Checkbox
            checked={form.inclusiveEvaluation}
            onChange={() => onChangeInput('inclusiveEvaluation', !form.inclusiveEvaluation)}
          >
            Evaluación inclusiva
          </Checkbox>
        </LabeledField>
        <LabeledInput
          label={'Ventana de Tiempo'}
          placeholder={'Ingrese la ventana de tiempo en minuntos'}
          value={form.timeWindow}
          onChange={(value) => {
            onChangeInput('timeWindow', +value);
          }}
          onBlur={() => onTouched('timeWindowTouched')}
          error={onValidateField(formTouched.timeWindowTouched, formValid.timeWindow)}
          endEnhancer={'min.'}
          required
        />
        <LabeledInput
          label={'No. Eventos'}
          placeholder={'Ingrese el numero de eventos'}
          value={form.totalEvents}
          onChange={(value) => {
            onChangeInput('totalEvents', +value);
          }}
          onBlur={() => onTouched('totalEventsTouched')}
          error={onValidateField(
            formTouched.totalEventsTouched,
            formValid.totalEvents,
            typeof form.totalEvents === 'number',
            'Este valor no puede ser 0'
          )}
          caption={'Este valor no puede ser 0'}
          required
        />
        <LabeledInput
          label={'Mismo tipo'}
          placeholder={'Ingrese el numero de eventos del mismo tipo'}
          value={form.totalDistinct}
          onChange={(value) => {
            onChangeInput('totalDistinct', +value);
          }}
          onBlur={() => onTouched('totalDistinctTouched')}
          error={onValidateField(
            formTouched.totalDistinctTouched,
            formValid.totalDistinct,
            typeof form.totalDistinct === 'number',
            'Este valor no puede ser 0'
          )}
          caption={'Este valor no puede ser 0'}
          required
        />
        <EnhancedLabeledSelect
          label={'Encendido'}
          options={ignitionStatusOptions}
          value={[{ id: `${form.ignitionStatus}` }]}
          onChange={(value) => {
            if (value.option?.id)
              onChangeInput(
                'ignitionStatus',
                value.option?.id === 'true' ? true : value.option?.id === 'null' ? null : false
              );
            else onChangeInput('ignitionStatus', null);
          }}
          fullWidth
          required
        />
        <LabeledInput
          label={'Manejo continuo'}
          placeholder={'Ingrese el tiempo en minutos'}
          value={form.continuousDriving}
          onChange={(value) => {
            onChangeInput('continuousDriving', +value);
          }}
          onBlur={() => onTouched('continuousDrivingTouched')}
          error={onValidateField(
            formTouched.continuousDrivingTouched,
            formValid.continuousDriving,
            typeof form.continuousDriving === 'number',
            'Este valor no puede ser 0'
          )}
          endEnhancer={'min.'}
          caption={'Este valor no puede ser 0'}
          required
        />
        <LabeledInput
          label={'Tiempo de espera'}
          placeholder={'Ingrese el tiempo de espera en minutos'}
          value={form.cooldown}
          onChange={(value) => {
            onChangeInput('cooldown', +value);
          }}
          onBlur={() => onTouched('cooldownTouched')}
          error={onValidateField(formTouched.cooldownTouched, formValid.cooldown)}
          endEnhancer={'min.'}
          required
        />
        <Checkbox
          checked={form.visualCheck ?? false}
          onChange={(e) => onChangeInput('visualCheck', e.target.checked)}
        >
          Requiere confirmación visual
        </Checkbox>
        {!isNew && (
          <Checkbox
            checked={form.active ?? true}
            onChange={(e) => onChangeInput('active', e.target.checked)}
          >
            Activo
          </Checkbox>
        )}
      </FlexColumn>
    </Step>
  );

  const subClientsStep = (
    <Step
      title={`Subclientes`}
      key="subClients"
    >
      <SubClientListPicker
        assignedSubClientIds={form.subClients}
        onAssignedChange={(subClientIds) => onChangeInput('subClients', subClientIds)}
        onlyEnabledSafeGpt
      />
    </Step>
  );

  const steps = [generalDataStep, subClientsStep];

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      <ProgressSteps
        current={currentStep}
        overrides={{
          Description: {
            style: () => ({
              overflowY: 'auto',
              maxHeight: `calc(75vh - ${136 * steps.length}px)`, //maximum modal size minus 136 * number of steps
            }),
          },
        }}
      >
        {steps}
      </ProgressSteps>
    </FlexColumn>
  );
}
