import { EllipsedText, MaterialIcon, StyledTooltip, useLoading, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { FLEET_HEALTH_RULE_COLUMNS_NAME } from '../../constants/fleetHealth';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getFleetHealthRuleList } from '../../services/fleetHealthService';
import { FleetHealthRule } from '../../types/fleetHealth';
import { ListWrapper } from '../shared/ListWrapper';
import FleetHealthRuleModal from './modal/FleetHealthRuleModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function FleetHealthRuleList({ search, reloadPage }: Readonly<Props>) {
  const [rules, setRules] = useState<FleetHealthRule[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedRule, setSelectedRule] = useState<FleetHealthRule>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadRules = async () => {
      const { fleetHealthRules, error, total } = await getFleetHealthRuleList(page, search);
      if (!error && fleetHealthRules) {
        setRules(fleetHealthRules);
        setTotalItems(total ?? 0);
      } else {
        setRules(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadRules();
  }, [page]);

  const openDetailModal = (rule: FleetHealthRule) => {
    setSelectedRule(rule);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedRule(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={FLEET_HEALTH_RULE_COLUMNS_NAME}
        data={
          rules?.map((rule, i) => {
            return {
              items: [
                <EllipsedText key={`rule-name-${i}`}>{rule.name}</EllipsedText>,
                <EllipsedText key={`rule-active-${i}`}>{rule.active ? 'Sí' : 'No'}</EllipsedText>,
                <EllipsedText key={`rule-maxNoCommunication-${i}`}>
                  {rule.maxNoCommunication ? rule.maxNoCommunication.toString() + ' min' : '-'}
                </EllipsedText>,
                <EllipsedText key={`rule-minBattery-${i}`}>
                  {rule.minBattery ? rule.minBattery.toString() + '%' : '-'}
                </EllipsedText>,
                <EllipsedText>{rule.models.length.toString()}</EllipsedText>,
                <ParagraphXSmall key={`rule-alarms-${i}`}>
                  {rule.alarms.map((alarm) => alarm.name).join(', ')}
                </ParagraphXSmall>, // {rule.alarms}
                <StyledTooltip
                  key={`edit-${i}`}
                  content={'Editar'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDetailModal(rule)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'edit'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={rules !== undefined}
        isEmpty={!rules?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna marca"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <FleetHealthRuleModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          fleetHealthRule={selectedRule}
        />
      )}
    </>
  );
}
