import { FlexColumn, FlexRow, LabeledInput, StyledButton, useLoading } from '@gorila-shared-ui/components';
import { StyledDivider } from 'baseui/divider';
import { useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { createFleetHealthEventLog } from '../../../services/fleetHealthService';
import { fleetHealthEventLogFormState, fleetHealthEventLogsPageState } from '../../../storage/fleetHealthStates';
import { FleetHealthEventLogRequest } from '../../../types/fleetHealth';

export function FleetHealthEventLogForm() {
  const { theme } = useStyles();
  const { eventId } = useParams();
  const [eventLogForm, setEventLogForm] = useRecoilState(fleetHealthEventLogFormState);
  const resetEventLogForm = useResetRecoilState(fleetHealthEventLogFormState);
  const setEventLogsPage = useSetRecoilState(fleetHealthEventLogsPageState);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();

  const updateEventLogs = () => {
    setEventLogsPage(0);
  };

  const onInputChange = (value: any, field: keyof FleetHealthEventLogRequest) => {
    setEventLogForm((prev) => ({ ...prev, [field]: value }));
  };

  const createLog = async () => {
    if (!eventId) return;
    startLoading();
    const { error } = await createFleetHealthEventLog(eventId, eventLogForm);
    if (!error) {
      updateEventLogs();
      resetEventLogForm();
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.logs));
    } else {
      showFailFeedback(FEEDBACK.failedCreation(FEEDBACK_PREFIXES.logs));
    }
    stopLoading();
  };

  const canSubmit = (() => {
    if (!eventLogForm.message) return false;
    return true;
  })();

  return (
    <FlexColumn gap={theme.sizing.scale300}>
      <StyledDivider style={{ width: '100%' }} />
      <FlexRow gap={theme.sizing.scale300}>
        <LabeledInput
          placeholder="Mensaje *"
          value={eventLogForm.message}
          onChange={(value) => onInputChange(value, 'message')}
          required
        />
        <StyledButton
          onClick={createLog}
          disabled={!canSubmit}
          isLoading={loading}
        >
          Guardar
        </StyledButton>
      </FlexRow>
    </FlexColumn>
  );
}
