import {
  FlexRow,
  MaterialIcon,
  StyledButton,
  StyledSearchBar,
  StyledTooltip,
  useModal,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { memo } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';
import { useStyles } from '../../hooks/useStyles';
import {
  installationCampaignState,
  installationDateRangeState,
  installationDownloadListState,
  installationFilterGDLState,
  installationFilterStatusState,
  installationIvrState,
  installationReloadListState,
  installationSearchState,
  tableColumnsState,
} from '../../storage/Installations';
import InstallationFilters from './InstallationFilters';
import CreateInstallationModal from './modal/CreateInstallationModal';

function InstalltionsFiltersBar() {
  const { css, theme } = useStyles();
  const { search, setSearch } = useDebouncedSearch(useRecoilState(installationSearchState));
  const setReloadPage = useSetRecoilState(installationReloadListState);
  const setDownloadPage = useSetRecoilState(installationDownloadListState);
  const resetSelectStatus = useResetRecoilState(installationFilterStatusState);
  const resetSelectCampaign = useResetRecoilState(installationCampaignState);
  const resetSearch = useResetRecoilState(installationSearchState);
  const resetSort = useResetRecoilState(tableColumnsState);
  const resetInstallationDateRange = useResetRecoilState(installationDateRangeState);
  const resetIvr = useResetRecoilState(installationIvrState);
  const resetSelectGDLStatus = useResetRecoilState(installationFilterGDLState);
  const { showModal, openModal, closeModal } = useModal();

  const resetFilters = () => {
    resetSearch();
    resetSelectStatus();
    resetSort();
    resetInstallationDateRange();
    resetSelectCampaign();
    resetIvr();
    resetSelectGDLStatus();
  };

  return (
    <FlexRow
      classNames={css({ alignItems: 'center' })}
      gap={'4px'}
    >
      <FlexRow
        classNames={css({ flex: '1', alignItems: 'center' })}
        gap={'4px'}
      >
        <span className={css({ flexGrow: '0.5' })}>
          <StyledSearchBar
            name={'convoy-search'}
            value={search}
            onChange={setSearch}
            clearable
          />
        </span>
        <InstallationFilters />
        <StyledTooltip
          content={'Limpiar filtros'}
          showArrow={false}
        >
          <Button
            size="mini"
            shape="round"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon
              name={'clear'}
              size="mini"
            />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <StyledTooltip
        content={'Descargar'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={() => setDownloadPage(true)}
          kind="tertiary"
        >
          <MaterialIcon
            name={'download_for_offline'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
      <StyledTooltip
        content={'Actualizar lista'}
        showArrow={false}
      >
        <Button
          size="mini"
          shape="round"
          onClick={() => setReloadPage((prev) => !prev)}
          kind="tertiary"
        >
          <MaterialIcon
            name={'refresh'}
            size="mini"
          />
        </Button>
      </StyledTooltip>
      <StyledButton
        onClick={openModal}
        startEnhancer={
          <MaterialIcon
            name="add"
            size="xs-mini"
            color={theme.colors.contentInversePrimary}
          />
        }
      >
        Nueva
      </StyledButton>
      <CreateInstallationModal
        isOpen={showModal}
        onClose={closeModal}
      />
    </FlexRow>
  );
}

export default memo(InstalltionsFiltersBar);
