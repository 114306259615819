import { EllipsedText, FlexRow, MaterialIcon, StyledTooltip, useLoading } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SUB_CLIENT_DETAIL_PAGE } from '../../../../constants/app';
import { DETAIL_SECTION, SUB_CLIENTS_TABLE_COLUMNS_NAME } from '../../../../constants/subClients';
import { useStyles } from '../../../../hooks/useStyles';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';
import { getSubClients } from '../../../../services/subClientService';
import { subClientsSearchFilterState } from '../../../../storage/subClients';
import { SubClient } from '../../../../types/subClient';
import { ListWrapper } from '../../../shared/ListWrapper';

type Props = {
  clientId: string;
  reloadPage?: boolean;
};

export function ClientSubClientList({ clientId, reloadPage }: Readonly<Props>) {
  const { css } = useStyles();
  const [subClients, setSubClients] = useState<SubClient[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const search = useRecoilValue(subClientsSearchFilterState);
  const navigate = useNavigate();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadSubClientsTableData = async () => {
      const response = await getSubClients({
        clientId: clientId,
        page,
        q: search,
      });
      if (response?.error) {
        setSubClients(undefined);
        setTotalItems(0);
      } else {
        setSubClients(response?.subClients);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadSubClientsTableData();
  }, [page]);

  const onSelectSubClient = (subClientId: string) => {
    const route = generatePath(SUB_CLIENT_DETAIL_PAGE.route, { subClientId, tabName: DETAIL_SECTION });
    navigate(route, { replace: false });
  };

  return (
    <ListWrapper
      columns={SUB_CLIENTS_TABLE_COLUMNS_NAME}
      data={subClients?.map((subClient, i) => {
        return {
          items: [
            <ParagraphXSmall
              margin={0}
              key={`subClient-name-${i}`}
            >
              <EllipsedText>{subClient.name ?? '-'}</EllipsedText>
            </ParagraphXSmall>,
            <ParagraphXSmall
              margin={0}
              paddingLeft={'30px'}
              key={`subClient-userAccounts-${i}`}
            >
              <EllipsedText>{`${subClient.userAccounts ?? '-'}`}</EllipsedText>
            </ParagraphXSmall>,
            <FlexRow
              key={`subClient-action-${i}`}
              classNames={css({ paddingLeft: '30px' })}
            >
              <StyledTooltip
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => onSelectSubClient(subClient._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>
            </FlexRow>,
          ],
        };
      })}
      hasData={subClients !== undefined}
      isEmpty={!subClients?.length}
      loading={loading}
      noDataDescription="Todavía no tienes ningun usuario"
      onPageChange={setPage}
      page={page}
      totalItems={totalItems}
    />
  );
}
