import { EnhancedLabeledSelect, useLoading } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getSubAccounts } from '../../../services/subAccountService';
import { SubAccountCore } from '../../../types/subAccount';

type Props = {
  selectedSubAccountId?: string;
  onChangeSubAccount: (subAccountId?: string) => void;
  selectedSubClientId?: string;
  isFilter?: boolean;
  label?: string;
  subAccount?: SubAccountCore;
  disabled?: boolean;
  inline?: boolean;
  required?: boolean;
};
export function SubAccountSelect({
  onChangeSubAccount,
  selectedSubAccountId,
  selectedSubClientId,
  subAccount,
  isFilter = true,
  label = 'Subcuenta:',
  disabled = false,
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [subAccountList, setSubAccountList] = useState<SubAccountCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [subClientId, setSubClientId] = useState<string>();
  const [selectedSubAccountOption, setSelectedSubAccountOption] = useState<{ id: string; label: string }>();
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch, subClientId, disabled]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    if (disabled) return;
    startLoading();
    const loadSubAccounts = async () => {
      const requestId = ++requestIdRef.current;
      const { subAccounts, error, hasNext } = await getSubAccounts({
        page,
        subClientId,
        q: search,
      });
      if (requestId === requestIdRef.current) {
        if (!error && subAccounts) {
          if (page > 1 && subAccountList) {
            setSubAccountList([...subAccountList, ...subAccounts]);
            setHasNextPage(hasNext);
          } else {
            setSubAccountList(subAccounts);
            setHasNextPage(hasNext);
          }
        } else {
          setSubAccountList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadSubAccounts();
  }, [page]);

  useEffect(() => {
    if (subClientId && selectedSubAccountId) onChangeSubAccount();
    setSubClientId(selectedSubClientId);
  }, [selectedSubClientId]);

  const subAccountOptions = useMemo(() => {
    if (!subAccountList) return [];
    const options: { id: string | undefined; label: string }[] = [
      ...subAccountList.map((subAccount) => ({ id: subAccount._id, label: subAccount.name })),
    ];
    if (!required) {
      options?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguna',
      });
    }
    if (subAccount) {
      if (!options.find((s) => s.id === subAccount._id))
        options.push({
          id: subAccount._id,
          label: subAccount.name,
        });
    }
    if (selectedSubAccountOption) {
      if (!options.find((c) => c.id === selectedSubAccountOption.id)) options.push(selectedSubAccountOption);
    }
    return options;
  }, [subAccountList]);

  const onSubAccountChange = (params: OnChangeParams) => {
    params.option?.id ? onChangeSubAccount(params.option.id as string) : onChangeSubAccount(undefined);
    setSelectedSubAccountOption(params.option as { id: string; label: string });
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={subAccountOptions}
      value={[{ id: selectedSubAccountId }]}
      onChange={onSubAccountChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      fullWidth
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      searchable
      onBlur={() => setSearch(undefined)}
      disabled={disabled}
      inline={inline}
      inset={inline}
      required={required}
    />
  );
}
