import { GenericComponent } from '@gorila-shared-ui/components';
import { StyledBody } from 'baseui/card';
import { useStyles } from '../../../hooks/useStyles';

export function FloatingCardContent({
  children,
  flexible = false,
  gap,
}: GenericComponent & { flexible?: boolean; gap?: string }) {
  const { classes } = useStyles();
  return (
    <StyledBody
      style={{ height: '100%', marginBottom: 0, gap }}
      className={`${flexible ? classes.verticalFlex : ''}`}
    >
      {children}
    </StyledBody>
  );
}
