import { ApiError } from '@gorila-shared-ui/components';
import * as uuid from 'uuid';
import { Template, Whatsapp, WhatsappRequest } from '../types/whatsapp';
import { getRequest, postRequest, uploadRequest } from './api';
import { URLS } from './urls/urls';

export const getWhatsappsList = async (
  page?: number,
  q?: string
): Promise<{ whatsapps?: Whatsapp[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
  };
  const response = await getRequest(URLS.whatsapp.all, {
    queryParams: params,
  });
  return {
    whatsapps: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const getWhatsappDetail = async (whatsappId: string): Promise<{ whatsapp: Whatsapp; error?: ApiError }> => {
  const response = await getRequest(URLS.whatsapp.detail(whatsappId));
  return {
    whatsapp: response?.data,
    error: response?.error,
  };
};

export const createWhatsapp = async (whatsapp: WhatsappRequest): Promise<{ id?: string; error?: ApiError }> => {
  const { file, ...whatsappRequest } = whatsapp;
  const body = {
    ...whatsappRequest,
  };
  const response = await postRequest(URLS.whatsapp.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const saveWhatappFile = async (file: File): Promise<{ path?: string; error?: ApiError }> => {
  const myId = uuid.v4();
  const response = await uploadRequest(`public/whatsappCampaign/${myId}.csv`, file, {
    contentType: file.type,
  });

  return {
    path: response?.data?.key,
    error: response?.error,
  };
};

export const getTemplateList = async (): Promise<{ templates?: Template[]; error?: ApiError }> => {
  const response = await getRequest(URLS.whatsapp.templates);
  return {
    templates: response?.data?.items,
    error: response?.error,
  };
};
