import {
  EllipsedText,
  FlexColumn,
  FlexRow,
  MaterialIcon,
  StyledButton,
  useElementWidth,
} from '@gorila-shared-ui/components';
import { Panel, StatelessAccordion } from 'baseui/accordion';
import { Button } from 'baseui/button';
import { ChevronDown } from 'baseui/icon';
import { Item, Navigation, NavigationProps } from 'baseui/side-navigation';
import { ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHover } from 'usehooks-ts';
import { ALL_PAGES, EXPANDED_SIDENAV_WIDTH, SIDENAV_WIDTH } from '../../../constants/app';
import { useSidenavItems } from '../../../hooks/useSidenavItems';
import { useStyles } from '../../../hooks/useStyles';
import { currentPageState, sidenavIsPinnedState, sidenavSectionsState } from '../../../storage/app';
import { Page } from '../../../types/app';
import { getPageIdByReportTypeRoute, getPageRoute } from '../../../utils/pages';
import { UserCard } from './UserCard';

export function Sidenav() {
  const { css, theme, classes, concatenateClasses } = useStyles();
  const navigate = useNavigate();
  const [sidenavIsPinned, setSidenavIsPinned] = useRecoilState(sidenavIsPinnedState);
  const currentPage = useRecoilValue(currentPageState);
  const location = useLocation();
  const [expanded, setExpanded] = useRecoilState(sidenavSectionsState);
  const { elementRef } = useElementWidth();
  const isHover = useHover(elementRef);
  const version = process.env.REACT_APP_VERSION;

  const activeItemId = useMemo(() => {
    if (currentPage.id === 'reportsPanels') {
      const reportPageId = getPageIdByReportTypeRoute(location.pathname);
      if (reportPageId) return reportPageId;
    }
    return currentPage.parentId ?? currentPage.id;
  }, [currentPage, location]);

  const itemTitle = (page: Page) => {
    return page.name;
  };

  const items: Item[] = ALL_PAGES.map((page) => ({
    itemId: page.id,
    title: (
      <FlexRow
        classNames={concatenateClasses(classes.centeredStart, css({ overflow: 'hidden' }))}
        gap={theme.sizing.scale400}
      >
        {page.icon && (
          <span className={css({ flexShrink: 0, lineHeight: 0 })}>
            <MaterialIcon name={page.icon} />
          </span>
        )}
        <EllipsedText inline>{itemTitle(page)}</EllipsedText>
        {/* {(isHover || sidenavIsPinned) && <EllipsedText inline>{itemTitle(page)}</EllipsedText>} */}
      </FlexRow>
    ),
  }));
  const { coreItems, sections, pageSection } = useSidenavItems(items);

  useEffect(() => {
    setExpanded(!pageSection ? [] : [pageSection]);
  }, [pageSection]);

  const navigationProps: Omit<NavigationProps, 'items'> = {
    activeItemId: activeItemId,
    onChange: ({ event, item }) => {
      event.preventDefault();
      const route = getPageRoute(item.itemId ?? '');
      if (route) {
        navigate(route, { replace: false });
      }
    },
    overrides: {
      NavItem: {
        style: () => {
          return {
            paddingLeft: theme.sizing.scale750,
            paddingRight: theme.sizing.scale600,
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale300,
          };
        },
      },
    },
  };

  const sidenavWidth = (() => {
    if (!sidenavIsPinned && !isHover) {
      return `${SIDENAV_WIDTH}px`;
    }
    return `${EXPANDED_SIDENAV_WIDTH}px`;
  })();

  return (
    <FlexColumn
      ref={elementRef}
      gap={0}
      classNames={css({
        display: 'flex',
        backgroundColor: theme.colors.backgroundSecondary,
        width: sidenavWidth,
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        transition: 'left 0.3s ease, margin-left 0.2s ease-in-out, width 0.3s ease',
        zIndex: '1',
      })}
    >
      <UserCard />
      <FlexColumn
        gap={theme.sizing.scale400}
        classNames={css({ overflowY: 'auto', overflowX: 'hidden' })}
      >
        <Navigation
          items={coreItems}
          {...navigationProps}
        />

        <StatelessAccordion
          expanded={expanded}
          onChange={({ key, expanded }) => {
            if (pageSection !== key) setExpanded(expanded);
          }}
          accordion={false}
          overrides={{
            Root: {
              style: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.sizing.scale600,
              },
            },
            Header: {
              style: {
                backgroundColor: 'transparent',
                paddingTop: theme.sizing.scale200,
                paddingBottom: theme.sizing.scale200,
                paddingLeft: theme.sizing.scale850,
                paddingRight: theme.sizing.scale600,
              },
              component: ({ children, $expanded, onClick, $style, $disabled }) => {
                return (
                  <Button
                    onClick={onClick}
                    kind={'tertiary'}
                    size="mini"
                    style={$style}
                    disabled={$disabled}
                  >
                    <ChevronDown
                      title=""
                      size={theme.sizing.scale800}
                      overrides={{
                        Svg: {
                          style: {
                            transition: 'transform 0.2s ease-in-out',
                            transform: $expanded ? undefined : 'rotate(-90deg)',
                          },
                        },
                      }}
                    />
                    <ParagraphMedium
                      margin={0}
                      style={{
                        display: isHover || sidenavIsPinned ? 'block' : 'none',
                        transition: 'display 0.2s ease-in-out',
                      }}
                    >
                      <EllipsedText inline>{children}</EllipsedText>
                    </ParagraphMedium>
                  </Button>
                );
              },
            },
            Content: {
              style: {
                backgroundColor: 'transparent',
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
            },
            PanelContainer: {
              style: {
                borderBottomStyle: 'none',
              },
            },
            ToggleIcon: {
              component: () => {
                return null;
              },
            },
          }}
        >
          {sections.map((section) => (
            <Panel
              key={section.id}
              disabled={pageSection === section.id}
              title={section.title}
            >
              <Navigation
                items={section.items}
                {...navigationProps}
              />
            </Panel>
          ))}
        </StatelessAccordion>
      </FlexColumn>
      <FlexRow
        centered
        classNames={css({
          padding: theme.sizing.scale500,
          marginTop: 'auto',
        })}
      >
        <StyledButton
          kind="secondary"
          onClick={() => setSidenavIsPinned((prev) => !prev)}
          full
        >
          <FlexRow centered>
            <MaterialIcon name={sidenavIsPinned ? 'keep_off' : 'keep'} />
          </FlexRow>
        </StyledButton>
      </FlexRow>
      {version && (
        <FlexRow
          centered
          classNames={css({
            paddingRight: theme.sizing.scale500,
            paddingLeft: theme.sizing.scale500,
            marginBottom: theme.sizing.scale500,
          })}
        >
          <ParagraphXSmall margin={0}>v{version}</ParagraphXSmall>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
