import { ApiError } from '@gorila-shared-ui/components';
import { Client, ClientCore } from '../types/client';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getClientsList = async (
  page?: number,
  q?: string
): Promise<{ clients?: ClientCore[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
  };
  const response = await getRequest(URLS.clients.all, {
    queryParams: { ...params },
  });
  return {
    clients: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const getClient = async (clientId: string): Promise<{ client: Client; error?: ApiError }> => {
  const response = await getRequest(URLS.clients.detail(clientId));
  return {
    client: response?.data,
    error: response?.error,
  };
};

export const postClient = async (client: Client): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: client.name,
    paid: client.paid ?? false,
    paymentDay: client.paymentDay,
    rfc: client.rfc,
    tradeName: client.tradeName,
    contact: client.contact,
    installationContact: client.installationContact,
    invoiceContact: client.invoiceContact,
  };
  const response = await postRequest(URLS.clients.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
export const updateClient = async (client: Client): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: client.name,
    paid: client.paid ?? false,
    paymentDay: client.paymentDay,
    rfc: client.rfc,
    tradeName: client.tradeName,
    contact: client.contact,
    installationContact: client.installationContact,
    invoiceContact: client.invoiceContact,
  };
  const response = await postRequest(URLS.clients.update(client._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateClientPaid = async (client: ClientCore): Promise<{ id?: string; error?: ApiError }> => {
  const response = await postRequest(URLS.clients.update(client._id), {
    body: client,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
