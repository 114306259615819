import { isValidLatitude, isValidLongitude } from '@gorila-shared-ui/components';
import { FleetHealthEvent } from '../types/fleetHealth';

export const getValidPositions = (events: FleetHealthEvent[]): number[][] => {
  const positions = events.map((event) => {
    const lat = event.lastEvent.latitude;
    const lng = event.lastEvent.longitude;

    const hasValidPosition = !!lat && !!lng && isValidLatitude(lat) && isValidLongitude(lng);

    return hasValidPosition ? [lng, lat] : undefined;
  });
  return positions.filter((position): position is number[] => position !== undefined);
};


export const formatDaysMinutes = (minutes: number): string => {
  const MINUTES_IN_HOUR = 60;
  const MINUTES_IN_DAY = 24 * MINUTES_IN_HOUR;

  const days = Math.floor(minutes / MINUTES_IN_DAY);
  const remainingMinutes = minutes % MINUTES_IN_DAY;
  const hours = Math.floor(remainingMinutes / MINUTES_IN_HOUR);

  if (days > 0) {
    return `${days}d ${hours}h`;
  } else {
    return `${hours}h`;
  }

}