import {
  FlexRow,
  MaterialIcon,
  StyledButton,
  StyledSearchBar,
  StyledTooltip,
  useModal,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useResetRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import { assetTypeState } from '../../storage/assets';
import { AssetFilters } from './AssetFilters';
import AssetDetailModal from './modal/AssetDetailModal';
import AssetFiltersModal from './modal/AssetFiltersModal';

type Props = {
  search?: string;
  selectedClientId?: string;
  selectedSubClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  setSelectedSubClientId: (subClientId?: string) => void;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function AssetFiltersBar({
  search,
  onChangeSearch,
  onReloadPage,
  selectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
  setSelectedClientId,
}: Props) {
  const { css, theme } = useStyles();
  const { openModal: openFilterModal, closeModal: closeFilterModal, showModal: showFilterModal } = useModal();
  const { openModal: openFormModal, closeModal: closeFormModal, showModal: showFormModal } = useModal();
  const resetSelectedAssetType = useResetRecoilState(assetTypeState);
  const { isLarge } = useBreakpoints();

  const resetFilters = () => {
    onChangeSearch(undefined);
    resetSelectedAssetType();
    setSelectedClientId(undefined);
    setSelectedSubClientId(undefined);
  };

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <FlexRow
          classNames={css({
            width: '60%',
          })}
        >
          <StyledSearchBar
            value={search ?? ''}
            onChange={onChangeSearch}
            clearable
          />
        </FlexRow>
        {!isLarge && (
          <AssetFilters
            selectedClientId={selectedClientId}
            selectedSubClientId={selectedSubClientId}
            setSelectedClientId={setSelectedClientId}
            setSelectedSubClientId={setSelectedSubClientId}
          />
        )}
        {isLarge && (
          <StyledTooltip
            content={'Filtros'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={openFilterModal}
              kind="tertiary"
            >
              <MaterialIcon name={'filter_list'} />
            </Button>
          </StyledTooltip>
        )}
        <StyledTooltip
          content={'Restaurar filtros'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon name={'autorenew'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openFormModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nueva
        </StyledButton>
      </FlexRow>
      {showFilterModal && (
        <AssetFiltersModal
          onClose={closeFilterModal}
          isOpen={showFilterModal}
          selectedClientId={selectedClientId}
          selectedSubClientId={selectedSubClientId}
          setSelectedClientId={setSelectedClientId}
          setSelectedSubClientId={setSelectedSubClientId}
        />
      )}
      {showFormModal && (
        <AssetDetailModal
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeFormModal();
          }}
          isOpen={showFormModal}
        />
      )}
    </FlexRow>
  );
}
