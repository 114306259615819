import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  LabeledSelect,
  StyledBanner,
  StyledButton,
  useLoading,
} from '@gorila-shared-ui/components';
import { Delete } from 'baseui/icon';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { Step } from 'baseui/progress-steps';
import { Option } from 'baseui/select';
import { HeadingXSmall } from 'baseui/typography';
import { memo, useEffect, useMemo, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import { MIN_HEIGHT_MAP } from '../../../constants/installations';
import { useFeedback } from '../../../hooks/useFeedback';
import { useInstallationCoords } from '../../../hooks/useInstallationCoords';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { postAsset } from '../../../services/assetService';
import { updateInstallation } from '../../../services/installationService';
import { createFSMJob, getWorkersList } from '../../../services/jobService';
import { editingInstallationErrorState, editingInstallationState } from '../../../storage/Installations';
import { Asset, AssetMinimal } from '../../../types/asset';
import { Container } from '../../../types/container';
import { Installation, InstallationAddressRequest } from '../../../types/installation';
import { JobSchedule, Worker } from '../../../types/job';
import { Vehicle } from '../../../types/vehicle';
import { getInstallationClient } from '../../../utils/installations';
import { AssetFormSteps } from '../../assets/AssetFormSteps';
import Map from '../../map/Map';

type Props = {
  onClose: (update?: boolean) => void;
  isOpen: boolean;
  installation: Installation;
};

function ChangeJobModal({ onClose, isOpen, installation }: Props) {
  const { theme, classes, css } = useStyles();
  const [editingAsset, setEditingAsset] = useState<AssetMinimal | undefined>();
  const [assetSelected, setAssetSelected] = useState<Asset>();
  const [isNewAsset, setIsNewAsset] = useState(false);
  const [jobWorkers, setJobWorkers] = useState<Worker[]>();
  const [jobSchedule, setJobSchedule] = useState<JobSchedule>();
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const { loading, startLoading, stopLoading } = useLoading();
  const [error, setError] = useState('');
  const requireAssetData = editingAsset?.assetType === 'container' || editingAsset?.assetType === 'vehicle';
  const totalSteps = isNewAsset && requireAssetData ? 4 : 3;
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = currentStep + 1 === totalSteps;
  const [installationForm, setInstallationForm] = useRecoilState(editingInstallationState);
  const installationFormHasError = useRecoilValue(editingInstallationErrorState);
  const [jobTypeSelected, setJobTypeSelected] = useState<Option>();

  useEffect(() => {
    if (isOpen) {
      setJobSchedule((prev) => ({
        ...prev!,
        expenses: 0,
        overprice: 0,
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    let prefix = 'Instalación';
    if (jobTypeSelected?.label) {
      prefix = `${jobTypeSelected.label}`.split(' ')[0];
    }
    onJobChange(`${prefix} para ${getInstallationClient(installation)}`, 'name');
  }, [installation, jobTypeSelected]);

  const getWorkers = async () => {
    if (!jobSchedule) return;
    const { workers, error } = await getWorkersList(
      installation._id,
      jobSchedule.appointmentDate,
      jobSchedule.jobTypeId
    );
    if (!error) {
      setJobWorkers(workers);
    } else {
      showFailFeedback(error);
    }
  };

  useInstallationCoords(installationForm, setInstallationForm);

  useEffect(() => {
    if (!jobSchedule?.appointmentDate || !jobSchedule.jobTypeId) return;
    getWorkers();
  }, [jobSchedule?.appointmentDate, jobSchedule?.jobTypeId]);

  const jobsTypeOptions = useMemo(() => {
    return [
      ...installation.campaign.job_types.map((job) => ({
        id: job.pk,
        label: job.name,
      })),
    ];
  }, [installation]);

  const workersOptions = useMemo(() => {
    if (!jobWorkers) return;
    return [
      ...jobWorkers.map((worker) => ({
        id: worker.user_id,
        label: worker.full_name,
      })),
    ];
  }, [jobWorkers]);

  const onJobChange = (value: string | number, field: keyof JobSchedule) => {
    setJobSchedule((prev) => {
      return {
        ...prev!,
        [field]: value || '',
      };
    });
  };

  const onUpdateJob = async () => {
    if (!jobSchedule || !editingAsset) return;
    startLoading();
    let assetId = isNewAsset ? undefined : assetSelected?._id;
    if (isNewAsset) {
      const { id, error: assetError } = await postAsset(editingAsset);
      if (!assetError && id) assetId = id;
      else if (assetError) {
        setError(typeof assetError === 'string' ? assetError : assetError.toString());
        showFailFeedback(assetError as string);
      }
    }
    if (!installationFormHasError) {
      const { error: installationError } = await updateInstallation(installation._id, installationForm);
      if (installationError) {
        showFailFeedback(installationError);
      }
    } else {
      await updateInstallation(installation._id, {
        ...installationForm,
        latitude: 0,
        longitude: 0,
      });
    }
    if (assetId) {
      const { error } = await createFSMJob(installation._id, jobSchedule, assetId);
      if (!error) {
        showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.job));
        resetModal(true);
      } else {
        setError(typeof error === 'string' ? error : error.toString());
        showFailFeedback(error);
      }
    }
    stopLoading();
  };

  const validateVehicle = (vehicle?: Vehicle) => {
    if (!vehicle) return false;
    if (
      !vehicle.brand?._id ||
      !vehicle.subBrand?._id ||
      !vehicle.year ||
      !vehicle.color ||
      !vehicle.licensePlate ||
      !vehicle.vehicleType ||
      !vehicle.vin
    )
      return false;
    return true;
  };

  const validateContainer = (container?: Container) => {
    if (!container) return false;
    if (!container.identifier) return false;
    return true;
  };

  const canContinue = isNewAsset
    ? !!editingAsset?.label && !!editingAsset?.client?._id && !!editingAsset?.subClient?._id
    : !!assetSelected;

  const validateAsset = () => {
    let errors = 0;
    if (!canContinue) errors += 1;
    if (editingAsset?.assetType === 'vehicle') errors += validateVehicle(editingAsset?.vehicle) ? 0 : 1;
    else if (editingAsset?.assetType === 'container') errors += validateContainer(editingAsset?.container) ? 0 : 1;
    return errors === 0;
  };

  const canSubmit = useMemo(() => {
    let errors = 0;
    if (isNewAsset) {
      if (!validateAsset()) errors += 1;
    } else if (!assetSelected) errors += 1;
    if (!jobSchedule?.appointmentDate || !jobSchedule.jobTypeId || !jobSchedule.userId) errors += 1;
    return errors === 0;
  }, [editingAsset, jobSchedule]);

  const resetModal = (update?: boolean) => {
    setJobSchedule(undefined);
    setJobWorkers(undefined);
    setEditingAsset(undefined);
    setAssetSelected(undefined);
    setIsNewAsset(false);
    setError('');
    setCurrentStep(0);
    if (!update) setInstallationForm(installation);
    onClose(update);
  };

  const onInstallationInputChange = (value: any, field: keyof InstallationAddressRequest) => {
    setInstallationForm((prev) => ({ ...prev!, [field]: value }));
  };

  useUpdateEffect(() => {
    const date = jobSchedule?.appointmentDate ? new Date(jobSchedule?.appointmentDate).toLocaleDateString() : '';
    const label = jobTypeSelected?.label ? `${jobTypeSelected.label}` : '';
    onJobChange(label && date ? `${label} - ${date}` : label ? label : date ? date : '', 'description');
  }, [jobSchedule?.appointmentDate, jobTypeSelected]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => resetModal()}
      isOpen={isOpen}
      size="auto"
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Origen: {installation.campaign.name}</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        <FlexColumn
          classNames={css({
            width: '60vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'auto',
          })}
        >
          {error && (
            <StyledBanner
              title="*Error*"
              kind="negative"
              action={{
                label: 'label',
                icon: () => <Delete />,
                onClick: () => {
                  setError('');
                },
              }}
            >
              {error}
            </StyledBanner>
          )}
          <AssetFormSteps
            currentStep={currentStep}
            onAssetChange={setEditingAsset}
            asset={editingAsset}
            installation={installation}
            isNewAsset={isNewAsset}
            createNewAsset={setIsNewAsset}
            onSelectedAssetChange={setAssetSelected}
            additionalSteps={[
              <Step
                title="Datos de la Cita"
                key="appointmentData"
              >
                <FlexColumn>
                  <LabeledInput
                    label="Día de la cita"
                    value={jobSchedule?.appointmentDate}
                    type="datetime-local"
                    onChange={(value) => {
                      onJobChange(value, 'appointmentDate');
                    }}
                    required
                  />
                  <LabeledSelect
                    label="Tipo"
                    options={jobsTypeOptions}
                    value={[{ id: jobSchedule?.jobTypeId }]}
                    onChange={(params) => {
                      onJobChange(params.option?.id as string, 'jobTypeId');
                      setJobTypeSelected(params.option || undefined);
                    }}
                    searchable
                    required
                  />
                  <LabeledSelect
                    label="Tecnico"
                    options={workersOptions}
                    value={[{ id: jobSchedule?.userId }]}
                    onChange={(params) => onJobChange(params.option?.id as string, 'userId')}
                    disabled={!jobSchedule?.appointmentDate || !jobSchedule.jobTypeId}
                    searchable
                    required
                  />
                  <LabeledInput
                    label="Viáticos"
                    value={jobSchedule?.expenses}
                    onChange={(value) => onJobChange(+value, 'expenses')}
                    type="number"
                  />
                  <LabeledInput
                    label="Sobre precio"
                    value={jobSchedule?.overprice}
                    onChange={(value) => onJobChange(+value, 'overprice')}
                    type="number"
                  />
                  <LabeledInput
                    label="Nombre"
                    value={jobSchedule?.name}
                    onChange={(value) => onJobChange(value, 'name')}
                  />
                  <LabeledInput
                    label="Descripción"
                    value={jobSchedule?.description}
                    readonly
                  />
                </FlexColumn>
              </Step>,
              <Step
                title="Ubicación de la Cita"
                key="appointmentLocationData"
              >
                <FlexColumn>
                  <LabeledInput
                    label="Latitud"
                    value={installationForm.latitude ?? ''}
                    onChange={(value) => onInstallationInputChange(value, 'latitude')}
                  />
                  <LabeledInput
                    label="Longitud"
                    value={installationForm.longitude ?? ''}
                    onChange={(value) => onInstallationInputChange(value, 'longitude')}
                  />
                  <LabeledInput
                    label="Georeferencia"
                    value={installationForm.georeference}
                    onChange={(value) => onInstallationInputChange(value, 'georeference')}
                    disabled
                  />
                  <FlexRow
                    classNames={css({
                      minHeight: MIN_HEIGHT_MAP,
                    })}
                  >
                    <Map fullWindow={false} />
                  </FlexRow>
                </FlexColumn>
              </Step>,
            ]}
          />
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          {currentStep > 0 && (
            <StyledButton
              kind="tertiary"
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Regresar
            </StyledButton>
          )}
          <StyledButton
            kind="tertiary"
            onClick={() => resetModal()}
          >
            Cancelar
          </StyledButton>
          {!isLastStep && (
            <StyledButton
              onClick={() => {
                flushSync(() => {
                  setCurrentStep((prev) => prev + 1);
                });
                if (currentStep + 1 === totalSteps - 1) {
                  let map = document.getElementById('map');
                  map?.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              disabled={(() => {
                if (currentStep === 0) {
                  return !canContinue;
                }
                if (requireAssetData && isNewAsset && currentStep === 1) {
                  switch (editingAsset?.assetType) {
                    case 'container':
                      return !validateContainer(editingAsset.container);
                    case 'vehicle':
                      return !validateVehicle(editingAsset.vehicle);
                  }
                }
                return !canSubmit;
              })()}
            >
              Siguiente
            </StyledButton>
          )}
          {isLastStep && (
            <StyledButton
              onClick={onUpdateJob}
              disabled={(isNewAsset && !validateAsset()) || !canSubmit}
              isLoading={loading}
            >
              Guardar
            </StyledButton>
          )}
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(ChangeJobModal);
