import {
  EmptyState,
  FlexColumn,
  FlexRow,
  LabeledData,
  Loading,
  MaterialIcon,
  StyledTooltip,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { LabelMedium } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { getDeviceModel } from '../../../services/deviceService';
import { DeviceModel } from '../../../types/deviceModel';
import { DeviceModelForm } from '../DeviceModelForm';

type Props = {
  isOpen: boolean;
  selectedDeviceModel?: DeviceModel;
  onClose: (update?: boolean) => void;
};
function DeviceModelDetailModal({ onClose, isOpen, selectedDeviceModel }: Props) {
  const { css } = useStyles();
  const [deviceModel, setDeviceModel] = useState<DeviceModel>();
  const [editingMode, setEditingMode] = useState(false);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen && selectedDeviceModel) setEditingMode(false);
  }, [isOpen]);

  const loadDeviceModelData = async (deviceModelId: string) => {
    startLoading();
    const response = await getDeviceModel(deviceModelId);
    if (response?.error) {
      setDeviceModel(undefined);
      setError(true);
    } else {
      setDeviceModel(response.model);
      setError(false);
    }
    stopLoading();
  };

  useEffect(() => {
    if (!selectedDeviceModel) {
      setDeviceModel(undefined);
      setEditingMode(true);
      return;
    } else {
      loadDeviceModelData(selectedDeviceModel._id);
    }
  }, [selectedDeviceModel]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && error && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <EmptyState
            title="*Error*"
            description="No se pudieron obtener los datos del modelo"
          />
        </FlexColumn>
      )}
      {!loading && !error && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              {deviceModel?.name ?? 'Nuevo'}
            </ModalHeader>
            {!editingMode && (
              <StyledTooltip
                content={'Editar'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => setEditingMode(true)}
                  kind="tertiary"
                >
                  <MaterialIcon
                    name={'edit'}
                    size="xs-mini"
                  />
                </Button>
              </StyledTooltip>
            )}
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                width: '70vw',
                maxWidth: '1200px',
                minWidth: '350px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
              })}
            >
              {!editingMode && deviceModel?.brand.name && (
                <LabeledData
                  label={<LabelMedium minWidth={'110px'}>{'Marca:'}</LabelMedium>}
                  content={deviceModel?.brand.name}
                />
              )}
              {editingMode && (
                <DeviceModelForm
                  deviceModel={deviceModel}
                  onCancel={() => setEditingMode(false)}
                  afterSave={() => onClose(true)}
                />
              )}
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(DeviceModelDetailModal);
