import { PaginatedColumn } from '../types/app';
import {
  InstallationAddressRequest,
  InstallationAddressRequestTouched,
  InstallationRequest,
  InstallationRequestTouched,
} from '../types/installation';
import { JobTypeCategory } from '../types/job';
import { InstallationLogRequest } from '../types/logs';

export const INSTALLATION_TABLE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Fuente',
    key: 'insuranceCompany',
    sorteable: true,
    minWidth: 75,
    maxWidth: 75,
    centered: true,
  },
  {
    label: 'Serie',
    key: 'vin',
    sorteable: true,
    minWidth: 130,
    maxWidth: 130,
  },
  {
    label: 'Asignado',
    key: 'fchAsignado',
    sorteable: true,
    minWidth: 90,
    maxWidth: 90,
    centered: true,
  },
  {
    label: 'Proceso',
    key: 'fchEnProceso',
    sorteable: true,
    minWidth: 90,
    maxWidth: 90,
    centered: true,
  },
  {
    label: 'Instalación',
    key: 'fchInstaladoFSM',
    sorteable: true,
    minWidth: 95,
    maxWidth: 95,
    centered: true,
  },
  {
    label: 'Est. Aseg.',
    key: 'estatusInstalacion',
    sorteable: true,
    minWidth: 100,
    maxWidth: 100,
    centered: true,
  },
  {
    label: 'Est. Gor.',
    key: 'gdlStatus',
    sorteable: true,
    minWidth: 100,
    maxWidth: 100,
    centered: true,
  },
  {
    label: 'id Job Fsm',
    key: 'externalId',
    sorteable: true,
    minWidth: 100,
    maxWidth: 100,
    centered: true,
  },
  {
    label: 'Categoría',
    key: 'jobCategory',
    sorteable: true,
    minWidth: 160,
    maxWidth: 160,
  },
  {
    label: 'Cliente',
    key: 'contratante',
    sorteable: true,
    minWidth: 180,
    flex: 1,
  },
  {
    label: '# Intentos',
    key: 'contactAttempts',
    sorteable: true,
    minWidth: 100,
    maxWidth: 100,
    centered: true,
  },
  {
    label: 'Último Contacto',
    key: 'lastContactAttempt',
    sorteable: true,
    minWidth: 140,
    maxWidth: 140,
  },
  {
    label: 'IVR',
    key: 'isIVR',
    sorteable: true,
    minWidth: 60,
    maxWidth: 60,
    centered: true,
  },
  {
    label: 'Últ. Comentario',
    key: 'lastLog',
    sorteable: false,
    minWidth: 150,
    flex: 2,
  },
  {
    label: 'Modificado',
    key: 'lastModifiedBy',
    sorteable: false,
    minWidth: 110,
    centered: true,
    flex: 1,
  },
  {
    label: '',
    key: 'actions',
    sorteable: false,
    minWidth: 100,
    maxWidth: 130,
    centered: true,
  },
];

export const ORIGIN_FILTER_OPTIONS = [
  {
    label: 'Todos',
    id: undefined,
  },
  {
    label: 'GNP',
    id: 'gnp',
  },
  {
    label: 'Gorila',
    id: 'gdl',
  },
];

export const IVR_FILTER_OPTIONS = [
  {
    label: 'Todos',
    id: undefined,
  },
  {
    label: 'Particular',
    id: 'true',
  },
  {
    label: 'Pyme',
    id: 'false',
  },
];

export const GDL_FILTER_OPTIONS = [
  {
    label: 'Todos',
    id: undefined,
  },
  {
    label: 'Prospección',
    id: 'EP',
  },
  {
    label: 'Asignación Requerida',
    id: 'AR',
  },
  {
    label: 'Prospección Rechazada',
    id: 'PR',
  },
  {
    label: 'Por concretar',
    id: 'PC',
  },
  {
    label: 'Concretado',
    id: 'C',
  },
  {
    label: 'Sin respuesta',
    id: 'SR',
  },
  {
    label: 'Rechazado',
    id: 'R',
  },
  {
    label: 'Checkout',
    id: 'CO',
  },
  {
    label: 'Pendiente',
    id: 'PE',
  },
  {
    label: 'Inicio',
    id: 'I',
  },
  {
    label: 'En progreso',
    id: 'P',
  },
  {
    label: 'Finalizado',
    id: 'F',
  },
  {
    label: 'Cancelado',
    id: 'CA',
  },
];

export const MIN_WIDTH_MAP = '400px';
export const MIN_HEIGHT_MAP = '220px';
export const DATA_WIDTH = '280px';
export const DATA_XL_WIDTH = '560px';

export const ADDRESS_INSTALLATION_DEFAULTS: InstallationAddressRequest = {
  georeference: '',
};

export const NEW_INSTALLATION_DEFAULTS: InstallationRequest = {
  engineNumber: '',
  policyNumber: '',
  vin: '',
  licensePlate: '',
  client: {
    rfc: '',
    name: '',
    lastName: '',
    motherName: '',
    phone: '',
    email: '',
  },
  georeference: '',
  category: JobTypeCategory.installation,
};

export const ADDRESS_INSTALLATION_TOUCHED_DEFAULTS: InstallationAddressRequestTouched = {
  georeferenceTouched: false,
  latitudeTouched: false,
  longitudeTouched: false,
};

export const NEW_INSTALLATION_TOUCHED_DEFAULTS: InstallationRequestTouched = {
  brandTouched: false,
  subBrandTouched: false,
  modelTouched: false,
  engineNumberTouched: false,
  policyNumberTouched: false,
  vinTouched: false,
  licensePlateTouched: false,
  clientTouched: {
    rfcTouched: false,
    nameTouched: false,
    lastNameTouched: false,
    phoneTouched: false,
    emailTouched: false,
  },
  insuranceCompanyTouched: false,
  georeferenceTouched: false,
  latitudeTouched: false,
  longitudeTouched: false,
  categoryTouched: false,
};

export const MINIMAL_MODEL_YEAR = 1886;

export const NEW_LOG_DEFAULTS: InstallationLogRequest = {
  logType: undefined,
  comment: undefined,
  logSubType: undefined,
};

// Valid states to generate a job appointment
export const INSTALLATION_STATES_VALID = [
  'EP', // En proceso
  'NC', // No contactado
  'NI', // No instalado
];
