import { ApiError } from '@gorila-shared-ui/components';
import { ApiEndpoint } from '../types/api';
import {
  Campaign,
  Installation,
  InstallationAddressRequest,
  InstallationCore,
  InstallationRequest,
  InstallationStatus,
  UpdatedInstallationStatusData,
} from '../types/installation';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';
import { JobTypeCategory } from '../types/job';

export const getInstallationsList = async (
  page = 1,
  q?: string,
  status?: string,
  orderBy?: string,
  order?: string | null,
  startDate?: number | null,
  endDate?: number | null,
  origin?: string,
  ivr?: boolean,
  gdlStatus?: string,
  emissionType?: JobTypeCategory | string,
  download?: boolean
): Promise<{
  installations?: InstallationCore[];
  error?: ApiError;
  total: number;
}> => {
  const response = await getRequest(
    URLS.installations.all,
    {
      queryParams: {
        page,
        q,
        status,
        orderBy,
        order: order,
        startDate: startDate && endDate ? startDate : undefined,
        endDate,
        origin,
        ivr,
        gdlStatus,
        emissionType,
        download,
      },
    },
    ApiEndpoint.emissions
  );
  return {
    installations: response?.data?.items,
    total: response?.data?.total ?? 0,
    error: response?.error,
  };
};

export const getInstallationsStatusList = async (): Promise<{
  installationsStatus?: InstallationStatus[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.installations.statusList, {}, ApiEndpoint.emissions);
  return {
    installationsStatus: response?.data,
    error: response?.error,
  };
};

export const getCampaignList = async (): Promise<{
  campaigns?: Campaign[];
  error?: ApiError;
}> => {
  const response = await getRequest(URLS.installations.campaignsList, {}, ApiEndpoint.emissions);
  return {
    campaigns: response?.data,
    error: response?.error,
  };
};

export const getInstallation = async (
  installationId: string
): Promise<{ installation: Installation; error?: ApiError }> => {
  const response = await getRequest(URLS.installations.detail(installationId), {}, ApiEndpoint.emissions);
  return {
    installation: response?.data,
    error: response?.error,
  };
};

export const updateInstallationStatus = async (
  installationId: string,
  updatedStatus: UpdatedInstallationStatusData
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(
    URLS.installations.updateStatus(installationId),
    {
      body: {
        ...updatedStatus,
        reason: updatedStatus.reason || null,
      },
    },
    ApiEndpoint.emissions
  );
  return {
    error: response?.error,
  };
};

export const updateInstallation = async (
  installationId: string,
  installation: InstallationAddressRequest
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(
    URLS.installations.update(installationId),
    {
      body: installation,
    },
    ApiEndpoint.emissions
  );
  return {
    error: response?.error,
  };
};

export const createInstallation = async (
  installation: InstallationRequest
): Promise<{ error?: ApiError; id?: string }> => {
  const response = await postRequest(
    URLS.installations.create,
    {
      body: { ...installation, georeference: installation.georeference ?? '' },
    },
    ApiEndpoint.emissions
  );
  return {
    id: response?.data?._id,
    error: response?.error,
  };
};

export const setValidInvalidPhone = async (
  installationId: string,
  phone: string,
  valid: boolean
): Promise<{ error?: ApiError }> => {
  const response = await postRequest(
    URLS.installations.phoneStatus(installationId),
    {
      body: {
        phone,
        status: valid ? 'valid' : 'invalid',
      },
    },
    ApiEndpoint.emissions
  );
  return {
    error: response?.error,
  };
};

export const getTagList = async (): Promise<{ tags: string[]; error?: ApiError }> => {
  const response = await getRequest(URLS.installations.phoneTags, {}, ApiEndpoint.emissions);
  return {
    tags: response.data,
    error: response?.error,
  };
};

export const setTagPhone = async (phone: string, tag: string, action: string): Promise<{ error?: ApiError }> => {
  const response = await postRequest(
    URLS.installations.phoneTags,
    {
      body: {
        phone,
        tag,
        action: action,
      },
    },
    ApiEndpoint.emissions
  );
  return {
    error: response?.error,
  };
};
