import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  LabeledSelect,
  LabeledTextArea,
  StyledButton,
  useLoading,
} from '@gorila-shared-ui/components';
import { useEffect, useMemo, useState } from 'react';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import {
  WEBHOOK_METHOD_GET,
  WEBHOOK_METHOD_POST,
  WEBHOOK_METHODS,
  WEBHOOK_TYPE_ALARM,
  WEBHOOK_TYPE_POSITION,
  WEBHOOK_TYPES,
} from '../../constants/webhooks';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import { postWebhook } from '../../services/webhookService';
import { Webhook } from '../../types/webhook';

type Props = {
  webhook?: Webhook;
  onCancel: () => void;
  afterSave: () => void;
  clientId: string;
};
export function WebhookForm({ webhook, onCancel, afterSave, clientId }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingWebhook, setEditingWebhook] = useState<Webhook>();
  const [jsonStringValue, setJsonStringValue] = useState<string>('{}');
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  const isNewWebhook = !webhook;

  const isValidJson = useMemo(() => {
    try {
      JSON.parse(jsonStringValue);
      return true;
    } catch (error) {
      return false;
    }
  }, [jsonStringValue]);

  useEffect(() => {
    if (webhook) {
      setEditingWebhook(webhook);
      try {
        setJsonStringValue(JSON.stringify(webhook.headers));
      } catch (error) {
        setJsonStringValue('{}');
      }
    }
    setEditingWebhook(undefined);
  }, [webhook]);

  useEffect(() => {
    if (isValidJson) {
      setEditingWebhook((prev) => ({ ...prev!, headers: JSON.parse(jsonStringValue) }));
    }
    setEditingWebhook((prev) => ({ ...prev!, headers: {} }));
  }, [jsonStringValue]);

  const canSubmit = useMemo(() => {
    if (!isValidJson) return false;
    if (!editingWebhook?.endpoint || !editingWebhook.method || !editingWebhook.webhookType) return false;
    return true;
  }, [editingWebhook, isNewWebhook]);

  const onInputChange = (value: any, field: keyof Webhook) => {
    setEditingWebhook((prev) => ({ ...prev!, [field]: value }));
  };

  const onSave = async () => {
    if (!editingWebhook) return;
    startLoading();
    const { id, error } = await postWebhook(clientId, editingWebhook);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.webhook));
    } else {
      showFailFeedback(error || FEEDBACK.failedCreation(FEEDBACK_PREFIXES.webhook));
    }
    stopLoading();
  };

  const methodOptions = useMemo(() => {
    return [
      {
        id: WEBHOOK_METHOD_GET,
        label: WEBHOOK_METHODS[WEBHOOK_METHOD_GET],
      },
      {
        id: WEBHOOK_METHOD_POST,
        label: WEBHOOK_METHODS[WEBHOOK_METHOD_POST],
      },
    ];
  }, []);

  const webhookTypeOptions = useMemo(() => {
    return [
      {
        id: WEBHOOK_TYPE_POSITION,
        label: WEBHOOK_TYPES[WEBHOOK_TYPE_POSITION],
      },
      {
        id: WEBHOOK_TYPE_ALARM,
        label: WEBHOOK_TYPES[WEBHOOK_TYPE_ALARM],
      },
    ];
  }, []);

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        <LabeledInput
          label="Url"
          value={editingWebhook?.endpoint}
          onChange={(value) => onInputChange(value, 'endpoint')}
          required
        />
        <LabeledSelect
          label="Metodo"
          value={[{ id: editingWebhook?.method }]}
          options={methodOptions}
          onChange={(params) => onInputChange(params.option?.id, 'method')}
          required
        />
        <LabeledTextArea
          label="Encabezados"
          value={jsonStringValue}
          onChangeValue={(value) => setJsonStringValue(value)}
          error={!isValidJson}
        />
        <LabeledSelect
          label="Tipo"
          value={[{ id: editingWebhook?.webhookType }]}
          options={webhookTypeOptions}
          onChange={(params) => onInputChange(params.option?.id, 'webhookType')}
          required
        />
        <LabeledInput
          label="Id externo"
          value={editingWebhook?.externalId}
          onChange={(value) => onInputChange(value, 'externalId')}
        />
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          <StyledButton
            kind="tertiary"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
