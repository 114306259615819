import { EllipsedText, MaterialIcon, StyledTooltip, useLoading, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { CLIENT_DETAIL_PAGE, FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { ACCOUNT_SECTION, CLIENT_TABLE_COLUMNS_NAME } from '../../constants/clients';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getClientsList, updateClientPaid } from '../../services/clientService';
import { ClientCore } from '../../types/client';
import { ListWrapper } from '../shared/ListWrapper';
import ConfirmModal from '../ui/ConfirmModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function ClientsList({ search, reloadPage }: Readonly<Props>) {
  const { theme } = useStyles();
  const [clients, setClients] = useState<ClientCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, showModal: showConfirmModal } = useModal();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [selectedClient, setSelectedClient] = useState<ClientCore>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadClients = async () => {
      const { clients, error, total } = await getClientsList(page, search);
      if (!error && clients) {
        setClients(clients);
        setTotalItems(total);
      } else {
        setClients(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadClients();
  }, [page]);

  const onSelectClient = (clientId: string) => {
    const route = generatePath(CLIENT_DETAIL_PAGE.route, { clientId, tabName: ACCOUNT_SECTION });
    navigate(route, { replace: false });
  };

  const onTogglePaid = (client: ClientCore) => {
    setSelectedClient({ ...client, paid: !client.paid });
    openConfirmModal();
  };

  const onConfirmTogglePaid = async () => {
    if (!selectedClient) return;
    const { id, error } = await updateClientPaid(selectedClient);
    if (!error && id) {
      showPositiveFeedback(FEEDBACK.edited(FEEDBACK_PREFIXES.client, selectedClient.name));
    } else {
      showFailFeedback(error || FEEDBACK.failedEdition(FEEDBACK_PREFIXES.client, selectedClient.name));
    }
    setSelectedClient(undefined);
    setPage(0);
    closeConfirmModal();
  };

  return (
    <>
      <ListWrapper
        columns={CLIENT_TABLE_COLUMNS_NAME}
        data={clients?.map((client, i) => {
          return {
            items: [
              <ParagraphXSmall
                key={`client-rfc-${i}`}
                margin={0}
              >
                <EllipsedText>{client.rfc || '-'}</EllipsedText>
              </ParagraphXSmall>,
              <ParagraphXSmall
                key={`client-name-${i}`}
                margin={0}
              >
                <EllipsedText>{client.name || '-'}</EllipsedText>
              </ParagraphXSmall>,
              <Checkbox
                key={`client-paid-${i}`}
                checked={client.paid}
                checkmarkType="toggle"
                onChange={() => onTogglePaid(client)}
              />,
              <MaterialIcon
                key={`client-safe-gpt-${i}`}
                name={client.safeGPT ? 'done' : 'close'}
                color={client.safeGPT ? 'green' : 'red'}
              />,
              <StyledTooltip
                key={`details-${client._id}`}
                content={'Ver detalle'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => onSelectClient(client._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={clients !== undefined}
        isEmpty={!clients?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun cliente"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={closeConfirmModal}
        onConfirm={onConfirmTogglePaid}
        description={
          <ParagraphMedium>
            ¿Seguro que quieres cambiar a{' '}
            <span style={{ color: selectedClient?.paid ? theme.colors.positive : theme.colors.negative }}>
              {selectedClient?.paid ? 'pagado' : 'no pagado'}
            </span>{' '}
            el cliente {selectedClient?.name}?
          </ParagraphMedium>
        }
      />
    </>
  );
}
