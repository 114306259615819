import { EnhancedLabeledSelect, useLoading } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getTemplateList } from '../../../services/whatsappService';
import { Template } from '../../../types/whatsapp';

type Props = {
  template?: Template;
  selectedTemplateId?: string;
  onChangeTemplateId?: (templateId?: string) => void;
  onChangeTemplate?: (template?: Template) => void;
  isFilter?: boolean;
  label?: string;
  placeholder?: string;
  error?: React.ReactNode;
  onBlur?: () => void;
  inline?: boolean;
  clearable?: boolean;
  required?: boolean;
};
export function TemplateSelect({
  selectedTemplateId,
  onChangeTemplateId,
  onChangeTemplate,
  template,
  isFilter = true,
  label = 'Plantilla',
  placeholder = 'Seleciona una plantilla',
  error,
  onBlur,
  inline,
  clearable = false,
  required = false,
}: Readonly<Props>) {
  const [templatesList, setTemplatesList] = useState<Template[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<{ id: string; label: string }>();
  const requestIdRef = useRef(0);

  useEffect(() => {
    const loadTemplates = async () => {
      startLoading();
      const requestId = ++requestIdRef.current;
      const { templates, error } = await getTemplateList();
      if (requestId === requestIdRef.current) {
        if (!error && templates) {
          setTemplatesList(templates);
        } else {
          setTemplatesList(undefined);
        }
        stopLoading();
      }
    };
    loadTemplates();
  }, []);

  const templateOptions = useMemo(() => {
    if (!templatesList) return;
    const templatesOptions: {
      id: string | undefined;
      label: string;
    }[] = templatesList.map((template) => ({ id: template.templateName, label: template.name }));
    if (!required) {
      templatesOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todas' : 'Ninguna',
      });
    }
    if (template) {
      if (template.templateName && !templatesOptions.find((c) => c.id === template.templateName)) {
        templatesOptions.push({
          id: template.templateName,
          label: template.name,
        });
      } else if (template.name && !template.templateName) {
        const templateFind = templatesOptions.find(
          (c) => c.label.toLowerCase().trim() === template.name.toLowerCase().trim()
        );
        if (templateFind) {
          onChangeTemplateId && onChangeTemplateId(templateFind.id);
          onChangeTemplate && onChangeTemplate({ templateName: templateFind.id as string, name: templateFind.label });
          setSelectedTemplateOption(templateFind as { id: string; label: string });
        } else {
          onChangeTemplateId && onChangeTemplateId(undefined);
          onChangeTemplate && onChangeTemplate(undefined);
        }
      }
    }
    if (selectedTemplateOption) {
      if (!templatesOptions.find((c) => c.id === selectedTemplateOption.id))
        templatesOptions.push(selectedTemplateOption);
    }
    return templatesOptions;
  }, [templatesList]);

  const onTemplateChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onChangeTemplateId && onChangeTemplateId(params.option.id as string | undefined);
      onChangeTemplate && onChangeTemplate({ templateName: params.option.id, name: params.option.label } as Template);
    } else {
      onChangeTemplateId && onChangeTemplateId(undefined);
      onChangeTemplate && onChangeTemplate(undefined);
    }
    setSelectedTemplateOption(params.option as { id: string; label: string });
  };

  return (
    <EnhancedLabeledSelect
      label={label}
      options={templateOptions}
      value={[{ id: selectedTemplateId }]}
      onChange={onTemplateChange}
      fullWidth
      onBlur={() => {
        onBlur && onBlur();
      }}
      fetchDataLoading={loading}
      searchable
      inline={inline}
      inset={inline}
      error={error}
      required={required}
      clearable={clearable}
      placeholder={placeholder}
    />
  );
}
