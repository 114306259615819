import {
  EllipsedText,
  FlexColumn,
  FlexRow,
  IMAGE_EXTENSIONS,
  isValidImage,
  MaterialIcon,
  StyledButton,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { FileUploader } from 'baseui/file-uploader';
import { ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { useState } from 'react';
import { FILE_EXTENSIONS } from '../../constants/app';
import { useStyles } from '../../hooks/useStyles';

type Props = {
  fileData?: {
    name: string;
    url: string;
  };
  file?: File;
  onBlur?: () => void;
  error?: React.ReactNode;
  extensions?: string[];
  onFileChange: (file?: File) => void;
};

export function DocumentUploader({ fileData, file, onFileChange, extensions, error, onBlur }: Readonly<Props>) {
  const { css, theme, concatenateClasses, classes } = useStyles();
  const [documentErrors, setDocumentErrors] = useState('');
  const allExtensions = [...FILE_EXTENSIONS, ...IMAGE_EXTENSIONS];
  const validExtensions = extensions ?? allExtensions;

  if (fileData || file) {
    return (
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={concatenateClasses(classes.customField, css({ boxSizing: 'border-box', alignItems: 'center' }))}
      >
        <div className={css({ lineHeight: 0 })}>
          <MaterialIcon
            name={isValidImage(fileData?.name ?? file?.name ?? '') ? 'image' : 'attachment'}
            size="mini"
            color={theme.colors.contentSecondary}
          />
        </div>
        <EllipsedText
          breakAll
          inline
          style={{
            fontSize: theme.sizing.scale550,
          }}
        >
          {fileData?.name ?? file?.name ?? ''}
        </EllipsedText>
        <Button
          size="mini"
          shape="circle"
          kind="tertiary"
          onClick={() => {
            onFileChange(undefined);
          }}
          overrides={{
            BaseButton: {
              style: {
                marginLeft: 'auto',
                flexShrink: 0,
              },
            },
          }}
        >
          <MaterialIcon
            name="delete"
            size={theme.sizing.scale650}
          />
        </Button>
      </FlexRow>
    );
  }

  return (
    <FlexColumn gap={0}>
      <FileUploader
        accept={validExtensions}
        onDrop={(acceptedFiles, rejectedFiles) => {
          if (rejectedFiles.length === 1) {
            setDocumentErrors(`Sube un archivo con un formato válido: ${validExtensions.join(', ')}.`);
          } else if (rejectedFiles.length > 1) {
            setDocumentErrors(`Solo puedes subir un archivo a la vez.`);
          } else {
            setDocumentErrors('');
          }
          if (acceptedFiles.length === 1) {
            onFileChange(acceptedFiles[0]);
          }
          onBlur && onBlur();
        }}
        errorMessage={documentErrors}
        onRetry={() => {
          setDocumentErrors('');
        }}
        multiple={false}
        overrides={{
          ErrorMessage: {
            style: {
              maxWidth: '300px',
              textAlign: 'center',
              fontSize: theme.sizing.scale550,
            },
          },
          FileDragAndDrop: {
            style: {
              paddingTop: theme.sizing.scale600,
              paddingBottom: theme.sizing.scale600,
              borderStyle: error || documentErrors ? 'dashed' : undefined,
              borderColor: error || documentErrors ? theme.colors.negative200 : undefined,
              backgroundColor: error || documentErrors ? theme.colors.negative50 : undefined,
            },
          },
          ContentMessage: {
            component: () => (
              <ParagraphSmall
                marginTop={0}
                marginBottom={theme.sizing.scale300}
                color={theme.colors.contentSecondary}
              >
                Arrastra un archivo aquí o...
              </ParagraphSmall>
            ),
          },
          RetryButtonComponent: {
            component: ({ onClick }) => (
              <StyledButton
                shape="pill"
                size="mini"
                onClick={onClick}
                overrides={{
                  BaseButton: {
                    style: { marginTop: theme.sizing.scale300 },
                  },
                }}
              >
                Aceptar
              </StyledButton>
            ),
          },
          ButtonComponent: {
            component: ({ ...props }) => (
              <StyledButton
                {...props}
                kind="primary"
                shape="pill"
                size="mini"
              >
                Buscar archivo
              </StyledButton>
            ),
          },
        }}
      />
      {error && !documentErrors && (
        <ParagraphXSmall
          margin={0}
          className={css({ color: `${theme.colors.negative} !important` })}
        >
          {error}
        </ParagraphXSmall>
      )}
    </FlexColumn>
  );
}
