import { FlexColumn, FlexRow, LabeledInput, StyledButton, useLoading } from '@gorila-shared-ui/components';
import { Block } from 'baseui/block';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import { EMPTY_SUB_ACCOUNT } from '../../../constants/subAccounts';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { createSubAccount, updateSubAccount } from '../../../services/subAccountService';
import { ClientCore } from '../../../types/client';
import { MinimalSubAccount, SubAccount } from '../../../types/subAccount';
import { SubClientCore } from '../../../types/subClient';
import { isArrayOfAssets } from '../../../utils/assets';
import { AssetListPicker } from '../../shared/assetListPicker/AssetListPicker';

type Props = {
  clientId?: string;
  subClientId?: string;
  subAccount?: SubAccount;
  onCancel: () => void;
  afterSave: () => void;
};
export function SubAccountForm({ subAccount, clientId, subClientId, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingSubAccount, setEditingSubAccount] = useState<MinimalSubAccount>(EMPTY_SUB_ACCOUNT);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (subAccount) {
      setEditingSubAccount(subAccount);
    } else if (clientId && subClientId) {
      setEditingSubAccount((prev) => ({
        ...prev,
        client: { _id: clientId } as ClientCore,
        subClient: { _id: subClientId } as SubClientCore,
      }));
    }
  }, [subAccount, clientId, subClientId]);

  const hasUpdates = !areEqual(subAccount ?? {}, editingSubAccount);

  const canSubmit = useMemo(() => {
    if (editingSubAccount.name) return true;
    return false;
  }, [editingSubAccount]);

  const onInputChange = (value: any, field: keyof MinimalSubAccount) => {
    setEditingSubAccount((prev) => ({ ...prev, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = subAccount
      ? await updateSubAccount(editingSubAccount)
      : await createSubAccount(editingSubAccount);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        subAccount
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.subAccount, editingSubAccount.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.subAccount)
      );
    } else {
      showFailFeedback(
        error || subAccount
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.subAccount, editingSubAccount.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.subAccount)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
        })}
      >
        <LabeledInput
          label="Nombre:"
          value={editingSubAccount.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        <Block
          minHeight={'300px'}
          display={'flex'}
          flexDirection={'column'}
        >
          <AssetListPicker
            subClientId={subClientId}
            assignedAssetIds={
              isArrayOfAssets(editingSubAccount.assets)
                ? editingSubAccount.assets.map((asset) => asset._id)
                : editingSubAccount.assets ?? []
            }
            onAssignedChange={(assetIds) => onInputChange(assetIds, 'assets')}
            required
          />
        </Block>
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {subAccount?._id && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
