import { ConfirmModalContent, ConfirmModalProps } from '@gorila-shared-ui/components';
import { Modal } from 'baseui/modal';
import { createPortal } from 'react-dom';

const ConfirmModal = ({
  isOpen,
  description,
  title = 'Confirmación',
  confirmText = 'Aceptar',
  cancelText = 'Cancelar',
  onConfirm,
  onCancel,
  canConfirm = true,
}: ConfirmModalProps) => {
  return createPortal(
    <Modal
      closeable={false}
      isOpen={isOpen}
    >
      <ConfirmModalContent
        description={description}
        onConfirm={onConfirm}
        canConfirm={canConfirm}
        cancelText={cancelText}
        confirmText={confirmText}
        onCancel={onCancel}
        title={title}
      />
    </Modal>,
    document.body
  );
};

export default ConfirmModal;
