import { FlexColumn, LabeledInput, StyledButton } from '@gorila-shared-ui/components';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useForms } from '../../hooks/useForms';
import { useStyles } from '../../hooks/useStyles';
import {
  editFleetHealthAlarmErrorState,
  editFleetHealthAlarmState,
  editFleetHealthAlarmTouchedState,
  editFleetHealthAlarmValidState,
} from '../../storage/fleetHealthStates';
import { FleetHealthAlarmRequest, FleetHealthAlarmRequestTouched, FleetHealthAlarmType } from '../../types/fleetHealth';
import { FleetHealthAlarmTypeSelect } from '../shared/selects/FleetHealthAlarmTypeSelect';

type Props = {
  onSubmit: (form: FleetHealthAlarmRequest, alarmType?: FleetHealthAlarmType) => void;
};
export function FleetHealthAlarmForm({ onSubmit }: Readonly<Props>) {
  const { css } = useStyles();
  const [selectedAlarmType, setSelectedAlarmType] = useState<FleetHealthAlarmType>();
  const [form, setForm] = useRecoilState(editFleetHealthAlarmState);
  const formValid = useRecoilValue(editFleetHealthAlarmValidState);
  const formHasError = useRecoilValue(editFleetHealthAlarmErrorState);
  const [formTouched, setFormTouched] = useRecoilState(editFleetHealthAlarmTouchedState);
  const { onTouched, onChangeInput } = useForms<FleetHealthAlarmRequest, FleetHealthAlarmRequestTouched>(
    setForm,
    setFormTouched
  );
  const resetEditFleetHealthAlarmState = useResetRecoilState(editFleetHealthAlarmState);
  const resetEditFleetHealthAlarmTouchedState = useResetRecoilState(editFleetHealthAlarmTouchedState);
  const canSubmit = !formHasError;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    resetEditFleetHealthAlarmState();
    resetEditFleetHealthAlarmTouchedState();
  };

  return (
    <FlexColumn classNames={css({ overflow: 'hidden' })}>
      <FleetHealthAlarmTypeSelect
        selectedAlarmTypeId={form.code}
        onChangeAlarmTypeId={(id, alarmType) => {
          onChangeInput('code', id);
          setSelectedAlarmType(alarmType);
        }}
        required
      />
      <LabeledInput
        label="No. máximo de eventos"
        placeholder="Ingrese el no. máximo de eventos"
        value={form?.maxEvents ?? ''}
        onChange={(value) => {
          onChangeInput('maxEvents', value);
        }}
        type="number"
        min={0}
        onBlur={() => onTouched('maxEventsTouched')}
        error={formTouched.maxEventsTouched && !formValid.maxEvents && 'El valor debe ser mayor a 0.'}
        required
      />
      <LabeledInput
        label="Rango de tiempo"
        placeholder="Ingrese el rango de tiempo"
        value={form?.timeRange}
        onChange={(value) => {
          onChangeInput('timeRange', value);
        }}
        type="number"
        endEnhancer="min"
        min={0}
        caption="Período de evaluación en minutos. El valor predeterminado es 1440 (24 horas)"
        onBlur={() => onTouched('timeRangeTouched')}
        error={formTouched.timeRangeTouched && !formValid.timeRange && 'El valor debe ser mayor a 0.'}
      />
      <StyledButton
        onClick={() => {
          onSubmit(form, selectedAlarmType);
          clearStates();
        }}
        disabled={!canSubmit}
      >
        Agregar
      </StyledButton>
    </FlexColumn>
  );
}
