import { ApiError } from '@gorila-shared-ui/components';
import { Brand } from '../types/brand';
import { SubBrand } from '../types/subBrand';
import { Version } from '../types/version';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

//vehicle brands
export const getBrandsList = async (
  page?: number,
  q?: string
): Promise<{ brands?: Brand[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
  };
  const response = await getRequest(URLS.vehicles.brandList, {
    queryParams: params,
  });
  return {
    brands: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const postBrand = async (brand: Brand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: brand.name,
  };
  const response = await postRequest(URLS.vehicles.createBrand, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateBrand = async (brand: Brand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: brand.name,
  };
  const response = await postRequest(URLS.vehicles.updateBrand(brand._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

//vehicle sub-brands
export const getSubBrandsList = async (
  page?: number,
  q?: string,
  brandId?: string
): Promise<{ subBrands?: SubBrand[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    brandId,
  };
  const response = await getRequest(URLS.vehicles.subBrandList, {
    queryParams: params,
  });
  return {
    subBrands: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const postSubBrand = async (subBrand: SubBrand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: subBrand.name,
    brandId: subBrand.brand._id,
  };
  const response = await postRequest(URLS.vehicles.createSubBrand, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateSubBrand = async (subBrand: SubBrand): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: subBrand.name,
    brandId: subBrand.brand._id,
  };
  const response = await postRequest(URLS.vehicles.updateSubBrand(subBrand._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

// vehicle versions
export const getVersionsList = async (
  page?: number,
  q?: string,
  brandId?: string,
  subBrandId?: string
): Promise<{ versions?: Version[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    brandId,
    subBrandId,
  };
  const response = await getRequest(URLS.vehicles.versionList, {
    queryParams: params,
  });
  return {
    versions: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const postVersion = async (version: Version): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: version.name,
    brandId: version.brand._id,
    subBrandId: version.subBrand._id,
  };
  const response = await postRequest(URLS.vehicles.createVersion, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateVersion = async (version: Version): Promise<{ id?: string; error?: ApiError }> => {
  const body = {
    name: version.name,
    brandId: version.brand._id,
    subBrandId: version.subBrand._id,
  };
  const response = await postRequest(URLS.vehicles.updateVersion(version._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
