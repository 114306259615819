import { PaginatedColumn } from '../types/app';
import {
  CooldownTime,
  FleetHealtEventType,
  FleetHealthAlarmRequest,
  FleetHealthAlarmRequestTouched,
  FleetHealthEventLogRequest,
  FleetHealthEventStatusRequest,
  FleetHealthRuleRequest,
  FleetHealthRuleRequestTouched,
} from '../types/fleetHealth';

export const FLEET_HEALTH_RULE_COLUMNS_NAME: PaginatedColumn[] = [
  {
    label: 'Nombre',
    key: 'name',
  },
  {
    label: 'Activo',
    key: 'active',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
  {
    label: 'Tiempo max. sin comunicar',
    key: 'maxNoCommunication',
    centered: true,
  },
  {
    label: 'Batería mínima',
    key: 'minBattery',
    minWidth: 150,
    maxWidth: 150,
    centered: true,
  },
  {
    label: 'Modelos',
    key: 'models',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
  {
    label: 'Alarmas',
    key: 'alarms',
  },
  {
    label: 'Acciones',
    key: 'actions',
    minWidth: 120,
    maxWidth: 120,
    centered: true,
  },
];

export const FLEET_HEALTH_RULE_DEFAULTS: FleetHealthRuleRequest = {
  name: '',
  active: true,
  alarms: [],
  maxNoCommunication: null,
  minBattery: null,
  models: [],
};

export const FLEET_HEALTH_RULE_TOUCHED_DEFAULTS: FleetHealthRuleRequestTouched = {
  nameTouched: false,
  activeTouched: false,
  alarmsTouched: false,
  maxNoCommunicationTouched: false,
  minBatteryTouched: false,
  modelsTouched: false,
};

export const FLEET_HEALTH_ALARM_DEFAULTS: FleetHealthAlarmRequest = {
  code: undefined,
  maxEvents: 1,
  timeRange: 1440,
};

export const FLEET_HEALTH_ALARM_TOUCHED_DEFAULTS: FleetHealthAlarmRequestTouched = {
  codeTouched: false,
  maxEventsTouched: false,
  timeRangeTouched: false,
};

export const FLEET_HEALTH_EVENT_TYPE = {
  [FleetHealtEventType.maxCommunication]: 'Sin comunicación',
  [FleetHealtEventType.batteryLevel]: 'Batería',
  [FleetHealtEventType.alarms]: 'Alarmas',
};

export const FLEET_HEALTH_EVENT_LOG_TYPE_COMMENT = 'comment';
export const FLEET_HEALTH_EVENT_LOG_TYPE_STATUS = 'status';

export const FLEET_HEALTH_EVENT_LOG_TYPE_NAME = {
  [FLEET_HEALTH_EVENT_LOG_TYPE_COMMENT]: 'Comentario',
  [FLEET_HEALTH_EVENT_LOG_TYPE_STATUS]: 'Estado',
};

export const FLEET_HEALTH_EVENT_LOG_DEFAULTS: FleetHealthEventLogRequest = {
  message: '',
  logType: FLEET_HEALTH_EVENT_LOG_TYPE_COMMENT,
};

export const FLEET_HEALTH_EVENT_STATUS_DEFAULTS: FleetHealthEventStatusRequest = {
  status: '',
  cooldownMinutes: undefined,
  emissionId: undefined,
};

export const COOLDOWN_TIMES = {
  [CooldownTime['12hours']]: '12 Hrs',
  [CooldownTime['48hours']]: '48 Hrs',
  [CooldownTime['7days']]: '7 Dias',
  [CooldownTime['15days']]: '15 Dias',
  [CooldownTime['30days']]: '30 Dias',
  [CooldownTime['blacklist']]: 'A Lista Negra',
};
