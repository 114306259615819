import { FlexColumn, FlexRow, StyledBanner, StyledButton, useLoading } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { HeadingXSmall, ParagraphXSmall } from 'baseui/typography';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { getReportingDevices } from '../../../services/reportsService';
import { StyledDateTimeRangePicker } from '../../ui/StyledDateTimeRangePicker';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

function CommsReportModal({ onClose, isOpen }: Props) {
  const { loading, startLoading, stopLoading } = useLoading();
  const [error, setError] = useState('');
  const { theme, classes } = useStyles();
  const [startDate, setStartDate] = useState<number | null>();
  const [endDate, setEndDate] = useState<number | null>();
  const [datesError, setDatesError] = useState('');
  const { showPositiveFeedback, showFailFeedback } = useFeedback();
  const [maxEndDate, setMaxEndDate] = useState<number>();

  const validCommsDateRange = !!startDate && !!endDate;

  useEffect(() => {
    if (!isOpen) return;
    const now = new Date().getTime();
    setMaxEndDate(now);
  }, [isOpen]);

  const downloadReport = async () => {
    if (!validCommsDateRange) return;
    startLoading();
    const response = await getReportingDevices(startDate.toString(), endDate.toString(), 1, true);
    if (response?.error) {
      showFailFeedback(response.error);
    } else {
      resetModal();
      showPositiveFeedback('Se esta generando el archivo...');
    }
    stopLoading();
  };

  const resetModal = () => {
    onClose();
    setStartDate(undefined);
    setEndDate(undefined);
    setError('');
    setDatesError('');
  };

  return createPortal(
    <Modal
      animate
      closeable
      onClose={resetModal}
      isOpen={isOpen}
      size={600}
      autoFocus={false}
    >
      <ModalHeader>
        <FlexRow
          gap={theme.sizing.scale200}
          classNames={classes.centeredStart}
        >
          <HeadingXSmall margin={0}>Reporte Comunicaciones</HeadingXSmall>
        </FlexRow>
      </ModalHeader>
      <ModalBody>
        {error && (
          <StyledBanner
            title="*Error*"
            kind="negative"
            action={{
              label: 'Label',
              onClick: () => {
                setError('');
              },
            }}
          >
            {error}
          </StyledBanner>
        )}
        <FlexColumn>
          <StyledDateTimeRangePicker
            todayIsMaxDate={false}
            dates={[startDate ? new Date(startDate) : undefined, endDate ? new Date(endDate) : undefined]}
            onDatesChange={(dates) => {
              if (dates instanceof Array) {
                if (dates.length > 1 && dates[0] && dates[1]) {
                  setStartDate(dates[0].getTime());
                  setEndDate(dates[1].getTime());
                } else if (dates[0]) {
                  setStartDate(dates[0].getTime());
                  setEndDate(null);
                }
              }
            }}
            onError={setDatesError}
            startLabel="Fecha y hora de inicio:"
            endLabel="Fecha y hora fin:"
            maxEndDate={maxEndDate}
          />
          {datesError && (
            <ParagraphXSmall
              margin={0}
              color={theme.colors.negative}
            >
              {datesError}
            </ParagraphXSmall>
          )}
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        <FlexRow
          gap={theme.sizing.scale300}
          classNames={classes.centeredEnd}
        >
          <StyledButton
            kind="tertiary"
            onClick={resetModal}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={downloadReport}
            disabled={!validCommsDateRange}
            isLoading={loading}
          >
            Solicitar
          </StyledButton>
        </FlexRow>
      </ModalFooter>
    </Modal>,
    document.body
  );
}

export default memo(CommsReportModal);
