import { FullContainer, Loading, useLoading } from '@gorila-shared-ui/components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { INSTALLATIONS_PAGE } from '../../../constants/app';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { getInstallation } from '../../../services/installationService';
import { Installation } from '../../../types/installation';
import { InstallationDetailData } from '../../installations/InstallationDetailData';
import { InstallationDetailHeader } from '../../installations/InstallationDetailHeader';

export function InstallationPage() {
  const { css, theme } = useStyles();
  const { installationId } = useParams();
  const [installation, setInstallation] = useState<Installation>();
  const { loading, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const { showFailFeedback } = useFeedback();

  const getInstallationData = async () => {
    if (!installationId) return;
    startLoading();
    const { installation, error } = await getInstallation(installationId);
    if (error) {
      showFailFeedback(error);
      setInstallation(undefined);
    } else {
      setInstallation(installation);
    }
    stopLoading();
  };

  useEffect(() => {
    if (!installationId) {
      return navigate(INSTALLATIONS_PAGE.route, { replace: false });
    }
    getInstallationData();
  }, [installationId]);

  return (
    <FullContainer
      verticalFlex
      classNames={`${css({
        padding: theme.sizing.scale400,
        gap: theme.sizing.scale500,
        boxSizing: 'border-box',
        overflow: 'hidden',
      })}`}
    >
      {loading && <Loading />}
      {installation && !loading && (
        <>
          <InstallationDetailHeader
            installation={installation}
            refreshData={getInstallationData}
          />
          <InstallationDetailData
            installation={installation}
            refreshData={getInstallationData}
          />
        </>
      )}
    </FullContainer>
  );
}
