import { ApiError } from '@gorila-shared-ui/components';
import { MinimalSubAccount, SubAccount } from '../types/subAccount';
import { isArrayOfAssets } from '../utils/assets';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getSubAccounts = async ({
  page,
  q,
  subClientId,
}: {
  page?: number;
  q?: string;
  subClientId?: string;
}): Promise<{ subAccounts?: SubAccount[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const params = {
    page: page ?? 1,
    q,
    subClientId,
  };
  const response = await getRequest(URLS.subAccounts.all, {
    queryParams: params,
  });
  return {
    subAccounts: response?.data?.items,
    total: response?.data?.total ?? 0,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const createSubAccount = async (subAccount: MinimalSubAccount): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...subAccount,
    clientId: subAccount.client?._id,
    subClientId: subAccount.subClient?._id,
    assets: isArrayOfAssets(subAccount.assets) ? subAccount.assets.map((asset) => asset._id) : subAccount.assets,
  };
  const response = await postRequest(URLS.subAccounts.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateSubAccount = async (subAccount: MinimalSubAccount): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...subAccount,
    client: undefined,
    subClient: undefined,
    clientId: subAccount.client?._id,
    subClientId: subAccount.subClient?._id,
    assets: isArrayOfAssets(subAccount.assets) ? subAccount.assets.map((asset) => asset._id) : subAccount.assets,
  };
  const response = await postRequest(URLS.subAccounts.update(subAccount._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const getSubAccount = async (subAccountId: string): Promise<{ subAccount?: SubAccount; error?: ApiError }> => {
  const response = await getRequest(URLS.subAccounts.detail(subAccountId));
  return {
    subAccount: response?.data,
    error: response?.error,
  };
};
