import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  MaterialIcon,
  StyledButton,
  StyledTooltip,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import { postDevicesBrand, updateDevicesBrand } from '../../services/deviceService';
import { DeviceBrand } from '../../types/deviceBrand';

type Props = {
  devicesBrand?: DeviceBrand;
  onCancel: () => void;
  afterSave: () => void;
  inline?: boolean;
};
export function DevicesBrandForm({ devicesBrand, onCancel, afterSave, inline = false }: Props) {
  const { css, theme } = useStyles();
  const [editingDevicesBrand, setEditingDevicesBrand] = useState<DeviceBrand>({
    _id: '',
    name: '',
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (devicesBrand) {
      setEditingDevicesBrand(devicesBrand);
    }
  }, [devicesBrand]);

  const hasUpdates = !areEqual(devicesBrand ?? {}, editingDevicesBrand);

  const canSubmit = useMemo(() => {
    return !!editingDevicesBrand.name;
  }, [editingDevicesBrand]);

  const onInputChange = (value: string | undefined | null, field: keyof DeviceBrand) => {
    setEditingDevicesBrand((prev) => ({ ...prev!, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = devicesBrand
      ? await updateDevicesBrand(editingDevicesBrand)
      : await postDevicesBrand(editingDevicesBrand);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        devicesBrand
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.deviceBrand, editingDevicesBrand.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.deviceBrand)
      );
    } else {
      showFailFeedback(
        error || devicesBrand
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.deviceBrand, editingDevicesBrand.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.deviceBrand)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: 'end',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          width: '100%',
          height: '100%',
        })}
      >
        <LabeledInput
          label={`${inline ? '(Nueva Marca) ' : ''}Nombre:`}
          value={editingDevicesBrand?.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
      </FlexColumn>
      {inline && (
        <FlexRow gap={0}>
          <StyledTooltip
            content={'Cancelar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onCancel}
              kind="tertiary"
            >
              <MaterialIcon name={'cancel'} />
            </Button>
          </StyledTooltip>
          <StyledTooltip
            content={'Guardar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
              kind="tertiary"
            >
              <MaterialIcon name={'done'} />
            </Button>
          </StyledTooltip>
        </FlexRow>
      )}
      {!inline && (
        <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
          <FlexRow gap={theme.sizing.scale300}>
            {devicesBrand?._id && (
              <StyledButton
                kind="tertiary"
                onClick={onCancel}
              >
                Cancelar
              </StyledButton>
            )}
            <StyledButton
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
            >
              Guardar
            </StyledButton>
          </FlexRow>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
