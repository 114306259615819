import { EllipsedText, MaterialIcon, StyledTooltip, useLoading, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { DEVICE_BRAND_TABLE_COLUMNS_NAME } from '../../constants/deviceBrand';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getDeviceBrandsList } from '../../services/deviceService';
import { DeviceBrand } from '../../types/deviceBrand';
import { ListWrapper } from '../shared/ListWrapper';
import DevicesBrandDetailModal from './modal/DevicesBrandDetailModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function DevicesBrandsList({ search, reloadPage }: Readonly<Props>) {
  const [devicesBrands, setDevicesBrands] = useState<DeviceBrand[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedDevicesBrand, setSelectedDevicesBrand] = useState<DeviceBrand>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadDevicesBrands = async () => {
      const { brands, error, total } = await getDeviceBrandsList(page, search);
      if (!error && brands) {
        setDevicesBrands(brands);
        setTotalItems(total);
      } else {
        setDevicesBrands(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadDevicesBrands();
  }, [page]);

  const openDetailModal = (devicesBrand: DeviceBrand) => {
    setSelectedDevicesBrand(devicesBrand);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedDevicesBrand(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={DEVICE_BRAND_TABLE_COLUMNS_NAME}
        data={devicesBrands?.map((devicesBrand, i) => {
          return {
            items: [
              <EllipsedText key={`device-brand-name-${i}`}>{devicesBrand.name}</EllipsedText>,
              <StyledTooltip
                key={`details-${devicesBrand._id}`}
                content={'Ver detalle'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openDetailModal(devicesBrand)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={devicesBrands !== undefined}
        isEmpty={!devicesBrands?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna marca"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <DevicesBrandDetailModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          selectedDevicesBrand={selectedDevicesBrand}
        />
      )}
    </>
  );
}
