import { ApiError } from '@gorila-shared-ui/components';
import { Webhook } from '../types/webhook';
import { deleteRequest, getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getWebhooks = async ({
  clientId,
  page,
  q,
}: {
  clientId: string;
  page?: number;
  q?: string;
}): Promise<{ webhooks?: Webhook[]; error?: ApiError; total?: number }> => {
  const params = {
    page,
    q,
  };
  const response = await getRequest(URLS.webHooks.all(clientId), {
    queryParams: params,
  });
  return {
    webhooks: response?.data?.items,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const postWebhook = async (clientId: string, webhook: Webhook): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    ...webhook,
  };
  const response = await postRequest(URLS.webHooks.create(clientId), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const removeWebhook = async (
  clientId: string,
  webhookId: string
): Promise<{ id?: string; error?: ApiError }> => {
  const response = await deleteRequest(URLS.webHooks.delete(clientId, webhookId), {});
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
