import { MaterialIcon, StyledTooltip, useLoading, useModal, useMoment } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { ParagraphSmall } from 'baseui/typography';
import { useEffect, useState } from 'react';
import { BULK_FILE_TYPES, BULK_STATUSES, BULK_TABLE_COLUMNS_NAME } from '../../constants/bulk';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getBulkFiles } from '../../services/bulkService';
import { Bulk } from '../../types/bulk';
import { ListWrapper } from '../shared/ListWrapper';
import BulkDetailModal from './modal/BulkDetailModal';

type Props = {
  reloadPage?: boolean;
};
export function BulkList({ reloadPage }: Readonly<Props>) {
  const { theme } = useStyles();
  const [bulkFiles, setBulkFiles] = useState<Bulk[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedBulkFileId, setSelectedBulkFileId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadBulkFilesTableData = async () => {
      const response = await getBulkFiles(page);
      if (response?.error) {
        setBulkFiles(undefined);
        setTotalItems(0);
      } else {
        setBulkFiles(response?.bulkFiles);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadBulkFilesTableData();
  }, [page]);

  const openBulkFileModal = (bulkFileId: string) => {
    setSelectedBulkFileId(bulkFileId);
    openModal();
  };

  const closeBulkFileModal = (updateTable?: boolean) => {
    setSelectedBulkFileId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={BULK_TABLE_COLUMNS_NAME}
        data={
          bulkFiles?.map((bulkFile, i) => {
            return {
              items: [
                BULK_STATUSES[bulkFile.status],
                BULK_FILE_TYPES[bulkFile.metadata.filetype],
                formatDateFromTs(+bulkFile.created),
                <ParagraphSmall
                  key={`errors-${i}`}
                  color={theme.colors.negative}
                  margin={0}
                >
                  {bulkFile.errors}
                </ParagraphSmall>,
                <StyledTooltip
                  key={`ws-action-${i}`}
                  content={'Ver detalles'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openBulkFileModal(bulkFile._id)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={bulkFiles !== undefined}
        isEmpty={!bulkFiles?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ningun log"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
        hasSearch={false}
      />
      {showModal && (
        <BulkDetailModal
          onClose={(updateTable) => closeBulkFileModal(updateTable)}
          isOpen={showModal}
          selectedBulkFileId={selectedBulkFileId}
        />
      )}
    </>
  );
}
