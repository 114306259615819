import { ApiError, PaginatedResponse, useLoading } from '@gorila-shared-ui/components';
import { useEffect } from 'react';
import { RecoilState, SetterOrUpdater, useRecoilState } from 'recoil';
import useEffectOnce from '../../hooks/useEffectOnce';
import useUpdateEffect from '../../hooks/useUpdateEffect';

export function PaginatedProvider<T>({
  searchState,
  pageState,
  getPaginated,
  setPaginated,
  onSearchChange,
  onUpdateFinish,
  setLoading,
  update,
}: {
  searchState: RecoilState<string | undefined>;
  pageState: RecoilState<number>;
  setLoading: SetterOrUpdater<boolean>;
  getPaginated: (
    page: number,
    search: string
  ) => Promise<{ paginatedResponse?: PaginatedResponse<T>; error?: ApiError }>;
  setPaginated: SetterOrUpdater<PaginatedResponse<T> | undefined>;
  onUpdateFinish: () => void;
  onSearchChange: () => void;
  update?: string;
}) {
  const [search, setSearch] = useRecoilState(searchState);
  const [page, setPage] = useRecoilState(pageState);
  const { loading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const load = async () => {
    if (loading) return;
    startLoading();
    const { paginatedResponse, error } = await getPaginated(page, search ?? '');
    if (!error && paginatedResponse) {
      if (page > 1) {
        setPaginated((prev) => ({
          hasNext: paginatedResponse.hasNext,
          total: paginatedResponse.total,
          items: [...prev!.items, ...paginatedResponse.items],
        }));
      } else {
        setPaginated(paginatedResponse);
      }
    } else {
      setPaginated(undefined);
    }
    stopLoading();
  };

  useEffectOnce(() => {
    load();
    return () => {
      setSearch('');
      setPage(1);
    };
  });

  useUpdateEffect(() => {
    setPage(0);
    onSearchChange();
  }, [search]);

  useUpdateEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    load();
  }, [page]);

  useUpdateEffect(() => {
    if (!update) return;
    load().finally(() => {
      onUpdateFinish();
    });
  }, [update]);

  return null;
}
