import { FlexColumn } from '@gorila-shared-ui/components';
import { memo } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import StyledModal from '../../ui/StyledModal';
import { WhatsappForm } from '../WhatsappForm';

type Props = {
  isOpen: boolean;
  onClose: (update?: boolean) => void;
};

function NewCampaignModal({ onClose, isOpen }: Props) {
  const { css } = useStyles();

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      title={'Nueva campaña de whatsapp'}
      size="auto"
      content={
        <FlexColumn
          classNames={css({
            width: '50vw',
            maxWidth: '1200px',
            minWidth: '350px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '80vh',
            overflow: 'hidden',
          })}
        >
          <WhatsappForm
            onCancel={() => onClose()}
            afterSave={() => onClose(true)}
          />
        </FlexColumn>
      }
    />
  );
}

export default memo(NewCampaignModal);
