import {
  EmptyState,
  FlexColumn,
  FlexRow,
  Loading,
  MaterialIcon,
  StyledTooltip,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { getUser } from '../../../services/userService';
import { User } from '../../../types/user';
import { UserForm } from '../UserForm';
import { UserInfo } from '../UserInfo';

type Props = {
  isOpen: boolean;
  selectedUserId?: string;
  onClose: (update?: boolean) => void;
  clientId?: string;
};

function UserDetailModal({ onClose, isOpen, selectedUserId, clientId }: Props) {
  const { css } = useStyles();
  const [user, setUser] = useState<User>();
  const { startLoading, stopLoading, loading } = useLoading(false);
  const [editingMode, setEditingMode] = useState(false);
  const [error, setError] = useState(false);

  const loadUserData = async (userId: string) => {
    startLoading();
    const response = await getUser(userId);
    if (response?.error) {
      setUser(undefined);
      setError(true);
    } else {
      setUser(response?.user);
      setError(false);
    }
    stopLoading();
  };

  useEffect(() => {
    if (isOpen && selectedUserId) setEditingMode(false);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedUserId) {
      setUser(undefined);
      setEditingMode(true);
      return;
    }
    loadUserData(selectedUserId);
  }, [selectedUserId]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && error && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <EmptyState
            title="*Error*"
            description="No se pudieron obtener los datos del usuario"
          />
        </FlexColumn>
      )}
      {!loading && !error && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              {user?.username ?? 'Nuevo usuario'}
            </ModalHeader>
            {!editingMode && (
              <StyledTooltip
                content={'Editar'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => setEditingMode(true)}
                  kind="tertiary"
                >
                  <MaterialIcon
                    name={'edit'}
                    size="xs-mini"
                  />
                </Button>
              </StyledTooltip>
            )}
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                maxWidth: '1200px',
                minWidth: '550px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
                overflow: 'auto',
              })}
            >
              {!editingMode && user && <UserInfo user={user} />}
              {editingMode && !loading && (
                <UserForm
                  user={user}
                  onCancel={() => setEditingMode(false)}
                  afterSave={() => onClose(true)}
                  clientId={clientId}
                />
              )}
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(UserDetailModal);
