import { FlexColumn, FlexRow, Loading, useLoading } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../hooks/useStyles';
import { getWebServiceLog } from '../../../services/logsService';
import { WebServiceLog } from '../../../types/webServiceLogs';
import { WebServiceLogInfo } from '../WebServiceLogInfo';

type Props = {
  isOpen: boolean;
  selectedWebServiceId?: string;
  onClose: (update?: boolean) => void;
};

function WebServiceLogDetailModal({ onClose, isOpen, selectedWebServiceId }: Props) {
  const { css, theme } = useStyles();
  const [webService, setWebService] = useState<WebServiceLog>();
  const { startLoading, stopLoading, loading } = useLoading(false);

  const loadWebServiceData = async (webServiceId: string) => {
    startLoading();
    const response = await getWebServiceLog(webServiceId);
    if (response?.error) {
      setWebService(undefined);
    } else {
      setWebService(response?.webService);
    }
    stopLoading();
  };

  useEffect(() => {
    if (!selectedWebServiceId) {
      setWebService(undefined);
      return;
    }
    loadWebServiceData(selectedWebServiceId);
  }, [selectedWebServiceId]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={() => onClose()}
      isOpen={isOpen}
      size="auto"
    >
      {loading && (
        <FlexColumn
          centered
          classNames={css({
            height: '400px',
            width: '400px',
          })}
        >
          <Loading />
        </FlexColumn>
      )}
      {!loading && webService && (
        <>
          <FlexRow
            gap={0}
            classNames={css({
              justifyContent: 'space-between',
              margin: '6px 2rem 0px 0px',
            })}
          >
            <ModalHeader
              className={css({
                marginBottom: '0 !important',
              })}
            >
              {webService._id}
            </ModalHeader>
          </FlexRow>
          <ModalBody>
            <FlexColumn
              classNames={css({
                width: '70vw',
                maxWidth: '1200px',
                minWidth: '350px',
                boxSizing: 'border-box',
                padding: '1rem',
                borderTop: '1px solid rgb(226, 226, 226)',
                maxHeight: '80vh',
                overflowY: 'auto',
                gap: theme.sizing.scale900,
              })}
            >
              <WebServiceLogInfo webService={webService} />
            </FlexColumn>
          </ModalBody>
        </>
      )}
    </Modal>,
    document.body
  );
}

export default memo(WebServiceLogDetailModal);
