import {
  FlexColumn,
  FlexRow,
  isValidLatitude,
  isValidLongitude,
  LabeledData,
  MaterialIcon,
  useMoment,
} from '@gorila-shared-ui/components';
import { StatefulPopover } from 'baseui/popover';
import { LabelMedium } from 'baseui/typography';
import { memo, useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { useCamera } from '../../../hooks/useCamera';
import { useStyles } from '../../../hooks/useStyles';
import { FleetHealthLastEvent } from '../../../types/fleetHealth';

type Props = {
  event: FleetHealthLastEvent;
};

function FleetHealthEventMarker({ event }: Readonly<Props>) {
  const { theme, css } = useStyles();
  const { zoomTo } = useCamera();
  const { formatDateFromTs } = useMoment();
  const iconWrapper = css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    background: theme.colors.negative,
    paddingBottom: '3px',
    boxSizing: 'border-box',
  });
  const icon = useMemo(
    () => (
      <span className={iconWrapper}>
        <MaterialIcon
          name="warning"
          size="16px"
          color="#FFF"
        />
      </span>
    ),
    [iconWrapper]
  );

  if (!isValidLatitude(event.latitude) || !isValidLongitude(event.longitude)) return null;

  const onClick = () => {
    zoomTo({
      lat: event.latitude,
      lng: event.longitude,
    });
  };

  return (
    <Marker
      longitude={event.longitude}
      latitude={event.latitude}
      onClick={onClick}
    >
      <StatefulPopover
        showArrow
        autoFocus={false}
        returnFocus
        placement="top"
        triggerType="hover"
        popoverMargin={0}
        content={({ close }) => (
          <FlexColumn
            gap={theme.sizing.scale300}
            classNames={css({
              paddingRight: theme.sizing.scale600,
              paddingLeft: theme.sizing.scale600,
              paddingTop: theme.sizing.scale500,
              paddingBottom: theme.sizing.scale500,
            })}
          >
            <FlexRow gap={theme.sizing.scale200}>
              <LabelMedium>{event.geoReference}</LabelMedium>
            </FlexRow>
            <FlexColumn gap={theme.sizing.scale0}>
              <LabeledData
                label={'Fecha:'}
                content={event.timestamp ? formatDateFromTs(event.timestamp) : '-'}
                labelWidth="70px"
              />
              <LabeledData
                label={'Latitud:'}
                content={event.latitude.toString()}
                labelWidth="70px"
              />
              <LabeledData
                label={'Longitud:'}
                content={event.longitude.toString()}
                labelWidth="70px"
              />
            </FlexColumn>
          </FlexColumn>
        )}
        overrides={{
          Inner: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
            },
          },
          Arrow: {
            style: {
              backgroundColor: theme.colors.backgroundPrimary,
            },
          },
        }}
      >
        {icon}
      </StatefulPopover>
    </Marker>
  );
}

export default memo(FleetHealthEventMarker);
