import { EmptyState, FlexColumn, Loading, useLoading, useMoment } from '@gorila-shared-ui/components';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { COMMANDS_STATUS, TABLE_COLUMNS_NAME } from '../../constants/commands';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { availableCommandLabelsState } from '../../storage/commandFiltersState';
import { HistoricCommand } from '../../types/command';
import { StyledPaginatedTable } from '../shared/StyledPaginatedTable';
import { useCommands } from './hooks/useCommands';

export function CommandList() {
  const { css, classes } = useStyles();
  const [commands, setCommands] = useState<HistoricCommand[]>();
  const commandLabels = useRecoilValue(availableCommandLabelsState);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { formatDateFromTs } = useMoment();
  const { loading, stopLoading, startLoading } = useLoading(true);
  const {
    reload,
    search,
    selectedCommandType,
    selectedStatus,
    selectedCommandsDateRange,
    loadHistoricCommands,
    getCommandStatusColor,
    downloadPage,
    setDownloadPage,
    selectedCommandOrigin,
  } = useCommands();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useUpdateEffect(() => {
    setPage(0);
  }, [reload]);

  useUpdateEffect(() => {
    if (
      selectedCommandsDateRange &&
      (selectedCommandsDateRange[0] || selectedCommandsDateRange[1]) &&
      (!selectedCommandsDateRange[0] || !selectedCommandsDateRange[1])
    ) {
      return;
    }
    setPage(0);
  }, [search, selectedCommandType, selectedStatus, selectedCommandsDateRange, selectedCommandOrigin]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadData = async () => {
      startLoading();
      await loadCommands();
      stopLoading();
    };

    loadData();
  }, [page]);

  useEffect(() => {
    if (!downloadPage) return;
    loadCommands(true);
    setDownloadPage(false);
  }, [downloadPage]);

  const loadCommands = async (download: boolean = false) => {
    const response = await loadHistoricCommands(
      page,
      search,
      selectedCommandsDateRange && selectedCommandsDateRange[0],
      selectedCommandsDateRange && selectedCommandsDateRange[1],
      selectedStatus,
      selectedCommandType,
      selectedCommandOrigin,
      download
    );
    if (response?.error) {
      showFailFeedback(response.error);
      if (!download) {
        setCommands(undefined);
        setTotalItems(0);
      }
    } else {
      if (download) {
        showPositiveFeedback('Se esta generando el archivo...');
      } else {
        setCommands(response?.commands);
        setTotalItems(response?.total ?? 0);
      }
    }
  };

  return (
    <FlexColumn classNames={`${classes.flexFill} ${css({ overflow: 'hidden' })}`}>
      {!commands && !loading && <EmptyState title="Todavía no envias ningun comando" />}
      {!commands && loading && <Loading />}
      {commands && (
        <>
          {!!commands?.length && (
            <StyledPaginatedTable
              columns={TABLE_COLUMNS_NAME}
              data={commands.map((command) => {
                return {
                  items: [
                    command?.asset.vehicle?.licensePlate || command?.asset.container?.identifier,
                    command.ident,
                    command?.asset.label || '-',
                    commandLabels ? commandLabels[command?.commandType] : '-',
                    formatDateFromTs(+command?.created) || '-',
                    <span
                      key={`status-${command._id}`}
                      className={css({
                        color: getCommandStatusColor(command.status),
                      })}
                    >
                      {COMMANDS_STATUS[command.status]}
                    </span>,
                    command?.user.username ?? '-',
                  ],
                };
              })}
              totalItems={totalItems}
              page={page}
              onPageChange={setPage}
              loading={loading}
            />
          )}
          {!commands.length && (
            <EmptyState
              title="No se encontraron resultados"
              description="Intenta con otra busqueda"
            />
          )}
        </>
      )}
    </FlexColumn>
  );
}
