import { FlexColumn } from '@gorila-shared-ui/components';
import { memo } from 'react';
import { useStyles } from '../../../hooks/useStyles';
import { SafeGptConfiguration } from '../../../types/safeGpt';
import StyledModal from '../../ui/StyledModal';
import { SafeGptConfigForm } from '../SafeGptConfigForm';

type Props = {
  isOpen: boolean;
  onClose: (update?: boolean) => void;
  safeGptConfig?: SafeGptConfiguration;
};

function SafeGptConfigModal({ onClose, isOpen, safeGptConfig }: Props) {
  const { css } = useStyles();

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      closeable
      title={safeGptConfig ? `Configuración: ${safeGptConfig?.name}` : 'Nueva'}
      size="auto"
      content={
        <FlexColumn
          classNames={css({
            width: '60vw',
            maxWidth: '750px',
            minWidth: '450px',
            boxSizing: 'border-box',
            padding: '1rem',
            borderTop: '1px solid rgb(226, 226, 226)',
            maxHeight: '75vh',
            overflow: 'hidden',
          })}
        >
          <SafeGptConfigForm
            afterSave={() => onClose(true)}
            onCancel={onClose}
            safeGptConfig={safeGptConfig}
          />
        </FlexColumn>
      }
    />
  );
}

export default memo(SafeGptConfigModal);
