import { ApiError } from '@gorila-shared-ui/components';
import { AppPermission } from '../types/appPermission';
import { fileRequest, getRequest } from './api';
import { URLS } from './urls/urls';

export const getAppPermissions = async (): Promise<{ appPermissions?: AppPermission; error?: ApiError }> => {
  const response = await getRequest(URLS.permissions.all);

  return {
    appPermissions: response?.data?.permissions,
    error: response?.error,
  };
};

export const getFileUrlByKey = async (key: string): Promise<{ url?: string; error?: ApiError }> => {
  const response = await fileRequest(key);
  return {
    url: response?.data?.url,
    error: response?.error,
  };
};
