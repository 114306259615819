import { blobToFile } from '@gorila-shared-ui/components';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { CSVData } from '../../types/app';

type Props = {
  file: File;
  onChange: (file: File) => void;
  editingHeaders?: boolean;
  onlyRead?: boolean;
};
export function CSVPreview({ file, onChange, editingHeaders = false, onlyRead = false }: Props) {
  const { css, theme } = useStyles();
  const [csvData, setCSVData] = useState<CSVData | null>(null);
  const [fileLoad, setFileLoad] = useState(true);
  const headerColumns = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  useEffect(() => {
    if (file) {
      setFileLoad(true);
      handleFileUpload();
    }
  }, [file]);

  useEffect(() => {
    if (csvData) {
      if (fileLoad) {
        setFileLoad(false);
        return;
      }
      const csv = Papa.unparse({
        fields: csvData.headers,
        data: csvData.rows,
      });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      onChange(blobToFile(blob, file.name));
    }
  }, [csvData]);

  const handleFileUpload = () => {
    Papa.parse(file, {
      complete: (result) => {
        const headers = result.data[0] as string[];
        const rows = result.data.slice(1) as string[][];
        setCSVData({ headers, rows });
      },
      header: false,
    });
  };

  const handleHeaderChange = (headerIndex: number, value: string) => {
    if (!csvData) return;

    const updatedHeaders = [...csvData.headers];
    updatedHeaders[headerIndex] = value;

    setCSVData({ ...csvData, headers: updatedHeaders });
  };

  const handleCellChange = (rowIndex: number, colIndex: number, value: string) => {
    if (!csvData) return;

    const updatedRows = [...csvData.rows];
    updatedRows[rowIndex][colIndex] = value;

    setCSVData({ ...csvData, rows: updatedRows });
  };

  const rowHeaderStyles = css({
    textAlign: 'center',
    backgroundColor: theme.colors.backgroundTertiary,
    padding: theme.sizing.scale100,
    minWidth: '20px',
  });

  const cellStyles = css({
    padding: theme.sizing.scale100,
    borderColor: theme.colors.inputBorder,
    borderRadius: '5px',
    fontSize: '12px',
    width: '110px',
  });

  const headerCellStyles = css({
    padding: theme.sizing.scale100,
    backgroundColor: theme.colors.backgroundPrimary,
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
    width: '110px',
  });

  if (!csvData) return;

  const columnsCount = csvData.headers?.length <= 10 ? 10 : csvData?.headers.length;

  return (
    <div
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      })}
    >
      {csvData && (
        <table className={css({ overflow: 'auto', display: 'inline-block', maxHeight: '300px', position: 'relative' })}>
          <thead className={css({ display: 'inline-block', width: '100%', position: 'sticky', top: '-1px' })}>
            <tr className={css({ display: 'inline-block', width: '100%' })}>
              {headerColumns.slice(0, columnsCount + 1).map((header, index) => (
                <th
                  key={index}
                  className={css({
                    padding: theme.sizing.scale100,
                    backgroundColor: theme.colors.backgroundTertiary,
                    width:
                      index !== 0 && index <= csvData.headers?.length
                        ? '118px'
                        : index === 0
                        ? '20px'
                        : `calc(100% / 10)`,
                    minWidth: index !== 0 && index <= csvData.headers?.length ? '118px' : '20px',
                  })}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={rowHeaderStyles}>1</td>
              {csvData.headers.map((header, index) => (
                <td
                  key={index}
                  className={editingHeaders ? undefined : headerCellStyles}
                >
                  {editingHeaders ? (
                    <input
                      type="text"
                      value={header}
                      onChange={(e) => handleHeaderChange(index, e.target.value)}
                      className={cellStyles}
                      readOnly={onlyRead}
                    />
                  ) : (
                    header
                  )}
                </td>
              ))}
            </tr>
            {csvData.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={rowHeaderStyles}>{rowIndex + 2}</td>
                {row.map((cell, colIndex) => (
                  <td key={colIndex}>
                    <input
                      type="text"
                      value={cell}
                      onChange={(e) => handleCellChange(rowIndex, colIndex, e.target.value)}
                      className={cellStyles}
                      readOnly={onlyRead}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
