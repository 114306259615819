import {
  EllipsedText,
  MaterialIcon,
  StyledTooltip,
  useLoading,
  useModal,
  useMoment,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { WHATSAPP_TABLE_COLUMNS_NAME } from '../../constants/whatsapp';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getWhatsappsList } from '../../services/whatsappService';
import { Whatsapp } from '../../types/whatsapp';
import { ListWrapper } from '../shared/ListWrapper';
import DetailCampaignModal from './modal/DetailCampaignModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function WhatsappList({ search, reloadPage }: Readonly<Props>) {
  const [whatsapps, setWhatsapps] = useState<Whatsapp[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const { formatDateFromTs } = useMoment();
  const [selectedWhatsapp, setSelectedWhatsapp] = useState<Whatsapp>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    const loadWhatsapps = async () => {
      startLoading();
      const { whatsapps, error, total } = await getWhatsappsList(page, search);
      if (!error && whatsapps) {
        setWhatsapps(whatsapps);
        setTotalItems(total);
      } else {
        setWhatsapps(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadWhatsapps();
  }, [page]);

  const openDetailModal = (whatsapp: Whatsapp) => {
    setSelectedWhatsapp(whatsapp);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedWhatsapp(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={WHATSAPP_TABLE_COLUMNS_NAME}
        data={
          whatsapps?.map((whatsapp, i) => {
            return {
              items: [
                <EllipsedText key={`whatsapp-name-${i}`}>{whatsapp.name || '-'}</EllipsedText>,
                <EllipsedText key={`whatsapp-executed-${i}`}>{whatsapp.executed ? 'Si' : 'No'}</EllipsedText>,
                <EllipsedText key={`whatsapp-created-${i}`}>
                  {whatsapp.created ? formatDateFromTs(whatsapp.created, 'DD/MM/yyyy HH:mm') : '-'}
                </EllipsedText>,
                <StyledTooltip
                  key={`details-${whatsapp._id}`}
                  content={'Ver detalle'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDetailModal(whatsapp)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={whatsapps !== undefined}
        isEmpty={!whatsapps?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna campaña de whatsapp"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && selectedWhatsapp && (
        <DetailCampaignModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          whatsappId={selectedWhatsapp._id}
        />
      )}
    </>
  );
}
