import { FlexColumn, LabeledField, LabeledInput, useLoading } from '@gorila-shared-ui/components';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES, REQUIRED_FIELD_TEXT } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useForms } from '../../hooks/useForms';
import { createWhatsapp, saveWhatappFile } from '../../services/whatsappService';
import {
  editWhatsappErrorState,
  editWhatsappState,
  editWhatsappTouchedState,
  editWhatsappValidState,
} from '../../storage/whatsapp';
import { WhatsappRequest, WhatsappRequestTouched } from '../../types/whatsapp';
import { CSVPreview } from '../shared/CSVPreview';
import { DocumentUploader } from '../shared/DocumentUploader';
import { FormActionsRow } from '../shared/FormActionsRow';
import { TemplateSelect } from '../shared/selects/TemplateSelect';

type Props = {
  onCancel: () => void;
  afterSave: () => void;
};
export function WhatsappForm({ onCancel, afterSave }: Props) {
  const [form, setForm] = useRecoilState(editWhatsappState);
  const [formTouched, setFormTouched] = useRecoilState(editWhatsappTouchedState);
  const formValid = useRecoilValue(editWhatsappValidState);
  const formHasError = useRecoilValue(editWhatsappErrorState);
  const resetEditWhatsappState = useResetRecoilState(editWhatsappState);
  const resetEditWhatsappTouchedState = useResetRecoilState(editWhatsappTouchedState);
  const { onTouched, onChangeInput } = useForms<WhatsappRequest, WhatsappRequestTouched>(setForm, setFormTouched);
  const { loading, startLoading, stopLoading } = useLoading();
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const canSubmit = !formHasError;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  const onSave = async () => {
    if (!canSubmit || !form.file) return;
    startLoading();
    const { error, path } = await saveWhatappFile(form.file);
    if (error) {
      showFailFeedback(error || FEEDBACK.failedCreation(FEEDBACK_PREFIXES.whatsapp));
    } else if (path) {
      const { error } = await createWhatsapp({ ...form, fileKey: path });
      if (!error) {
        afterSave();
        showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.whatsapp));
      } else {
        showFailFeedback(error || FEEDBACK.failedCreation(FEEDBACK_PREFIXES.whatsapp));
      }
    }
    stopLoading();
  };

  const clearStates = () => {
    resetEditWhatsappState();
    resetEditWhatsappTouchedState();
  };

  return (
    <FlexColumn>
      <LabeledInput
        label={'Nombre'}
        placeholder={'Ingrese el nombre de la campaña'}
        value={form.name}
        onChange={(value) => {
          onChangeInput('name', value);
        }}
        onBlur={() => onTouched('nameTouched')}
        error={formTouched.nameTouched && !formValid.name && REQUIRED_FIELD_TEXT}
        required
      />
      <TemplateSelect
        selectedTemplateId={form.template}
        onChangeTemplateId={(value) => {
          onChangeInput('template', value);
        }}
        onBlur={() => onTouched('templateTouched')}
        error={formTouched.templateTouched && !formValid.template && REQUIRED_FIELD_TEXT}
        required
      />
      <LabeledField
        label="Archivo"
        error={formTouched.fileTouched && !formValid.file && REQUIRED_FIELD_TEXT}
        required
      >
        <DocumentUploader
          extensions={['.csv']}
          onFileChange={(file) => {
            onChangeInput('file', file);
          }}
          onBlur={() => onTouched('fileTouched')}
          file={form.file}
        />
      </LabeledField>
      {form.file && (
        <CSVPreview
          file={form.file}
          onChange={(file) => {
            onChangeInput('file', file);
          }}
        />
      )}
      <FormActionsRow
        onSubmit={onSave}
        onCancel={onCancel}
        loading={loading}
        canSubmit={canSubmit}
      />
    </FlexColumn>
  );
}
