import { ModalProps, StyledModalContent } from '@gorila-shared-ui/components';
import { Modal } from 'baseui/modal';
import { createPortal } from 'react-dom';

const StyledModal = ({
  content,
  onAccept,
  onCancel,
  onClose,
  title,
  closeable = false,
  isOpen = false,
  size = 'default',
}: ModalProps) => {
  return createPortal(
    <Modal
      onClose={onClose}
      closeable={closeable}
      isOpen={isOpen}
      animate
      autoFocus
      size={size}
    >
      <StyledModalContent
        content={content}
        onAccept={onAccept}
        onCancel={onCancel}
        title={title}
      />
    </Modal>,
    document.body
  );
};

export default StyledModal;
