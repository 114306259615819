import { FlexColumn, FlexRow, LabeledField, StyledButton, useLoading } from '@gorila-shared-ui/components';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { FEEDBACK, FEEDBACK_PREFIXES, REQUIRED_FIELD_TEXT } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useForms } from '../../hooks/useForms';
import { useStyles } from '../../hooks/useStyles';
import { postMassiveCommand } from '../../services/massiveCommandService';
import {
  editMassiveErrorState,
  editMassiveState,
  editMassiveTouchedState,
  editMassiveValidState,
} from '../../storage/massiveComms';
import { MassiveCommandRequest, MassiveCommandRequestTouched } from '../../types/massiveCommands';
import { CSVPreview } from '../shared/CSVPreview';
import { DocumentUploader } from '../shared/DocumentUploader';
import { AvailableCommandsSelect } from '../shared/selects/AvailableCommandsSelect';
import { DeviceModelSelect } from '../shared/selects/DeviceModelSelect';

type Props = {
  onCancel: () => void;
  afterSave: () => void;
};
export function MassiveCommsForm({ onCancel, afterSave }: Props) {
  const { css, theme } = useStyles();
  const [form, setForm] = useRecoilState(editMassiveState);
  const [formTouched, setFormTouched] = useRecoilState(editMassiveTouchedState);
  const formValid = useRecoilValue(editMassiveValidState);
  const formHasError = useRecoilValue(editMassiveErrorState);
  const resetEditMassiveState = useResetRecoilState(editMassiveState);
  const resetEditMassiveTouchedState = useResetRecoilState(editMassiveTouchedState);
  const { onTouched, onChangeInput } = useForms<MassiveCommandRequest, MassiveCommandRequestTouched>(
    setForm,
    setFormTouched
  );
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<string>();
  const canSubmit = !formHasError;

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  useEffect(() => {
    if (!(form.idents.length > 0)) {
      setFileError('El archivo esta vacio');
    }
  }, [form.idents]);

  const onFileChange = (file?: File) => {
    if (!file) {
      setFileError(REQUIRED_FIELD_TEXT);
      onChangeInput('idents', []);
    } else {
      setFileError(undefined);
      Papa.parse(file, {
        complete: (result) => {
          const rows = result.data.slice(1) as string[][];
          onChangeInput(
            'idents',
            rows.map((row) => row[0])
          );
        },
        header: false,
      });
    }
    setFile(file);
  };

  const onSave = async () => {
    if (!canSubmit) return;
    startLoading();
    const { error } = await postMassiveCommand(form);
    if (!error) {
      afterSave();
      showPositiveFeedback(FEEDBACK.created(FEEDBACK_PREFIXES.massiveCommands));
    } else {
      showFailFeedback(error || FEEDBACK.failedCreation(FEEDBACK_PREFIXES.massiveCommands));
    }
    stopLoading();
  };

  const clearStates = () => {
    resetEditMassiveState();
    resetEditMassiveTouchedState();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          height: '100%',
        })}
      >
        <LabeledField
          label="Modelo de los dispositivos"
          error={formTouched.deviceModelTouched && !formValid.deviceModelId && REQUIRED_FIELD_TEXT}
          required
        >
          <DeviceModelSelect
            label=""
            onChangeDeviceModel={(deviceModelId) => onChangeInput('deviceModelId', deviceModelId)}
            isFilter={false}
            selectedDeviceModelId={form.deviceModelId}
            onBlur={() => onTouched('deviceModelTouched')}
            required
          />
        </LabeledField>
        <LabeledField
          label="Commandos disponibles:"
          error={formTouched.commandTouched && !formValid.command && REQUIRED_FIELD_TEXT}
          required
        >
          <AvailableCommandsSelect
            label=""
            onChangeCommand={(command) => onChangeInput('command', command)}
            deviceModelId={form.deviceModelId}
            selectedCommandId={form.command}
            disabled={!form.deviceModelId}
            onBlur={() => onTouched('commandTouched')}
            required
          />
        </LabeledField>
        <LabeledField
          label="Archivo"
          error={formTouched.fileTouched && fileError}
          required
        >
          <DocumentUploader
            extensions={['.csv']}
            onFileChange={(file) => {
              onFileChange(file);
            }}
            onBlur={() => onTouched('fileTouched')}
            file={file}
          />
        </LabeledField>
        {file && (
          <CSVPreview
            file={file}
            onChange={(file) => {
              onFileChange(file);
            }}
          />
        )}
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          <StyledButton
            kind="tertiary"
            onClick={onCancel}
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
