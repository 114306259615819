import { FlexRow, MaterialIcon, StyledSearchBar, StyledTooltip, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useResetRecoilState } from 'recoil';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useStyles } from '../../hooks/useStyles';
import { wsTypeState } from '../../storage/logs';
import { WebServiceLogsFilters } from './WebServiceLogsFilters';
import WebServiceLogsFiltersModal from './modal/WebServiceLogsFiltersModal';

type Props = {
  search?: string;
  selectedClientId?: string;
  selectedSubClientId?: string;
  setSelectedClientId: (clientId?: string) => void;
  setSelectedSubClientId: (subClientId?: string) => void;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function WebServiceLogsFiltersBar({
  search,
  onChangeSearch,
  onReloadPage,
  selectedClientId,
  selectedSubClientId,
  setSelectedSubClientId,
  setSelectedClientId,
}: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { openModal: openFilterModal, closeModal: closeFilterModal, showModal: showFilterModal } = useModal();
  const resetSelectedWsType = useResetRecoilState(wsTypeState);
  const { isLarge } = useBreakpoints();

  const resetFilters = () => {
    onChangeSearch(undefined);
    resetSelectedWsType();
    setSelectedClientId(undefined);
    setSelectedSubClientId(undefined);
  };

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <FlexRow
          classNames={css({
            width: '60%',
          })}
        >
          <StyledSearchBar
            value={search ?? ''}
            onChange={onChangeSearch}
            clearable
          />
        </FlexRow>
        {!isLarge && (
          <WebServiceLogsFilters
            selectedClientId={selectedClientId}
            selectedSubClientId={selectedSubClientId}
            setSelectedClientId={setSelectedClientId}
            setSelectedSubClientId={setSelectedSubClientId}
          />
        )}
        {isLarge && (
          <StyledTooltip
            content={'Filtros'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={openFilterModal}
              kind="tertiary"
            >
              <MaterialIcon name={'filter_list'} />
            </Button>
          </StyledTooltip>
        )}
        <StyledTooltip
          content={'Restaurar filtros'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={resetFilters}
            kind="tertiary"
          >
            <MaterialIcon name={'autorenew'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
      </FlexRow>
      {showFilterModal && (
        <WebServiceLogsFiltersModal
          onClose={closeFilterModal}
          isOpen={showFilterModal}
          selectedClientId={selectedClientId}
          selectedSubClientId={selectedSubClientId}
          setSelectedClientId={setSelectedClientId}
          setSelectedSubClientId={setSelectedSubClientId}
        />
      )}
    </FlexRow>
  );
}
