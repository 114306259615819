import { FlexRow, MaterialIcon } from '@gorila-shared-ui/components';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { Key, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useStyles } from '../../hooks/useStyles';
import { editFleetHealthRuleState } from '../../storage/fleetHealthStates';
import { FleetHealthAlarmRequest, FleetHealthAlarmType } from '../../types/fleetHealth';
import { FleetHealthAlarmEditForm } from './FleetHealthAlarmEditForm';
import { FleetHealthAlarmForm } from './FleetHealthAlarmForm';

export function FleetHealthAlarmTabs() {
  const { theme } = useStyles();
  const [form, setForm] = useRecoilState(editFleetHealthRuleState);
  const [activeAlarmsKey, setActiveAlarmsKey] = useState<Key>(form.alarms.length ? 0 : 'new');

  const onSubmit = (alarmForm: FleetHealthAlarmRequest, alarmType?: FleetHealthAlarmType) => {
    if (!alarmForm.code || !alarmType) return;
    setForm((prev) => {
      const newAlarms = [...prev.alarms];
      newAlarms.push({
        ...alarmForm,
        code: alarmForm.code!,
        name: alarmType.name,
      });
      return {
        ...prev,
        alarms: newAlarms,
      };
    });
  };

  return (
    <Tabs
      activeKey={activeAlarmsKey}
      onChange={({ activeKey }) => {
        setActiveAlarmsKey(activeKey);
      }}
      orientation="vertical"
      overrides={{
        TabList: {
          style: {
            minWidth: '120px',
            maxWidth: '120px',
          },
        },
      }}
    >
      {form.alarms.map((alarm, index) => (
        <Tab
          title={alarm.name}
          key={index}
          overrides={{
            Tab: {
              style: {
                textAlign: 'end',
              },
            },
          }}
        >
          <FleetHealthAlarmEditForm
            fleetHealthAlarm={alarm}
            index={index}
          />
        </Tab>
      ))}
      <Tab
        title={
          <FlexRow
            centered
            gap={theme.sizing.scale300}
          >
            <MaterialIcon
              name="add"
              size={theme.sizing.scale600}
            />
            Nueva
          </FlexRow>
        }
        key="new"
      >
        <FleetHealthAlarmForm onSubmit={onSubmit} />
      </Tab>
    </Tabs>
  );
}
