import { EllipsedText, MaterialIcon, StyledTooltip, useLoading, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { BRAND_TABLE_COLUMNS_NAME } from '../../constants/brand';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getBrandsList } from '../../services/vehicleService';
import { Brand } from '../../types/brand';
import { ListWrapper } from '../shared/ListWrapper';
import BrandDetailModal from './modal/BrandDetailModal';

type Props = {
  search?: string;
  reloadPage?: boolean;
};
export function BrandsList({ search, reloadPage }: Readonly<Props>) {
  const [brands, setBrands] = useState<Brand[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { openModal, closeModal, showModal } = useModal();
  const [selectedBrand, setSelectedBrand] = useState<Brand>();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadBrands = async () => {
      const { brands, error, total } = await getBrandsList(page, search);
      if (!error && brands) {
        setBrands(brands);
        setTotalItems(total);
      } else {
        setBrands(undefined);
        setTotalItems(0);
      }
      stopLoading();
    };
    loadBrands();
  }, [page]);

  const openDetailModal = (brand: Brand) => {
    setSelectedBrand(brand);
    openModal();
  };

  const closeDetailModal = (updateTable?: boolean) => {
    setSelectedBrand(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={BRAND_TABLE_COLUMNS_NAME}
        data={
          brands?.map((brand, i) => {
            return {
              items: [
                <EllipsedText key={`brand-name-${i}`}>{brand.name}</EllipsedText>,
                <StyledTooltip
                  key={`details-${brand._id}`}
                  content={'Ver detalle'}
                  showArrow={false}
                >
                  <Button
                    size="compact"
                    shape="square"
                    onClick={() => openDetailModal(brand)}
                    kind="tertiary"
                  >
                    <MaterialIcon name={'visibility'} />
                  </Button>
                </StyledTooltip>,
              ],
            };
          }) ?? []
        }
        hasData={brands !== undefined}
        isEmpty={!brands?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna marca"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <BrandDetailModal
          onClose={(updateTable) => closeDetailModal(updateTable)}
          isOpen={showModal}
          selectedBrand={selectedBrand}
        />
      )}
    </>
  );
}
