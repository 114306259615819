import {
  FlexRow,
  MaterialIcon,
  StyledButton,
  StyledSearchBar,
  StyledTooltip,
  useModal,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useStyles } from '../../hooks/useStyles';
import DevicesBrandDetailModal from './modal/DevicesBrandDetailModal';

type Props = {
  search?: string;
  onChangeSearch: (search?: string) => void;
  onReloadPage: () => void;
};
export function DevicesBrandsFiltersBar({ search, onChangeSearch, onReloadPage }: Props) {
  const { openModal, closeModal, showModal } = useModal();
  const { css, theme } = useStyles();

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '50%',
        })}
      >
        <StyledSearchBar
          value={search ?? ''}
          onChange={onChangeSearch}
          clearable
        />
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nuevo
        </StyledButton>
      </FlexRow>
      {showModal && (
        <DevicesBrandDetailModal
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeModal();
          }}
          isOpen={showModal}
        />
      )}
    </FlexRow>
  );
}
