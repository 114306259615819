import { PaginatedResponse } from '@gorila-shared-ui/components';
import { getAssetsSearch } from '../../../../services/assetService';
import { AssetMinimal } from '../../../../types/asset';

export function useAssets() {
  const getSelectedAssets = async (
    clientId?: string,
    subClientId?: string,
    page?: number,
    search?: string,
    assetsSelected?: string[]
  ): Promise<PaginatedResponse<AssetMinimal> | undefined> => {
    const { assets, error, hasNext, total } = await getAssetsSearch(
      clientId,
      subClientId,
      page ?? 1,
      search,
      assetsSelected
    );
    if (!error && assets) {
      return {
        items: assets,
        hasNext,
        total,
      };
    }
  };

  const getUnselectedAssets = async (
    clientId?: string,
    subClientId?: string,
    page?: number,
    search?: string,
    assetsSelected?: string[]
  ): Promise<PaginatedResponse<AssetMinimal> | undefined> => {
    const { assets, error, hasNext, total } = await getAssetsSearch(
      clientId,
      subClientId,
      page ?? 1,
      search,
      undefined,
      assetsSelected
    );
    if (!error && assets) {
      return {
        items: assets,
        hasNext,
        total,
      };
    }
  };

  return {
    getSelectedAssets,
    getUnselectedAssets,
  };
}
