import {
  FlexRow,
  MaterialIcon,
  StyledButton,
  StyledSearchBar,
  StyledTooltip,
  useModal,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useDebouncedSearch } from '../../../../hooks/useDebouncedSearch';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { useStyles } from '../../../../hooks/useStyles';
import { clientUsersSearchFilterState } from '../../../../storage/clients';
import UserDetailModal from '../../../users/modal/UserDetailModal';

type Props = {
  clientId: string;
  onReloadPage: () => void;
};
export function ClientUserFiltersBar({ clientId, onReloadPage }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const { openModal: openFormModal, closeModal: closeFormModal, showModal: showFormModal } = useModal();
  const { search, setSearch } = useDebouncedSearch(useRecoilState(clientUsersSearchFilterState));
  const resetSearch = useResetRecoilState(clientUsersSearchFilterState);

  useEffectOnce(() => {
    return () => {
      resetSearch();
    };
  });

  return (
    <FlexRow
      classNames={css({
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <FlexRow
        gap={theme.sizing.scale300}
        classNames={css({
          width: '70%',
        })}
      >
        <FlexRow
          classNames={css({
            width: '60%',
          })}
        >
          <StyledSearchBar
            value={search ?? ''}
            onChange={setSearch}
            clearable
          />
        </FlexRow>
      </FlexRow>
      <FlexRow gap={theme.sizing.scale300}>
        <StyledTooltip
          content={'Recargar tabla'}
          showArrow={false}
        >
          <Button
            size="compact"
            shape="square"
            onClick={onReloadPage}
            kind="tertiary"
          >
            <MaterialIcon name={'refresh'} />
          </Button>
        </StyledTooltip>
        <StyledButton
          onClick={openFormModal}
          startEnhancer={
            <MaterialIcon
              name="add"
              size="xs-mini"
              color={theme.colors.contentInversePrimary}
            />
          }
        >
          Nuevo
        </StyledButton>
      </FlexRow>
      {showFormModal && (
        <UserDetailModal
          clientId={clientId}
          onClose={(updateTable) => {
            if (updateTable) {
              onReloadPage();
            }
            closeFormModal();
          }}
          isOpen={showFormModal}
        />
      )}
    </FlexRow>
  );
}
