import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  MaterialIcon,
  StyledButton,
  StyledTooltip,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import { postDevicesModel, updateDevicesModel } from '../../services/deviceService';
import { DeviceModel } from '../../types/deviceModel';
import { DevicesBrandForm } from '../devicesBrands/DevicesBrandForm';
import { DeviceBrandSelect } from '../shared/selects/DeviceBrandSelect';

type Props = {
  deviceModel?: DeviceModel;
  onCancel: () => void;
  afterSave: () => void;
  inline?: boolean;
  deviceBrandId?: string;
};
export function DeviceModelForm({ deviceModel, onCancel, afterSave, inline = false, deviceBrandId }: Props) {
  const { css, theme } = useStyles();
  const [editingDeviceModel, setEditingDeviceModel] = useState<DeviceModel>({
    _id: '',
    name: '',
    brand: {
      _id: '',
      name: '',
    },
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [createBrand, setCreateBrand] = useState(false);
  const [selectedDeviceBrandId, setSelectedDeviceBrandId] = useState<string>();

  useEffect(() => {
    if (deviceModel) {
      setEditingDeviceModel(deviceModel);
    } else {
      onInputChange({ _id: undefined }, 'brand');
    }
  }, [deviceModel]);

  const hasUpdates = !areEqual(deviceModel ?? {}, editingDeviceModel);

  const canSubmit = useMemo(() => {
    return !!editingDeviceModel.name && !!editingDeviceModel.brand._id;
  }, [editingDeviceModel]);

  const onInputChange = (value: string | undefined | null | {}, field: keyof DeviceModel) => {
    setEditingDeviceModel((prev) => ({ ...prev!, [field]: value }));
  };

  useEffect(() => {
    if (!deviceBrandId && !selectedDeviceBrandId) return;
    setSelectedDeviceBrandId(deviceBrandId);
    onInputChange({ _id: deviceBrandId }, 'brand');
  }, [deviceBrandId]);

  const onSave = async () => {
    startLoading();
    const { id, error } = deviceModel
      ? await updateDevicesModel(editingDeviceModel)
      : await postDevicesModel(editingDeviceModel);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        deviceModel
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.deviceModel, editingDeviceModel.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.deviceModel)
      );
    } else {
      showFailFeedback(
        error || deviceModel
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.deviceModel, editingDeviceModel.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.deviceModel)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: 'end',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          width: '100%',
          height: '100%',
          flexDirection: inline ? 'row-reverse' : 'column',
        })}
      >
        <LabeledInput
          label={`${inline ? '(Nuevo Modelo) ' : ''}Nombre:`}
          value={editingDeviceModel?.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        {createBrand && (
          <FlexRow classNames={css({ width: '100%', alignItems: 'end' })}>
            <DevicesBrandForm
              afterSave={() => {
                setCreateBrand(false);
                onInputChange({ _id: editingDeviceModel.brand._id }, 'brand');
              }}
              onCancel={() => {
                setCreateBrand(false);
                onInputChange({ _id: editingDeviceModel.brand._id }, 'brand');
              }}
              inline
            />
          </FlexRow>
        )}
        {!createBrand && (
          <FlexRow
            classNames={css({ width: '100%', alignItems: 'end' })}
            gap={'8px'}
          >
            <DeviceBrandSelect
              deviceBrand={deviceModel?.brand}
              selectedDeviceBrandId={editingDeviceModel?.brand._id}
              onChangeDeviceBrand={(brandId) => onInputChange({ _id: brandId }, 'brand')}
              isFilter={false}
              required
            />
            <StyledTooltip
              content={'Nueva'}
              showArrow={false}
            >
              <Button
                size="compact"
                shape="square"
                onClick={() => setCreateBrand(true)}
                kind="tertiary"
              >
                <MaterialIcon
                  name={'add'}
                  size="compact"
                />
              </Button>
            </StyledTooltip>
          </FlexRow>
        )}
      </FlexColumn>
      {inline && (
        <FlexRow gap={0}>
          <StyledTooltip
            content={'Cancelar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onCancel}
              kind="tertiary"
            >
              <MaterialIcon name={'cancel'} />
            </Button>
          </StyledTooltip>
          <StyledTooltip
            content={'Guardar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
              kind="tertiary"
            >
              <MaterialIcon name={'done'} />
            </Button>
          </StyledTooltip>
        </FlexRow>
      )}
      {!inline && (
        <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
          <FlexRow gap={theme.sizing.scale300}>
            {deviceModel?._id && (
              <StyledButton
                kind="tertiary"
                onClick={onCancel}
              >
                Cancelar
              </StyledButton>
            )}
            <StyledButton
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
            >
              Guardar
            </StyledButton>
          </FlexRow>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
