import { EllipsedText, FlexColumn, SectionedData, useMoment } from '@gorila-shared-ui/components';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import { HeadingXSmall } from 'baseui/typography';
import { memo, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from '../../../../hooks/useStyles';
import { FleetHealthLastEvent } from '../../../../types/fleetHealth';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  event: FleetHealthLastEvent;
};
function FleetHealthLastEventModal({ onClose, isOpen, event }: Props) {
  const { theme, css } = useStyles();
  const { formatDateFromTs } = useMoment();

  const eventData = useMemo(() => {
    return [
      {
        label: 'Fecha:',
        content: event.timestamp ? formatDateFromTs(event.timestamp, 'yyyy-MM-DD HH:mm:ss') : '-',
      },
      {
        label: 'Latitud:',
        content: event.latitude ? event.latitude.toString() : '-',
      },
      {
        label: 'Longitud:',
        content: event.longitude ? event.longitude.toString() : '-',
      },
      {
        label: 'Velocidad:',
        content: event.speed !== undefined ? `${event.speed} km/h` : '-',
      },
      {
        label: 'Batería:',
        content: event.batteryPercentage ? `${event.batteryPercentage}%` : '-',
      },
    ];
  }, [event]);

  return createPortal(
    <Modal
      animate
      closeable
      onClose={onClose}
      isOpen={isOpen}
      size="auto"
      overrides={{
        Dialog: {
          style: {
            maxWidth: '450px',
          },
        },
      }}
    >
      <ModalHeader>
        <HeadingXSmall margin={0}>
          <EllipsedText
            breakAll
            maxLines={3}
          >
            {event.geoReference}
          </EllipsedText>
        </HeadingXSmall>
      </ModalHeader>
      <ModalBody>
        <FlexColumn
          gap={theme.sizing.scale800}
          classNames={css({ minWidth: '350px' })}
        >
          <SectionedData
            title="Información general"
            data={eventData}
          />
        </FlexColumn>
      </ModalBody>
    </Modal>,
    document.body
  );
}

export default memo(FleetHealthLastEventModal);
