import {
  ApiError,
  EmptyState,
  FlexColumn,
  FlexRow,
  FullContainer,
  MaterialIcon,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { LabelMedium } from 'baseui/typography';
import { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DEFAULT_LIST_ITEM_HEIGHT } from '../../../constants/app';
import { DEFAULT_SUB_CLIENT_FILTERS } from '../../../constants/subClients';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getSubClientsSearch } from '../../../services/subClientService';
import { IdName } from '../../../types/app';
import { SubClientFiltersBar } from './SubClientFiltersBar';
import SubClientItem from './SubClientItem';

type Props = {
  assignedSubClientIds: string[];
  onAssignedChange: (subClientIds: string[]) => void;
  required?: boolean;
  onError?: (error?: ApiError) => void;
  onlyEnabledSafeGpt?: boolean;
};
export function SubClientListPicker({
  assignedSubClientIds,
  onAssignedChange,
  required,
  onError,
  onlyEnabledSafeGpt = false,
}: Readonly<Props>) {
  const { theme, css, classes } = useStyles();
  const [selectedSubClients, setSelectedSubClients] = useState<IdName[]>();
  const [selectedSubClientHasNext, setSelectedSubClientHasNext] = useState(false);
  const [selectedSubClientPage, setSelectedSubClientPage] = useState(1);
  const {
    startLoading: startSelectedSubClientLoading,
    stopLoading: stopSelectedSubClientLoading,
    loading: selectedSubClientLoading,
  } = useLoading();
  const [unselectedSubClients, setUnselectedSubClients] = useState<IdName[]>();
  const [unselectedSubClientHasNext, setUnselectedSubClientHasNext] = useState(false);
  const [unselectedSubClientPage, setUnselectedSubClientPage] = useState(1);
  const {
    startLoading: startUnselectedSubClientLoading,
    stopLoading: stopUnselectedSubClientLoading,
    loading: unselectedSubClientLoading,
  } = useLoading();
  const { search, setSearch, debouncedSearch: searchTrigger } = useDebouncedSearch('', 250);
  const [filters, setFilters] = useState(DEFAULT_SUB_CLIENT_FILTERS);

  const isSubClientsSelectLoaded = (index: number) =>
    !selectedSubClientHasNext || index < (selectedSubClients?.length ?? 0);
  const subClientSelectedCount = selectedSubClientHasNext
    ? (selectedSubClients?.length ?? 0) + 1
    : selectedSubClients?.length ?? 0;
  const loadMoreSelectedSubClient =
    selectedSubClientLoading || !selectedSubClientHasNext
      ? () => {}
      : () => {
          setSelectedSubClientPage(selectedSubClientPage + 1);
        };

  const isSubClientsUnselectLoaded = (index: number) =>
    !unselectedSubClientHasNext || index < (unselectedSubClients?.length ?? 0);
  const subClientUnselectedCount = unselectedSubClientHasNext
    ? (unselectedSubClients?.length ?? 0) + 1
    : unselectedSubClients?.length ?? 0;
  const loadMoreUnselectSubClients =
    unselectedSubClientLoading || !unselectedSubClientHasNext
      ? () => {}
      : () => {
          setUnselectedSubClientPage(unselectedSubClientPage + 1);
        };

  useUpdateEffect(() => {
    setSelectedSubClientPage(0);
    setUnselectedSubClientPage(0);
  }, [assignedSubClientIds, searchTrigger, filters]);

  useEffect(() => {
    if (!assignedSubClientIds.length) {
      setSelectedSubClients([]);
      setSelectedSubClientPage(1);
      return;
    }
    if (selectedSubClientPage === 0) {
      setSelectedSubClientPage(1);
      return;
    }
    const loadSelectedSubClients = async () => {
      startSelectedSubClientLoading();
      const { subClientsPaginated, error } = await getSubClientsSearch({
        page: selectedSubClientPage,
        q: search,
        include: assignedSubClientIds,
        clientId: filters.clientId,
        safeGPT: onlyEnabledSafeGpt,
      });
      if (subClientsPaginated && !error) {
        setSelectedSubClients((prev) =>
          prev && selectedSubClientPage > 1 ? [...prev, ...subClientsPaginated.items] : subClientsPaginated.items
        );
        setSelectedSubClientHasNext(subClientsPaginated.hasNext);
      } else {
        onError && onError(error);
        setSelectedSubClients([]);
        setSelectedSubClientHasNext(false);
      }
      stopSelectedSubClientLoading();
    };

    loadSelectedSubClients();
  }, [selectedSubClientPage]);

  useEffect(() => {
    if (unselectedSubClientPage === 0) {
      setUnselectedSubClientPage(1);
      return;
    }
    const loadUnselectedSubClients = async () => {
      startUnselectedSubClientLoading();
      const { subClientsPaginated, error } = await getSubClientsSearch({
        page: unselectedSubClientPage,
        q: search,
        exclude: assignedSubClientIds,
        clientId: filters.clientId,
        safeGPT: onlyEnabledSafeGpt,
      });
      if (subClientsPaginated && !error) {
        setUnselectedSubClients((prev) =>
          prev && unselectedSubClientPage > 1 ? [...prev, ...subClientsPaginated.items] : subClientsPaginated.items
        );
        setUnselectedSubClientHasNext(subClientsPaginated.hasNext);
      } else {
        onError && onError(error);
        setSelectedSubClients([]);
        setSelectedSubClientHasNext(false);
      }
      stopUnselectedSubClientLoading();
    };

    loadUnselectedSubClients();
  }, [unselectedSubClientPage]);

  if (!selectedSubClients && !unselectedSubClients) return null;

  const onSelectSubClient = (subClientId: string) => {
    const subClientsSelection = [...assignedSubClientIds, subClientId];
    onAssignedChange(subClientsSelection);
  };

  const onDeselectSubClient = (subClientId: string) => {
    const subClientsSelection = assignedSubClientIds?.filter((id) => subClientId !== id);
    onAssignedChange(subClientsSelection || []);
  };

  const onSelectAllSubClients = () => {
    onAssignedChange([
      ...(assignedSubClientIds || []),
      ...(unselectedSubClients?.map((subClient) => subClient._id) || []),
    ]);
    setSearch('');
  };

  const onDeselectAllSubClients = () => {
    if (search) {
      const subClientsSelection = assignedSubClientIds?.filter(
        (id) => !selectedSubClients?.some((subClient) => subClient._id === id)
      );
      onAssignedChange(subClientsSelection || []);
    } else {
      onAssignedChange([]);
    }
    setSearch('');
  };

  return (
    <FullContainer
      flexible
      classNames={`${classes.flexFill} 
        ${classes.verticalFlex} 
        ${css({
          gap: theme.sizing.scale400,
          minHeight: '400px',
        })}`}
    >
      <SubClientFiltersBar
        search={search}
        onChangeSearch={(event) => setSearch(event)}
        filters={filters}
        setFilters={setFilters}
      />
      <FlexRow classNames={`${classes.flexFill}`}>
        <FlexColumn classNames={`${classes.flexFill} ${css({ gap: theme.sizing.scale300 })}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <LabelMedium>Disponibles</LabelMedium>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!unselectedSubClients?.length}
              onClick={onSelectAllSubClients}
            >
              <MaterialIcon
                name="playlist_add"
                size="mini"
              />
            </Button>
          </FlexRow>
          {unselectedSubClients && (
            <>
              {!!unselectedSubClients?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isSubClientsUnselectLoaded}
                        itemCount={subClientUnselectedCount}
                        loadMoreItems={loadMoreUnselectSubClients}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={subClientUnselectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              subClients: unselectedSubClients,
                              type: 'selectable',
                              onClick: onSelectSubClient,
                              isLoaded: isSubClientsUnselectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {SubClientItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {search && !unselectedSubClients.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!search && !unselectedSubClients.length && (
                <EmptyState
                  title="No tienes ningun subcliente por asignar"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
        <FlexColumn classNames={`${classes.flexFill}`}>
          <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'space-between' })}`}>
            <FlexRow gap={theme.sizing.scale300}>
              <LabelMedium>Asignados {required && '*'}</LabelMedium>
              <LabelMedium color={theme.colors.contentSecondary}>({assignedSubClientIds.length})</LabelMedium>
            </FlexRow>
            <Button
              size="mini"
              shape="round"
              kind="tertiary"
              disabled={!selectedSubClients?.length}
              onClick={onDeselectAllSubClients}
            >
              <MaterialIcon
                name="playlist_remove"
                size="mini"
              />
            </Button>
          </FlexRow>
          {selectedSubClients && (
            <>
              {!!selectedSubClients?.length && (
                <ul
                  className={css({
                    margin: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    height: '100%',
                  })}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <InfiniteLoader
                        isItemLoaded={isSubClientsSelectLoaded}
                        itemCount={100}
                        loadMoreItems={loadMoreSelectedSubClient}
                      >
                        {({ onItemsRendered, ref }) => (
                          <List
                            height={height ? height : 500}
                            itemCount={subClientSelectedCount}
                            itemSize={() => DEFAULT_LIST_ITEM_HEIGHT}
                            width={width ? width : 200}
                            itemData={{
                              subClients: selectedSubClients,
                              type: 'deselectable',
                              onClick: onDeselectSubClient,
                              isLoaded: isSubClientsSelectLoaded,
                            }}
                            onItemsRendered={onItemsRendered}
                            overscanCount={4}
                            ref={ref}
                          >
                            {SubClientItem}
                          </List>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </ul>
              )}
              {search && !selectedSubClients.length && (
                <EmptyState
                  title="No se encontraron resultados"
                  description="Intenta con otra busqueda"
                  centered={false}
                />
              )}
              {!search && !selectedSubClients.length && (
                <EmptyState
                  title="No tienes ningun subcliente asignado"
                  centered={false}
                />
              )}
            </>
          )}
        </FlexColumn>
      </FlexRow>
    </FullContainer>
  );
}
