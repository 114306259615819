import { ApiError } from '@gorila-shared-ui/components';
import { Asset, AssetMinimal } from '../types/asset';
import { getRequest, postRequest } from './api';
import { URLS } from './urls/urls';

export const getAssetTypes = async (): Promise<{
  assetTypes?: {
    label: string;
    key: string;
  }[];
}> => {
  const response = await getRequest(URLS.assets.types, {});
  return {
    assetTypes: response?.data,
  };
};

export const getAssets = async ({
  clientId,
  subClientId,
  page,
  q,
  assetType,
}: {
  clientId?: string;
  subClientId?: string;
  page?: number;
  q?: string;
  assetType?: 'vehicle' | 'container' | 'phone';
}): Promise<{ assets?: Asset[]; error?: ApiError; total?: number; hasNext: boolean }> => {
  const params = {
    clientId,
    subClientId,
    page,
    q,
    assetType,
  };
  const response = await getRequest(URLS.assets.all, {
    queryParams: params,
  });
  return {
    assets: response?.data?.items,
    hasNext: response?.data?.hasNext || false,
    total: response?.data?.total,
    error: response?.error,
  };
};

export const getAssetsSearch = async (
  clientId?: string,
  subClientId?: string,
  page?: number,
  q?: string,
  assets?: string[],
  exclude?: string[]
): Promise<{ assets?: Asset[]; error?: ApiError; total: number; hasNext: boolean }> => {
  const body = {
    clientId,
    subClientId,
    page: page ?? 1,
    q,
    assets,
    exclude,
  };
  const response = await postRequest(URLS.assets.search, {
    body,
  });
  return {
    assets: response?.data?.items,
    total: response?.data?.total,
    hasNext: response?.data?.hasNext ?? false,
    error: response?.error,
  };
};

export const getAsset = async (assetId: string): Promise<{ asset?: Asset; error?: ApiError }> => {
  const response = await getRequest(URLS.assets.detail(assetId), {});
  return {
    asset: response?.data,
    error: response?.error,
  };
};

export const postAsset = async (asset: AssetMinimal): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    label: asset.label,
    assetType: asset.assetType,
    deviceId: asset.device?._id,
    secondaries: asset.secondaries?.map((device) => device._id),
    clientId: asset.client?._id,
    subClientId: asset.subClient?._id,
    deleted: asset.deleted,
    monitoring: asset.monitoring,
    insuranceNumber: asset.insuranceNumber,
    insuranceEndDate: asset.insuranceEndDate,
    insuranceStartDate: asset.insuranceStartDate,
  };
  if (body.assetType === 'vehicle' && asset.vehicle) {
    const { vehicle } = asset;
    body['vehicle'] = {
      color: vehicle.color,
      year: vehicle.year,
      brandId: vehicle.brand._id,
      subBrandId: vehicle.subBrand?._id,
      versionId: vehicle.version?._id,
      licensePlate: vehicle.licensePlate,
      vehicleType: vehicle.vehicleType,
      vin: vehicle.vin,
    };
  } else if (body.assetType === 'container' && asset.container) {
    const { container } = asset;
    body['container'] = {
      ...container,
    };
  }
  const response = await postRequest(URLS.assets.create, {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};

export const updateAsset = async (asset: AssetMinimal): Promise<{ id?: string; error?: ApiError }> => {
  const body: { [key: string]: any } = {
    label: asset.label,
    assetType: asset.assetType,
    deviceId: asset.device?._id,
    secondaries: asset.secondaries?.map((device) => device._id),
    clientId: asset.client?._id,
    subClientId: asset.subClient?._id,
    deleted: asset.deleted,
    monitoring: asset.monitoring,
    insuranceNumber: asset.insuranceNumber,
    insuranceEndDate: asset.insuranceEndDate,
    insuranceStartDate: asset.insuranceStartDate,
  };
  if (body.assetType === 'vehicle' && asset.vehicle) {
    const { vehicle } = asset;
    body['vehicle'] = {
      color: vehicle.color,
      year: vehicle.year,
      brandId: vehicle.brand._id,
      subBrandId: vehicle.subBrand?._id,
      versionId: vehicle.version?._id,
      licensePlate: vehicle.licensePlate,
      vehicleType: vehicle.vehicleType,
      vin: vehicle.vin,
    };
  } else if (body.assetType === 'container' && asset.container) {
    const { container } = asset;
    body['container'] = {
      ...container,
    };
  }
  const response = await postRequest(URLS.assets.update(asset._id), {
    body,
  });
  return {
    id: response?.data?.id,
    error: response?.error,
  };
};
