import { ApiError } from '@gorila-shared-ui/components';
import { Command, CommandOrigin, CommandReportOffline, HistoricCommand } from '../types/command';
import { getRequest } from './api';
import { URLS } from './urls/urls';

export const getHistoricCommands = async (
  page: number,
  search?: string,
  startDate?: number | null,
  endDate?: number | null,
  status?: string,
  commandType?: string,
  commandOrigin?: CommandOrigin,
  download?: boolean
): Promise<{ commands?: HistoricCommand[]; error?: ApiError; total?: number }> => {
  const queryParams = {
    page,
    status,
    commandType,
    startDate,
    endDate,
    commandOrigin,
    download: download ? true : undefined,
    q: search,
  };
  const response = await getRequest(URLS.commands.historic, {
    queryParams,
  });
  return {
    total: response?.data?.total,
    commands: response?.data?.items,
    error: response?.error,
  };
};

export const getAvailableCommands = async (): Promise<{ commands?: Command[]; error?: ApiError }> => {
  const response = await getRequest(URLS.commands.all, {});
  return {
    commands: response?.data,
    error: response?.error,
  };
};

export const getCommandsReports = async (
  page: number,
  startDate?: number | null,
  endDate?: number | null
): Promise<{ commands?: CommandReportOffline[]; error?: ApiError; total?: number }> => {
  const queryParams = {
    page,
    startDate,
    endDate,
  };
  const response = await getRequest(URLS.commands.reports, {
    queryParams,
  });
  return {
    total: response?.data?.total,
    commands: response?.data?.items,
    error: response?.error,
  };
};

export const downloadCommandsReportFile = async (reportId: string): Promise<{ url?: string; error?: ApiError }> => {
  const response = await getRequest(URLS.commands.downloadReport(reportId));
  return {
    url: response?.data?.url,
    error: response?.error,
  };
};
