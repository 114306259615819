import { FlexColumn, FlexRow, LabeledInput, StyledButton, useLoading } from '@gorila-shared-ui/components';
import { Checkbox } from 'baseui/checkbox';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import { postClient, updateClient } from '../../../services/clientService';
import { Client } from '../../../types/client';
import { ContactForm } from '../../ui/ContactForm';

type Props = {
  client?: Client;
  onCancel: () => void;
  afterSave: () => void;
};
export function ClientForm({ client, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingClient, setEditingClient] = useState<Client>({
    _id: '',
    name: '',
    tradeName: '',
    paid: false,
    paymentDay: 1,
    rfc: '',
    safeGPT: false,
  });
  const [isValidContacts, setIsValidContacts] = useState({
    contact: true,
    installationContact: true,
    invoiceContact: true,
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();
  const [isRFCVisited, setIsRFCVisited] = useState(false);

  useEffect(() => {
    if (client) {
      setEditingClient(client);
    }
  }, [client]);

  const hasUpdates = !areEqual(client ?? {}, editingClient);

  const canSubmit = useMemo(() => {
    let erros = 0;
    if (
      !editingClient.name ||
      !editingClient.tradeName ||
      editingClient.rfc.length > 14 ||
      editingClient.rfc.length < 12 ||
      editingClient.paymentDay <= 0 ||
      editingClient.paymentDay > 31 ||
      !isValidContacts.contact ||
      !isValidContacts.installationContact ||
      !isValidContacts.invoiceContact
    )
      erros += 1;

    return erros === 0;
  }, [editingClient, isValidContacts]);

  const onInputChange = (value: string | boolean | number | undefined | null | {}, field: keyof Client) => {
    setEditingClient((prev) => ({ ...prev!, [field]: value }));
  };

  const onContactValidateChange = (value: boolean, field: string) => {
    setIsValidContacts((prev) => ({ ...prev!, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = client ? await updateClient(editingClient) : await postClient(editingClient);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        client
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.client, editingClient.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.client)
      );
    } else {
      showFailFeedback(
        error || client
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.client, editingClient.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.client)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        height: '100%',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
        })}
      >
        <LabeledInput
          label="Nombre:"
          value={editingClient?.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        <LabeledInput
          label="Nombre comercial:"
          value={editingClient?.tradeName}
          onChange={(value) => onInputChange(value, 'tradeName')}
          required
        />
        <FlexRow breakPoint="isXSmall">
          <LabeledInput
            label="RFC:"
            value={editingClient?.rfc}
            onChange={(value) => onInputChange(value, 'rfc')}
            error={
              (editingClient.rfc.length > 14 || editingClient.rfc.length < 12) && isRFCVisited
                ? 'Ingrese un rfc valido'
                : null
            }
            onBlur={() => setIsRFCVisited(true)}
            required
          />
          <LabeledInput
            label="Día de pago:"
            value={editingClient?.paymentDay}
            onChange={(value) => onInputChange(+value, 'paymentDay')}
            type="number"
            min={0}
            max={31}
            error={editingClient.paymentDay > 31 || editingClient.paymentDay <= 0 ? 'Ingrese un rfc valido' : null}
            required
          />
        </FlexRow>
        <FlexRow>
          <Checkbox
            checked={editingClient?.paid}
            onChange={() => onInputChange(!editingClient?.paid, 'paid')}
          >
            Pagado
          </Checkbox>
          <Checkbox
            checked={editingClient?.safeGPT}
            onChange={() => onInputChange(!editingClient?.safeGPT, 'safeGPT')}
          >
            Safe GPT
          </Checkbox>
        </FlexRow>
        <div
          className={css({
            borderTop: '1px solid lightGray',
            paddingTop: '1em',
          })}
        >
          <ContactForm
            label="Contacto de emergencia"
            contact={editingClient.contact}
            onChange={(value) => onInputChange(value, 'contact')}
            isValid={(valid) => onContactValidateChange(valid, 'contact')}
          />
        </div>
        <div
          className={css({
            borderTop: '1px solid lightGray',
            paddingTop: '1em',
          })}
        >
          <ContactForm
            label="Contacto de instalación"
            contact={editingClient.installationContact}
            onChange={(value) => onInputChange(value, 'installationContact')}
            isValid={(valid) => onContactValidateChange(valid, 'installationContact')}
          />
        </div>
        <div
          className={css({
            borderTop: '1px solid lightGray',
            paddingTop: '1em',
          })}
        >
          <ContactForm
            label="Contacto de facturación"
            contact={editingClient.invoiceContact}
            onChange={(value) => onInputChange(value, 'invoiceContact')}
            isValid={(valid) => onContactValidateChange(valid, 'invoiceContact')}
          />
        </div>
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {client?._id && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
