import { FlexColumn, FlexRow, LabeledInput, StyledButton, useLoading } from '@gorila-shared-ui/components';
import { Block } from 'baseui/block';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../../constants/app';
import {
  CUSTOMER_ID,
  CUSTOMER_NAME,
  EMPTY_WEB_SERVICE,
  NEW_WSDL_PASSWORD,
  WSDL_URL,
  WSDL_USER,
  WS_TYPE_RECURSO_CONFIABLE,
  WS_TYPE_UNIGIS,
} from '../../../constants/webService';
import { useFeedback } from '../../../hooks/useFeedback';
import { useStyles } from '../../../hooks/useStyles';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { createWebService, updateWebService } from '../../../services/webServiceService';
import { SubClientCore } from '../../../types/subClient';
import { MinimalWebService, RecursoConfiable, WebService, WebServiceDataCore } from '../../../types/webService';
import { WebServiceTypePicker } from '../../shared/WebServiceTypePicker';
import { AssetListPicker } from '../../shared/assetListPicker/AssetListPicker';

type Props = {
  subClientId: string;
  webService?: WebService;
  onCancel: () => void;
  afterSave: () => void;
};
export function WebServiceForm({ webService, subClientId, onCancel, afterSave }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [editingWebService, setEditingWebService] = useState<MinimalWebService>(EMPTY_WEB_SERVICE);
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (webService) {
      setEditingWebService(webService);
    } else if (subClientId) {
      setEditingWebService((prev) => ({
        ...prev,
        subClient: { _id: subClientId } as SubClientCore,
      }));
    }
  }, [webService, subClientId]);

  useUpdateEffect(() => {
    if (webService) {
      setEditingWebService((prev) => ({ ...prev, data: webService.data }));
    } else {
      setEditingWebService((prev) => ({ ...prev, data: EMPTY_WEB_SERVICE.data }));
    }
  }, [editingWebService.wsType]);

  const hasUpdates = !areEqual(webService ?? {}, editingWebService);

  const canSubmit = useMemo(() => {
    if (!editingWebService.name || !editingWebService.wsType) return false;
    else if (editingWebService.wsType === WS_TYPE_UNIGIS) {
      const data = editingWebService.data as WebServiceDataCore;
      if (!!data?.NEW_WSDL_PASSWORD && !!data?.WSDL_URL && !!data?.WSDL_USER) {
        return true;
      }
    } else if (editingWebService.wsType === WS_TYPE_RECURSO_CONFIABLE) {
      const data = editingWebService.data as RecursoConfiable;
      if (
        !!data?.CUSTOMER_ID &&
        !!data?.CUSTOMER_NAME &&
        !!data?.NEW_WSDL_PASSWORD &&
        !!data?.WSDL_URL &&
        !!data?.WSDL_USER
      ) {
        return true;
      }
    }
    return false;
  }, [editingWebService]);
  const onInputChange = (value: any, field: keyof MinimalWebService) => {
    setEditingWebService((prev) => ({ ...prev, [field]: value }));
  };
  const onDataChange = (value: any, field: keyof WebServiceDataCore | keyof RecursoConfiable) => {
    setEditingWebService((prev) => ({
      ...prev,
      data: { ...prev.data, [field]: value } as WebServiceDataCore | RecursoConfiable,
    }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = webService
      ? await updateWebService(editingWebService)
      : await createWebService(editingWebService);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        webService
          ? FEEDBACK.edited(FEEDBACK_PREFIXES.webService, editingWebService.name)
          : FEEDBACK.created(FEEDBACK_PREFIXES.webService)
      );
    } else {
      showFailFeedback(
        error || webService
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.webService, editingWebService.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.webService)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
      })}
    >
      <FlexColumn
        classNames={css({
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
        })}
      >
        <LabeledInput
          label="Nombre:"
          value={editingWebService.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
        <WebServiceTypePicker
          onChange={(wsType) => onInputChange(wsType, 'wsType')}
          wsType={editingWebService.wsType}
          required
        />
        {editingWebService.wsType === WS_TYPE_RECURSO_CONFIABLE && (
          <>
            <LabeledInput
              label={CUSTOMER_ID}
              value={(editingWebService.data as RecursoConfiable)?.CUSTOMER_ID ?? ''}
              onChange={(value) => onDataChange(value, CUSTOMER_ID)}
              required
            />
            <LabeledInput
              label={CUSTOMER_NAME}
              value={(editingWebService.data as RecursoConfiable)?.CUSTOMER_NAME ?? ''}
              onChange={(value) => onDataChange(value, CUSTOMER_NAME)}
              required
            />
          </>
        )}
        {editingWebService.wsType !== undefined && (
          <>
            <LabeledInput
              label={WSDL_URL}
              value={(editingWebService.data as WebServiceDataCore)?.WSDL_URL ?? ''}
              onChange={(value) => onDataChange(value, WSDL_URL)}
              required
            />
            <LabeledInput
              label={WSDL_USER}
              value={(editingWebService.data as WebServiceDataCore)?.WSDL_USER ?? ''}
              onChange={(value) => onDataChange(value, WSDL_USER)}
              required
            />
            <LabeledInput
              type="password"
              label={NEW_WSDL_PASSWORD}
              value={(editingWebService.data as WebServiceDataCore)?.NEW_WSDL_PASSWORD ?? ''}
              onChange={(value) => onDataChange(value, NEW_WSDL_PASSWORD)}
              required
            />
          </>
        )}
        <Block
          minHeight={'300px'}
          display={'flex'}
          flexDirection={'column'}
        >
          <AssetListPicker
            subClientId={subClientId}
            assignedAssetIds={editingWebService.assignedAssets}
            onAssignedChange={(assetIds) => onInputChange(assetIds, 'assignedAssets')}
          />
        </Block>
      </FlexColumn>
      <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
        <FlexRow gap={theme.sizing.scale300}>
          {webService?._id && (
            <StyledButton
              kind="tertiary"
              onClick={onCancel}
            >
              Cancelar
            </StyledButton>
          )}
          <StyledButton
            onClick={onSave}
            isLoading={loading}
            disabled={!canSubmit || !hasUpdates}
          >
            Guardar
          </StyledButton>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
