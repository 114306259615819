import { FlexColumn, FlexRow, useMoment, VerticalDivider } from '@gorila-shared-ui/components';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { FLEET_HEALTH_EVENT_LOG_TYPE_NAME } from '../../../constants/fleetHealth';
import { useStyles } from '../../../hooks/useStyles';
import { fleetHealthEventLogState } from '../../../storage/fleetHealthStates';

type Props = {
  eventLogId: string;
  index: number;
};

function FleetHealthEventLogItem({ eventLogId, index }: Readonly<Props>) {
  const eventLog = useRecoilValue(fleetHealthEventLogState(eventLogId));
  const { css, theme, classes, concatenateClasses } = useStyles();
  const { formatDateFromTs } = useMoment();

  if (!eventLog) {
    return <></>; // TODO: skeleton
  }

  const paddingClass = css({ paddingTop: theme.sizing.scale600, paddingBottom: theme.sizing.scale600 });
  const isOdd = index % 2 !== 0;
  const textRight = css({ textAlign: 'end' });
  const logType = FLEET_HEALTH_EVENT_LOG_TYPE_NAME[eventLog.logType] ?? '';

  return (
    <FlexRow
      classNames={css({
        paddingLeft: theme.sizing.scale300,
        paddingRight: theme.sizing.scale300,
        backgroundColor: isOdd ? theme.colors.backgroundSecondary : undefined,
      })}
    >
      <FlexColumn
        gap={theme.sizing.scale0}
        classNames={concatenateClasses(css({ alignItems: 'flex-end' }), classes.flexible(1), paddingClass)}
      >
        <LabelSmall
          color={theme.colors.contentSecondary}
          className={textRight}
        >
          {formatDateFromTs(eventLog.created, 'yyyy-MM-DD HH:mm:ss')}
        </LabelSmall>
        <LabelMedium className={textRight}>{logType}</LabelMedium>
      </FlexColumn>
      <VerticalDivider
        width={4}
        classNames={css({ height: 'auto !important' })}
      />
      <FlexColumn
        gap={theme.sizing.scale0}
        classNames={concatenateClasses(classes.flexible(3), paddingClass)}
      >
        <LabelMedium margin={0}>{eventLog.message}</LabelMedium>
        <LabelSmall color={theme.colors.contentSecondary}>por: {eventLog.createdBy}</LabelSmall>
      </FlexColumn>
    </FlexRow>
  );
}

export default memo(FleetHealthEventLogItem);
