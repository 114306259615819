import { EnhancedLabeledSelect, useLoading } from '@gorila-shared-ui/components';
import { OnChangeParams } from 'baseui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFunction } from '../../../hooks/useDebounce';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getSubClients } from '../../../services/subClientService';
import { SubClientCore } from '../../../types/subClient';

type Props = {
  selectedSubClientId?: string;
  onChangeSubClientId?: (subClientId?: string) => void;
  onChangeSubClient?: (subClient?: SubClientCore) => void;
  selectedClientId?: string;
  isFilter?: boolean;
  label?: string;
  subClient?: SubClientCore;
  disabled?: boolean;
  autoSelectFirst?: boolean;
  maxDropdownHeight?: string;
  inline?: boolean;
  required?: boolean;
};
export function SubClientSelect({
  onChangeSubClientId,
  onChangeSubClient,
  selectedSubClientId,
  selectedClientId,
  subClient,
  isFilter = true,
  label = 'Subcliente:',
  disabled = false,
  autoSelectFirst = false,
  maxDropdownHeight,
  inline = false,
  required = false,
}: Readonly<Props>) {
  const [subClientsList, setSubClientsList] = useState<SubClientCore[]>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { search, setSearch, debouncedSearch } = useDebouncedSearch('');
  const [clientId, setClientId] = useState<string>();
  const [selectedSubClientOption, setSelectedSubClientOption] = useState<{ id: string; label: string }>();
  const [isAutoSelectComplete, setIsAutoSelectComplete] = useState(false);
  const requestIdRef = useRef(0);

  useUpdateEffect(() => {
    setPage(0);
  }, [debouncedSearch, clientId, disabled]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    if (disabled) return;
    startLoading();
    const loadSubClients = async () => {
      const requestId = ++requestIdRef.current;
      const { subClients, error, hasNext } = await getSubClients({
        clientId,
        page,
        q: search,
      });
      if (requestId === requestIdRef.current) {
        if (!error && subClients) {
          if (page > 1 && subClientsList) {
            setSubClientsList([...subClientsList, ...subClients]);
            setHasNextPage(hasNext);
          } else {
            setSubClientsList(subClients);
            setHasNextPage(hasNext);
          }
        } else {
          setSubClientsList(undefined);
          setHasNextPage(false);
        }
        stopLoading();
      }
    };
    loadSubClients();
  }, [page]);

  useEffect(() => {
    setSelectedSubClientOption(undefined);
    setIsAutoSelectComplete(false);
    setClientId(selectedClientId);
  }, [selectedClientId]);

  const subClientOptions = useMemo(() => {
    if (!subClientsList) return [];
    const subClientsOptions: { id: string | undefined; label: string }[] = subClientsList.map((subClient) => ({
      id: subClient._id,
      label: subClient.name,
    }));
    if (!required) {
      subClientsOptions?.unshift({
        id: undefined,
        label: isFilter ? 'Todos' : 'Ninguno',
      });
    }
    if (subClient) {
      if (!subClientsOptions.find((s) => s.id === subClient._id))
        subClientsOptions.push({
          id: subClient._id,
          label: subClient.name,
        });
    }
    if (selectedSubClientOption && selectedSubClientId) {
      if (!subClientsOptions.find((c) => c.id === selectedSubClientOption.id))
        subClientsOptions.push(selectedSubClientOption);
    }
    if (autoSelectFirst && !isAutoSelectComplete && subClientsOptions?.length >= 1) {
      const subClientDefaultOption = subClientsOptions[0];
      if (subClientDefaultOption) {
        onChangeSubClientId && onChangeSubClientId(subClientDefaultOption.id);
        onChangeSubClient &&
          onChangeSubClient({
            _id: subClientDefaultOption.id,
            name: subClientDefaultOption.label,
          } as SubClientCore);
        setSelectedSubClientOption(subClientDefaultOption as { id: string; label: string });
        setIsAutoSelectComplete(true);
      }
    }
    return subClientsOptions;
  }, [subClientsList]);

  const onSubClientChange = (params: OnChangeParams) => {
    if (params.option?.id) {
      onChangeSubClientId && onChangeSubClientId(params.option.id as string | undefined);
      onChangeSubClient && onChangeSubClient({ _id: params.option.id, name: params.option.label } as SubClientCore);
    } else {
      onChangeSubClientId && onChangeSubClientId(undefined);
      onChangeSubClient && onChangeSubClient(undefined);
    }
    setSelectedSubClientOption(params.option as { id: string; label: string });
    setSearch(undefined);
  };

  const fetchData = () => {
    if (hasNextPage) setPage(page + 1);
  };

  const handleInputChange = useDebounceFunction(function (term: string) {
    setSearch(term);
  }, 500);

  return (
    <EnhancedLabeledSelect
      label={label}
      options={subClientOptions}
      value={[{ id: selectedSubClientId }]}
      onChange={onSubClientChange}
      onInputChange={(e) => {
        handleInputChange(e.target.value);
      }}
      infiniteScroll
      fetchData={fetchData}
      fetchDataLoading={loading}
      hasNext={hasNextPage}
      searchable
      onBlur={() => setSearch(undefined)}
      disabled={disabled}
      maxDropdownHeight={maxDropdownHeight}
      inline={inline}
      inset={inline}
      fullWidth
      required={required}
    />
  );
}
