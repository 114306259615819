import {
  FlexColumn,
  FlexRow,
  LabeledInput,
  MaterialIcon,
  StyledButton,
  StyledTooltip,
  useLoading,
} from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useMemo, useState } from 'react';
import { areEqual } from 'react-window';
import { FEEDBACK, FEEDBACK_PREFIXES } from '../../constants/app';
import { useFeedback } from '../../hooks/useFeedback';
import { useStyles } from '../../hooks/useStyles';
import { postBrand, updateBrand } from '../../services/vehicleService';
import { Brand } from '../../types/brand';

type Props = {
  brand?: Brand;
  onCancel: () => void;
  afterSave: () => void;
  inline?: boolean;
};
export function BrandForm({ brand, onCancel, afterSave, inline = false }: Props) {
  const { css, theme } = useStyles();
  const [editingBrand, setEditingBrand] = useState<Brand>({
    _id: '',
    name: '',
  });
  const { startLoading, stopLoading, loading } = useLoading(false);
  const { showFailFeedback, showPositiveFeedback } = useFeedback();

  useEffect(() => {
    if (brand) {
      setEditingBrand(brand);
    }
  }, [brand]);

  const hasUpdates = !areEqual(brand ?? {}, editingBrand);

  const canSubmit = useMemo(() => {
    return !!editingBrand.name;
  }, [editingBrand]);

  const onInputChange = (value: string | undefined | null, field: keyof Brand) => {
    setEditingBrand((prev) => ({ ...prev!, [field]: value }));
  };

  const onSave = async () => {
    startLoading();
    const { id, error } = brand ? await updateBrand(editingBrand) : await postBrand(editingBrand);
    if (!error && id) {
      afterSave();
      showPositiveFeedback(
        brand ? FEEDBACK.edited(FEEDBACK_PREFIXES.brand, editingBrand.name) : FEEDBACK.created(FEEDBACK_PREFIXES.brand)
      );
    } else {
      showFailFeedback(
        error || brand
          ? FEEDBACK.failedEdition(FEEDBACK_PREFIXES.brand, editingBrand.name)
          : FEEDBACK.failedCreation(FEEDBACK_PREFIXES.brand)
      );
    }
    stopLoading();
  };

  return (
    <FlexColumn
      classNames={css({
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: '100%',
        flexDirection: inline ? 'row' : 'column',
        alignItems: 'end',
      })}
    >
      <FlexColumn
        classNames={css({
          overflow: 'auto',
          width: '100%',
          height: '100%',
        })}
      >
        <LabeledInput
          label={`${inline ? '(Nueva Marca) ' : ''}Nombre:`}
          value={editingBrand?.name}
          onChange={(value) => onInputChange(value, 'name')}
          required
        />
      </FlexColumn>
      {inline && (
        <FlexRow gap={0}>
          <StyledTooltip
            content={'Cancelar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onCancel}
              kind="tertiary"
            >
              <MaterialIcon name={'cancel'} />
            </Button>
          </StyledTooltip>
          <StyledTooltip
            content={'Guardar'}
            showArrow={false}
          >
            <Button
              size="compact"
              shape="square"
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
              kind="tertiary"
            >
              <MaterialIcon name={'done'} />
            </Button>
          </StyledTooltip>
        </FlexRow>
      )}
      {!inline && (
        <FlexRow classNames={`${css({ alignItems: 'center', justifyContent: 'flex-end' })}`}>
          <FlexRow gap={theme.sizing.scale300}>
            {brand?._id && (
              <StyledButton
                kind="tertiary"
                onClick={onCancel}
              >
                Cancelar
              </StyledButton>
            )}
            <StyledButton
              onClick={onSave}
              isLoading={loading}
              disabled={!canSubmit || !hasUpdates}
            >
              Guardar
            </StyledButton>
          </FlexRow>
        </FlexRow>
      )}
    </FlexColumn>
  );
}
