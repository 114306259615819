import { MaterialIcon, StyledTooltip, useLoading, useModal } from '@gorila-shared-ui/components';
import { Button } from 'baseui/button';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SUB_CLIENT_ASSETS_TABLE_COLUMNS_NAME } from '../../../constants/subClients';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { getAssets } from '../../../services/assetService';
import { subClientAssetsSearchFilterState } from '../../../storage/subClients';
import { Asset } from '../../../types/asset';
import AssetDetailModal from '../../assets/modal/AssetDetailModal';
import { ListWrapper } from '../../shared/ListWrapper';

type Props = {
  clientId: string;
  subClientId: string;
  reloadPage?: boolean;
};

export function SubClientAssetList({ clientId, subClientId, reloadPage }: Readonly<Props>) {
  const [assets, setAssets] = useState<Asset[]>();
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const { loading, startLoading, stopLoading } = useLoading();
  const search = useRecoilValue(subClientAssetsSearchFilterState);
  const [selectedSubClientAssetId, setSelectedSubClientAssetId] = useState<string>();
  const { openModal, closeModal, showModal } = useModal();

  useUpdateEffect(() => {
    setPage(0);
  }, [reloadPage, search]);

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }
    startLoading();
    const loadSubClientAssetsTableData = async () => {
      const response = await getAssets({
        subClientId,
        page,
        q: search,
      });
      if (response?.error) {
        setAssets(undefined);
        setTotalItems(0);
      } else {
        setAssets(response?.assets);
        setTotalItems(response?.total ?? 0);
      }
      stopLoading();
    };
    loadSubClientAssetsTableData();
  }, [page]);

  const openSubClientAssetModal = (subClientAssetId: string) => {
    setSelectedSubClientAssetId(subClientAssetId);
    openModal();
  };

  const closeSubClientAssetModal = (updateTable?: boolean) => {
    setSelectedSubClientAssetId(undefined);
    closeModal();
    if (updateTable) setPage(0);
  };

  return (
    <>
      <ListWrapper
        columns={SUB_CLIENT_ASSETS_TABLE_COLUMNS_NAME}
        data={assets?.map((subClientAsset, i) => {
          return {
            items: [
              subClientAsset.label,
              subClientAsset.device?.ident ?? '-',
              <StyledTooltip
                key={`subClientAsset-action-${i}`}
                content={'Ver detalles'}
                showArrow={false}
              >
                <Button
                  size="compact"
                  shape="square"
                  onClick={() => openSubClientAssetModal(subClientAsset._id)}
                  kind="tertiary"
                >
                  <MaterialIcon name={'visibility'} />
                </Button>
              </StyledTooltip>,
            ],
          };
        })}
        hasData={assets !== undefined}
        isEmpty={!assets?.length}
        loading={loading}
        noDataDescription="Todavía no tienes ninguna subcuenta"
        onPageChange={setPage}
        page={page}
        totalItems={totalItems}
      />
      {showModal && (
        <AssetDetailModal
          selectedAssetId={selectedSubClientAssetId}
          onClose={(updateTable) => closeSubClientAssetModal(updateTable)}
          isOpen={showModal}
          clientId={clientId}
          subClientId={subClientId}
        />
      )}
    </>
  );
}
