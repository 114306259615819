import { isValidLatitude, isValidLongitude } from '@gorila-shared-ui/components';
import { useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';
import { InstallationAddressRequest } from '../types/installation';
import { getAddressFromPoint } from '../utils/geocode';

export function useInstallationCoords(
  form: InstallationAddressRequest,
  onChange: SetterOrUpdater<InstallationAddressRequest>
) {
  useEffect(() => {
    if (!form?.latitude || !form?.longitude) return;
    if (!Number.isInteger(form.latitude) || !Number.isInteger(form.longitude)) return;
    const point = {
      lat: form.latitude,
      lng: form.longitude,
    };
    const isValidPoint = isValidLatitude(point.lat) && isValidLongitude(point.lng);
    if (!isValidPoint) return;
    const getAddres = async () => {
      const address = await getAddressFromPoint(point);
      onChange((prev) => ({
        ...prev,
        georeference: address ?? '',
        latitude: point.lat,
        longitude: point.lng,
      }));
    };
    getAddres();
  }, [form.latitude, form.longitude]);

  return null;
}
