import { useLoading, useMoment } from '@gorila-shared-ui/components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { FLEET_HEALTH_EVENT_TYPE } from '../constants/fleetHealth';
import { getFleetHealthEvent } from '../services/fleetHealthService';
import { fleetHealthEventDetailState } from '../storage/fleetHealthStates';
import { useFleetHealthEvent } from './useFleetHealthEvent';

export function useFleetHealthEventDetail() {
  const { eventId } = useParams();
  const [event, setEvent] = useRecoilState(fleetHealthEventDetailState);
  const [update, setUpdate] = useState(0);
  const { startLoading, stopLoading, loading } = useLoading();
  const { getEventStatusName } = useFleetHealthEvent();
  const { formatDateFromTs } = useMoment();

  useEffect(() => {
    if (!eventId) return;

    const loadEvent = async () => {
      startLoading();
      const { fleetHealthEvent, error } = await getFleetHealthEvent(eventId);
      if (fleetHealthEvent && !error) {
        setEvent(fleetHealthEvent);
      } else {
        setEvent(undefined);
      }
      stopLoading();
    };

    loadEvent();
  }, [eventId, update]);

  const reload = () => {
    setUpdate((prev) => prev + 1);
  };

  const eventName = event?.eventType ? FLEET_HEALTH_EVENT_TYPE[event.eventType] : 'N/A';
  const eventStatus = event?.status ? getEventStatusName(event.status) : '';
  const eventDatetime = event?.created ? formatDateFromTs(event.created) : '';

  return {
    event,
    loadingEvent: loading,
    reloadEvent: reload,
    eventName,
    eventDatetime,
    eventStatus,
  };
}
